import React from 'react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useVersion } from 'hooks/useVersion'

import { V1SwitchModal } from './V1SwitchModal'
import { V2SwitchModal } from './V2SwitchModal'

export const VersionSwitchWithoutEB = () => {
    const versionsContext = useVersion()

    return versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2 ? (
        <V1SwitchModal
            open={versionsContext?.showModal}
            onDismiss={() => versionsContext?.setShowModal(false)}
            onProceed={() => {
                versionsContext?.setShowModal(false)
                versionsContext?.setVersion(versionsContext.AVAILABLE_VERSIONS.v1)
            }}
        />
    ) : (
        <V2SwitchModal
            open={versionsContext?.showModal}
            onDismiss={() => versionsContext?.setShowModal(false)}
            onProceed={() => {
                versionsContext?.setShowModal(false)
                versionsContext?.setVersion(versionsContext.AVAILABLE_VERSIONS.v2)
            }}
        />
    )
}

export const VersionSwitch = withErrorBoundary(VersionSwitchWithoutEB, {})
