import React, { Component } from 'react';
import { Actions } from '../actions';
import { connect } from 'react-redux';
import { getSkinnedCompanyName } from "../containers/Home";
import TopSolution from '../components/Improve/TopSolution';
import MySolution from '../components/Improve/MySolution';
import Budget from '../components/Improve/Budget';
import { Row, Column } from '../components/utils/FlexBox';
import mixpanel from "mixpanel-browser";
import TAXONOMIES from '../utils/taxonomies';
import { getActiveCompany } from 'utils';
import { withHooks } from 'config/withHooks/withHooks';


const chosenSolutions = [
    {
        "category": "Culture",
        "title": "Flexible Work Schedule",
        "description": "An alternative to the traditional 9 to 5, Free 40-hour work week, allowing employees to vary their arrival and/or departure times. May include “core time”",
        "target": ["Ethnic minorities", "Non-dominant groups"],
        "cost": "Free",
        "relevant": ["Flexible Work"],
        "painpoint": "Work-life balance",
        "office": "London",
        "level": "Associate",
        "department": "Marketing",
        "link": "https://hbr.org/2019/01/what-pwc-learned-from-its-policy-of-flexible-work-for-everyone"
    }
];

const demographicTarget = [
    {
        "key": "gender",
        "label": "Gender Identity",
        options: ["Men", "Women+"]
    },
    {
        "key": "ethnicity",
        "label": "Racial & Ethnic Background",
        options: ["White", "P.O.C."]
    },
    {
        "key": "sexual_orientation",
        "label": "Sexual Identity",
        options: ["Heterosexual", "LGBTQ+"]
    },
    {
        "key": "disability",
        "label": "Persons with Disability",
        options: ["Yes", "No"]
    }
];

class ImproveContainer extends Component {
    state = {
        mySolutions: chosenSolutions
    };

    componentWillMount() {
        this.props.getTargets();
        this.props.getFilterMap();
        this.props.getAllSolutions();
    }

    componentDidMount() {
        mixpanel.track(TAXONOMIES.VIEW_PAGE, {
            name: "Improve"
        })

        this.addChosenSolution();
        this.props.getTargets();
    }

    addChosenSolution = (solution) => {
        if (solution) {
            this.state.mySolutions.push(solution)
        }
    }

    _addTarget(data) {
        this.props.doApplyTarget(data);
    }

    _deleteTarget(data) {
        this.props.doDeleteTarget(data);
    }

    _updateTarget(data) {
        this.props.doUpdateTarget(data);
    }

    _updateCompanySolutions(data) {
        this.props.doUpdateCompanySolutions(data);
    }

    _deleteSolution(data) {
        this.props.doDeleteSolution(data);
    }

    _updateBudget(data) {
        this.props.doUpdateBudget(data);
    }

    _createNewSolution(data) {
        this.props.doCreateNewSolution(data);
    }

    _createNewCompanySolution(data) {
        this.props.doCreateNewCompanySolution(data);
    }

    render() {
        let recommendedSolution = [];
        let solutions = [];
        let budget = false;
        const companyInformation = getActiveCompany(this.props.userMetadata?.companies)

        if (this.props.improve.solutions.allSolutions.length) {
            solutions = this.props.improve.solutions.allSolutions.filter(x => x.is_my_solution);
            recommendedSolution = this.props.improve.solutions.allSolutions.filter(x => x.is_recommendation);
        }

        if (companyInformation?.solutionBudget > 0) {
            budget = true;
        }

        const companyName = getSkinnedCompanyName(companyInformation?.name, this.props.profile)

        return (
            <div className={"App"}>
                <Row>
                    <Column width="100%">
                        {
                            Object.keys(this.props.home.companyInformation).length && recommendedSolution.length
                                ? <TopSolution topSolutions={recommendedSolution}
                                    filters={companyInformation?.recommendationFilters}
                                    doUpdateCompanySolutions={this.props.doUpdateCompanySolutions}
                                    showMessage={this.props.showMessage}
                                    companyName={companyName}
                                     />
                                : null
                        }
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        {
                            Object.keys(this.props.analyze.filters).length
                                ? <MySolution chosenSolutions={solutions}
                                    filters={this.props.analyze.filters}
                                    companyName={companyName}
                                    handleDeleteSolution={this._deleteSolution.bind(this)}
                                    handleUpdateCompanySolutions={this._updateCompanySolutions.bind(this)}
                                    handleCreateNewSolution={this._createNewSolution.bind(this)}
                                    handleCreateNewCompanySolution={this._createNewCompanySolution.bind(this)}
                                    filterMap={this.props.improve.filterMap.filterMap}
                                    newSolution={this.props.improve.solutions.newSolutions}
                                     />
                                : null
                        }
                    </Column>
                </Row>
                <Row>
                    <Column width="100%">
                        {
                            companyInformation
                                ? <Budget chosenSolutions={solutions}
                                    isBudget={budget}
                                    budget={companyInformation?.solutionBudget}
                                    handleUpdateBudget={this._updateBudget.bind(this)}
                                />
                                : null
                        }
                    </Column>
                </Row>
            </div>
        )
    }
}

export default connect(state => state, Actions)(withHooks(ImproveContainer));
