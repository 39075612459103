/** @format */

import { put } from "redux-saga/effects";
import { delay } from "redux-saga";
import { Actions, ActionTypes } from "../actions";

// All data sagas to add to middleware.
export default [
  [ActionTypes.SHOW_MESSAGE, showMessage],
  [ActionTypes.SHOW_TOAST, showToast],
];

function* showMessage({ payload }) {
  let delayTime = 5000;
  if (payload.delay !== undefined) {
    delayTime = payload.delay;
  }
  yield put(
    Actions.setGlobalMessage({
      type: payload.type,
      code: payload.code,
      message: payload.message,
    })
  );
  yield delay(delayTime);
  yield put(Actions.resetGlobalMessage());
}

function* showToast({ payload }) {
  const { delay: delayTime = 5000 } = payload;
  yield put(Actions.resetGlobalToast());
  yield put(
    Actions.setGlobalToast({
      type: payload.type,
      message: payload.message,
    })
  );
  yield delay(delayTime);
  yield put(Actions.resetGlobalToast());
}
