import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { BenchmarksContextController } from 'context/benchmarks/benchmarksContextController/BenchmarksContextController'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'

import { Diversity } from './Diversity'
import { Inclusion } from './Inclusion'

import './index.scss'

export const AnalyzeV2 = () => {
    return (
        <BenchmarksContextController>
            <Route path={generateCompanyURI('/analyze2/diversity/:tab?/:overTime?')} component={Diversity} />
            <Route path={generateCompanyURI('/analyze2/inclusion/:tab?/:overTime?')} component={Inclusion} />
            <Route exact path={generateCompanyURI('/analyze2')}>
                <Redirect to={generateCompanyURI('/analyze2/diversity')} />
            </Route>
        </BenchmarksContextController>
    )
}
