/** @format */

import { call, put } from "redux-saga/effects";
import mixpanel from "mixpanel-browser";

import { Actions, ActionTypes } from "../actions";
import { getPageName, mapToolToTracking } from "../utils/tracking";
import {
  loadSolutionTools,
  loadSolutionEvents,
  loadSolutionToolsFilters,
  loadSolutionSaveTool,
  loadSolutionDeleteTool,
  removeSavedToolV2,
} from "./network";
import TAXONOMIES from "../utils/taxonomies";

// All data sagas to add to middleware.
export default [
  [ActionTypes.GET_SOLUTION_EVENTS, getSolutionEvents],
  [ActionTypes.GET_SOLUTION_TOOLS, getSolutionTools],
  [ActionTypes.GET_SOLUTION_TOOLS_FILTERS, getSolutionToolsFilters],
  [ActionTypes.DO_SOLUTION_SAVE_TOOL, doSolutionSaveTool],
  [ActionTypes.DO_SOLUTION_REMOVE_SAVED_TOOL, doSolutionRemoveSavedTool],
  [ActionTypes.DO_SOLUTION_REMOVE_SAVED_TOOL_V2, doSolutionRemoveSavedToolV2],
];

function* getSolutionEvents({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  /*
        Call the api endpoint

    */
  const response = yield call(loadSolutionEvents, payload);
  /*
        Process the response
    */
  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionEvents(response.data));
  }
}

function* getSolutionTools({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  const isLoadingFirstPage = payload.query.includes('page=1')
  yield put(Actions.loadingSolutionTools({ isLoadingFirstPage }));
  /*
        Call the api endpoint

    */  
  const response = yield call(loadSolutionTools, payload);
  /*
              Process the response
          */
  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    if(!response.data.next) {
      yield put(Actions.endOfTools(true))
    }

    yield put(Actions.loadedSolutionTools(response.data));
  }
}

function* getSolutionToolsFilters({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  /*
        Call the api endpoint

    */
  const response = yield call(loadSolutionToolsFilters, payload);
  /*
              Process the response
          */
  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionToolsFilters(response.data));
  }
}

function* doSolutionSaveTool({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  /*
        Call the api endpoint

    */
  const { page, tool_obj, ...basePayload } = payload

  const response = yield call(loadSolutionSaveTool, basePayload);
  /*
              Process the response
          */

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const toolID = response.data.tool;
    const companyToolId = response.data.id;
    yield put(
      Actions.doSolutionModifyToolInRow({
        toolID: toolID,
        value: true,
        companyToolId: companyToolId,
      })
    );

    yield put(Actions.doneSolutionSaveTool(response.data));
    yield put(
      Actions.showToast({
        type: "success",
        message: "Tool has been saved!",
      })
    );

    mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.SAVE_TOOL}`, mapToolToTracking(tool_obj))
  }
}

function* doSolutionRemoveSavedTool({ payload }) {
  const response = yield call(loadSolutionDeleteTool, payload.companyToolId);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const toolID = payload.toolId;
    const page = payload.page;
    switch (page) {
      case "tools":
        yield put(
          Actions.doSolutionModifyToolInRow({
            toolID: toolID,
            value: false,
            companyToolId: null,
          })
        );
      case "my-solutions":
        yield put(
          Actions.doSolutionMyRemoveCompanyTool({
            companyToolId: payload.companyToolId,
          })
        );
      default:
        yield put(
          Actions.showToast({
            type: "warning",
            message: "Tool has been removed",
          })
        );
    }
    yield put(Actions.doneSolutionRemoveSavedTool());

    mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.REMOVE_FROM_SAVED}`, mapToolToTracking(payload.tool_obj))
  }
}

function* doSolutionRemoveSavedToolV2({ payload }) {
  const response = yield call(removeSavedToolV2, payload.companyToolId);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const toolID = payload.toolId;
    const page = payload.page;
    yield put(
      Actions.doSolutionMyRemoveCompanyTool({
        companyToolId: payload.companyToolId,
      })
    );
    yield put(Actions.doneSolutionRemoveSavedToolV2());

    mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.REMOVE_FROM_SAVED}`, mapToolToTracking(payload.tool_obj))
  }
}
