import { AuthResponse, VerificationType } from 'api/actions/auth/authActions.types'

export type LoginLocationState =
    | {
          resetMixpanel?: boolean
          mutation?: {
              data?: AuthResponse
              isSuccess?: boolean
          }
      }
    | undefined

export type VerificationTypeLocationState = {
    verificationType: VerificationType
}

export enum SearchParam {
    To = 'to',
    Code = 'code',
    State = 'state',
}

export enum LocalStorageKey {
    MfaDeviceId = 'mfa_device_id',
}
