import { jwtDecode } from 'jwt-decode'

import { HandleAuthCredentialsFn, UserObj } from 'api/actions/auth/authActions.types'

export const handleAuthCredentials: HandleAuthCredentialsFn = (response, trustedDevice) => {
    const { access_token, refresh_token, backup_codes } = response

    const user = jwtDecode(access_token) as UserObj

    const responseObject = {
        access_token,
        refresh_token,
        user,
        backupCodes: backup_codes,
    }

    const deviceId = user['2fa_device_id']

    if (access_token && refresh_token) {
        localStorage.setItem('auth_access_token', access_token)
        localStorage.setItem('auth_refresh_token', refresh_token)
    }

    if (typeof deviceId === 'string' && trustedDevice === true) {
        localStorage.setItem('mfa_device_id', deviceId)
    }

    return responseObject
}
