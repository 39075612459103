import React from 'react';
import { Card } from 'react-bootstrap'
import {copyToClipboard} from "../../utils";
import mixpanel from "mixpanel-browser";
import TAXONOMIES from "../../utils/taxonomies";

const SurveyTemplates = ({surveyLink}) => {
    const _copyToClipboard = (e, template) => {
        mixpanel.track(TAXONOMIES.COPIED_COMMUNICATION_TEMPLATE, {
            template
        })
        copyToClipboard(e.target.previousSibling);
        const target = e.target;
        target.firstChild.innerText = "Copied";
        setTimeout(() => {
            target.firstChild.innerText = "Copy";
        }, 5000);
    }
    return (
        <div className={"survey-templates"}>
            <Card className={"survey-template"}>
                <h5 className={"email-heading"}>Email 1</h5>
                <div className={"email"}>
                    <p>Dear employee,</p>
                    <p>We have partnered with Diversio, a Canadian data and analytics company that focuses on supporting organizations in becoming more inclusive. As part of our mandate, we’ve asked Diversio to evaluate diversity and inclusion in our organization. We’re asking you to complete a quick 5-minute survey. Your responses will allow us to reflect on our strengths and opportunities.</p>
                    <p>Your responses will remain completely anonymous and confidential—your name and contact information are not requested, and your responses are not visible to our internal team at any point. Once you complete the survey, Diversio collects and analyzes your feedback and will provide us with an overall report. The insights and recommendations we receive will support our efforts in continuing to build a diverse and inclusive workplace.</p>
                    <p>We’re always here to listen to any questions, thoughts, or suggestions you have about diversity and inclusion. You can talk to your manager, vice president, or anyone in Human Resources.</p>
                    <p>Survey link: <strong><a target="_blank" href={surveyLink}>{surveyLink}</a></strong></p>
                    <p>Thank you for your participation, </p>
                    <p>The Executive Team</p>
                </div>
                <div className={"copy"} onClick={e => _copyToClipboard(e, "Email 1")}>
                    <p>Copy</p>
                </div>
            </Card>
            <Card className={"survey-template"}>
                <h4 className={"email-heading"}>Email 2</h4>
                <div className={"email"}>
                    <p>Dear employee,</p>
                    <p>As we previously noted, we are collecting fully anonymous feedback from our employees to improve diversity and inclusion within our workplace. Your input is crucial for us to identify areas of improvement and the next steps for progress.</p>
                    <p>The survey is hosted by Diversio, a 3rd party company that focuses on improving DEI in organizations through a fully anonymous process. They will not collect any personally identifiable information or share any individual responses with us, only aggregated insights on what we can do better for everyone here.</p>
                    <p>Please reach out for any questions you may have about this.</p>
                    <p>Survey link: <strong><a target="_blank" href={surveyLink}>{surveyLink}</a></strong></p>
                    <p>Thank you for your participation, </p>
                    <p>The Executive Team</p>
                </div>
                <div className={"copy"} onClick={e => _copyToClipboard(e, "Email 2")}>
                    <p>Copy</p>
                </div>
            </Card>
            <Card className={"survey-template"}>
                <h4 className={"email-heading"}>Email 3</h4>
                <div className={"email"}>
                    <p>Dear employee,</p>
                    <p>Thank you all for your participation in the DEI survey! Your feedback is critical to our success in creating an inclusive workplace and we are excited to share our findings with you along with the next steps for progress.</p>
                    <p>For those who have not had the opportunity to complete this survey yet, it will be open until <strong>[Input your end date here]</strong>!</p>
                    <p>Survey link: <strong><a target="_blank" href={surveyLink}>{surveyLink}</a></strong></p>
                    <p>Again, we’re always here to listen to any questions, thoughts, or suggestions you have about diversity and inclusion. You can talk to your manager, vice president, or anyone in Human Resources.</p>
                    <p>Thank you!</p>
                    <p>The Executive Team</p>
                </div>
                <div className={"copy"} onClick={e => _copyToClipboard(e, "Email 3")}>
                    <p>Copy</p>
                </div>
            </Card>
        </div>
    );
};

export default SurveyTemplates;
