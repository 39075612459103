import React, { useMemo } from 'react'
import { HeatMapClassCell, HeatMapTableHeaderCell, IntersectionalityFilterRef } from '@diversioteam/diversio-ds'
import { parseYearQuarter } from 'utils'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { RawColumn } from 'hooks/useInclusionData'
import { AnalyzeView } from 'types/analyze.enum'
import { useGetCompanyProfiles } from 'hooks/companyProfiles/useGetCompanyProfiles'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

import { AddProfileRow } from './AddProfileRow'
import { HeatmapTableRow } from './TableRow'
import { HeatmapTableProps } from './table.types'
import * as S from './table.styles'

const HeatmapTableWithoutEB = ({
    label,
    onClick,
    data,
    table,
    selectedCellInfo: selectedTableCellInfo,
    showAddProfile,
    className,
}: HeatmapTableProps) => {
    const cellInfo =
        selectedTableCellInfo?.table === table
            ? {
                  row: selectedTableCellInfo.row,
                  column: selectedTableCellInfo.column,
              }
            : undefined

    const { survey } = useSurveys()

    // Filter APIs only need year as a query param
    const filterParams = useMemo(() => {
        return {
            year: parseYearQuarter(survey),
        }
    }, [survey])

    const queryGetCompanyProfiles = useGetCompanyProfiles(AnalyzeView.Inclusion, filterParams)

    return (
        <S.Table className={className}>
            <thead>
                <tr>
                    <th colSpan={100}>
                        <HeatMapClassCell name={label} />
                    </th>
                </tr>

                <S.TableHeader>
                    {data.columns.map((dataColumn: RawColumn, idx: number) => {
                        return (
                            <th key={`inclusion-header-cell-${idx}`}>
                                <HeatMapTableHeaderCell
                                    text={dataColumn.title}
                                    textTooltipTitle={dataColumn.questionTitle}
                                    textTooltipProps={{
                                        variant: 'light',
                                        size: 'medium',
                                        placement: 'top',
                                    }}
                                />
                            </th>
                        )
                    })}
                </S.TableHeader>
            </thead>

            <tbody>
                {data.data.map((inclusionData, idx) => {
                    const { scores } = inclusionData

                    return (
                        <HeatmapTableRow
                            key={`heatmap-table-row-${idx}`}
                            onClick={(row, column) => onClick(table, row, column)}
                            rowId={idx}
                            data={scores}
                            selectedCellInfo={cellInfo}
                        />
                    )
                })}

                {showAddProfile && (queryGetCompanyProfiles.data?.profiles.length || 0) < 4 ? (
                    <AddProfileRow columns={data.columns} />
                ) : null}
            </tbody>
        </S.Table>
    )
}

export const HeatmapTable = withErrorBoundary<HeatmapTableProps & { ref: React.Ref<IntersectionalityFilterRef> }>(
    HeatmapTableWithoutEB,
    {},
)
