import { useMutation } from '@tanstack/react-query'
import { createDiversityCompanyProfile, createInclusionCompanyProfile } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import { AnalyzeView } from 'types/analyze.enum'
import {
    CompanyProfilesParams,
    CreateCompanyProfilePayload,
} from 'api/actions/analyze/companyProfiles/companyProfilesActions.types'
import { Mutations } from 'api/mutations.enum'

export const useCreateCompanyProfile = (view: AnalyzeView, params: CompanyProfilesParams) => {
    const mutationKey =
        view === AnalyzeView.Diversity
            ? [Mutations.createDiversityCompanyProfile]
            : [Mutations.createInclusionCompanyProfile]
    const mutationFn = view === AnalyzeView.Diversity ? createDiversityCompanyProfile : createInclusionCompanyProfile

    const mutation = useMutation<void, Error, CreateCompanyProfilePayload>({
        mutationKey,
        mutationFn: (payload) => {
            return mutationFn({ payload, queryString: stringifyQueryParams(params) })
        },
    })

    return mutation
}
