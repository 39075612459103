import React from 'react';
import { Row } from "../utils/FlexBox";
import mixpanel from 'mixpanel-browser';
import HeatMap from './HeatMap';
import { getSkinnedCompanyName } from "../../containers/Home";
import Breakdown from "./Breakdown";
import Loader from '../utils/Loader';
import TAXONOMIES from '../../utils/taxonomies';
import { isBCI, isBCIApplicants, isBCIStudents } from 'utils/companyChecks';
import { INSUFFICIENT_DATA, NOT_AVAILABLE } from 'components/AnalyzeV2/Inclusion/Heatmap/heatmap.utils';
import { getActiveCompany, MINIMUM_DATA_THRESHOLD } from '../../utils';
import { useUserMetadata } from 'hooks/metadata/useUserMetadata';

const Demographics = (props) => {
    const { data: userMetadata } = useUserMetadata()

    const companyInformation = getActiveCompany(userMetadata?.companies)
    React.useEffect(() => {
        mixpanel.track(TAXONOMIES.SELECT_DEMOGRAPHICS_OR_ENGAGEMENT_PAGE, {
            bespokePageName: "Demographics"
        })
    }, []);

    const { current_or_selected_quarter, quarters, bespoke_engagement_quarters, bespoke_demographic_quarters, bespoke_questions } = props.analyze.filters

    const isExportToExcelEnabled = companyInformation?.permissions?.export_to_excel;
    const companyName = getSkinnedCompanyName(companyInformation?.name, props?.profile);

    const hasBespokeExperienceQuestions = bespoke_engagement_quarters.some((item) =>
        JSON.stringify(item) === JSON.stringify(current_or_selected_quarter)
    );

    const demographicQuestions = bespoke_questions.filter(question => question.question_type == "demographic")
    const experienceQuestions = bespoke_questions.filter(question => question.question_type == "experience")

    const latestBespokeDemographicSurvey = bespoke_demographic_quarters?.[0]
    const latestBespokeDemographicSurveyString = `${latestBespokeDemographicSurvey?.[0]}Q${latestBespokeDemographicSurvey?.[1]}`

    /**
     * If the current survey does not have bespoke demographic questions, display a message
     * communicating that the data being displayed is from an older survey.
     */
    const displayMessageForOldBespokeData = () => {
        const latestSurvey = quarters?.[0]
        
        // Check if latest survey has any bespoke engagement questions
        const latestSurveyHasBespokeDemographicQuestions = latestSurvey && latestBespokeDemographicSurvey && (latestSurvey.length == latestBespokeDemographicSurvey.length) && latestSurvey.every((element, index) =>
            element === latestBespokeDemographicSurvey[index]
        );

        if (!latestSurveyHasBespokeDemographicQuestions) {
            return <p><i>Note that the data displayed below is from a previous survey but is the most recent data available.</i></p>
        }
    }


    const isInclusionVisible = () => {
        const isHidden = (
        isBCIApplicants(companyName)
        || isBCI(companyName)
        || isBCIStudents(companyName)
        );

        const hasKpiData = () => {
            /**
             * We want to check inclusionMetrics to ensure that there is KPI data to display in the heatmap but we
             * don't want to determine this based on the industry item (which is usually at index 0) because sometimes
             * industry data is missing.
             * 
             * We only want to check the item at index 0 for cases where we have a bespoke heatmap with only a single
             * option to be displayed, and hence, only 1 item in the array.
            */ 
            if (props.analyze?.inclusionMetrics) {

                let indexToCheck = 1
                if (props.analyze.inclusionMetrics.length === 1) {
                    indexToCheck = 0
                }

                const metrics = props.analyze.inclusionMetrics[indexToCheck];
                const hasValidData = typeof metrics === "object" && !(Object.values(metrics).filter((value) => ((value === NOT_AVAILABLE) || (value === INSUFFICIENT_DATA))).length == MINIMUM_DATA_THRESHOLD);
                return hasValidData;
            }
        }

        if (!isHidden && hasKpiData()) {
            return true
        }

        return false;
    }


    return (
        props.analyze.isLoading ? <Loader/> :
        <div>
            <Row>
                <Breakdown 
                    colors={props.colors}
                    composition={props.bespoke.bespokeDemographics}
                    breakdown={props.analyze.diversityBreakdown}
                    companyName={companyInformation?.name}
                    filters={props.analyze.filters}
                    handleApplyFilters={data => {
                        mixpanel.track(TAXONOMIES.USE_FILTER, {
                            name: "Demographic Breakdown"
                        });
                        props.getBespokeDemographics({ ...data })
                    }
                    }
                    showMessage={props.showMessage}
                    profile={props.profile}
                    latestSurveyYear={latestBespokeDemographicSurveyString}
                    warningMessage={displayMessageForOldBespokeData}
                    bespokePage={"demographics"}
                />
            </Row>
            {isInclusionVisible(companyName) && companyInformation?.permissions?.analyze &&
            <Row>
                <HeatMap 
                    data={props.analyze.inclusionMetrics}
                    loadingNonOverviewMetrics={props.analyze.loadingNonOverviewMetrics}
                    prevData={props.analyze.previousInclusionMetrics}
                    overall={props.analyze.overall}
                    previousOverall={props.analyze.previousOverall}
                    companyName={companyName}
                    isExportToExcelEnabled={isExportToExcelEnabled}
                    getInclusionMetricsData={props.getInclusionMetricsData}
                    getInclusionMetricsExcel={props.getInclusionMetricsExcel}
                    getNonOverviewMetricsData={props.getNonOverviewMetricsData}
                    getNonOverviewMetricsExcel={props.getNonOverviewMetricsExcel}
                    allFilters={props.analyze.filters}
                    selected={props.selected}
                    selectTab={props.select}
                    metadata={props.analyze.metadata}
                    bespokeQuestions={bespoke_questions}
                    demQuestions={demographicQuestions}
                    currentHeatmap={"heatmap1"}
                    doUpdateCompanyFilters={props.doUpdateCompanyFilters}
                    latestSurveyYear={latestBespokeDemographicSurveyString}
                    warningMessage={displayMessageForOldBespokeData}
                    bespokePage={"demographics"}
                />
            </Row>}
            {isInclusionVisible(companyName) && hasBespokeExperienceQuestions &&
            <Row>
                <HeatMap 
                    data={props.bespoke.inclusionMetricsBespoke}
                    loadingNonOverviewMetrics={props.bespoke.loadingNonOverviewMetrics}
                    prevData={props.bespoke.previousInclusionMetricsBespoke}
                    overall={props.bespoke.overall}
                    previousOverall={props.bespoke.previousOverall}
                    companyName={companyName}
                    isExportToExcelEnabled={isExportToExcelEnabled}
                    getInclusionMetricsData={props.getInclusionMetricsDataBespoke}
                    getInclusionMetricsExcel={props.getInclusionMetricsExcel}
                    getNonOverviewMetricsData={props.getNonOverviewMetricsDataBespoke}
                    getNonOverviewMetricsExcel={props.getNonOverviewMetricsExcel}
                    allFilters={props.analyze.filters}
                    selected={props.selected}
                    selectTab={props.select}
                    metadata={props.bespoke.metadata}
                    bespokeQuestions={bespoke_questions}
                    expQuestions={experienceQuestions}
                    demQuestions={demographicQuestions}
                    currentHeatmap={"heatmap2"}
                    doUpdateCompanyFilters={props.doUpdateCompanyFilters}
                    latestSurveyYear={latestBespokeDemographicSurveyString}
                    warningMessage={displayMessageForOldBespokeData}
                    bespokePage={"demographics"}
                />
            </Row>}
        </div>
    );
};

export default Demographics;
