import React from 'react';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "../utils/Link";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { withTranslation } from 'react-i18next';
import diversioLogo from '../../assets/images/diversio-logo.png'
import "./index.scss";
import {Grid} from "@material-ui/core";

const SelfServeRegister = ({ onSubmit, t }) => {

    let schemaObj = {
        company: yup.string().required(),
        email: yup.string().required().email(),
        username: yup.string().required(),
        password: yup.string()
            .required()
            .min("8", t("AUTH.REGISTER.LENGTH_ERR"))
            .max("128", t("AUTH.REGISTER.LENGTH_ERR"))
            .matches(/^(?=.*[!@#$%^&*])/, t("AUTH.REGISTER.SPECIAL_CHAR_ERR"))
            .matches(/^(?=.*[a-z])/, t("AUTH.REGISTER.LOWER_CASE_ERR"))
            .matches(/^(?=.*[A-Z])/, t("AUTH.REGISTER.UPPER_CASE_ERR"))
            .matches(/^(?=.*[0-9])/, t("AUTH.REGISTER.NUMERIC_ERR")),
        confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    }

    const registerSchema = yup.object().shape(schemaObj);

    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(registerSchema)
    });


    return (
        <form id={"auth-form"} className={'auth-form'} onSubmit={handleSubmit(onSubmit)}>
            <img src={diversioLogo} className="logo" alt="Diversio Global"/>
            <Grid>
                <TextField
                    className={"register-form__text-field register-form__company-field"}
                    inputRef={register}
                    error={!!errors.company}
                    helperText={errors.company ? errors.company.message : ''}
                    name={'company'}
                    id="outlined-name"
                    label={t("AUTH.LOGIN.COMPANY")}
                    margin="normal"
                    variant="outlined"
                    type={"text"}
                    style={{ minWidth: "616px" }}
                />
            </Grid>

            <Grid>
                <TextField
                    className={"register-form__text-field"}
                    inputRef={register}
                    error={!!errors.username}
                    helperText={errors.username ? errors.username.message : ''}
                    name={'username'}
                    id="outlined-name"
                    label={t("AUTH.LOGIN.USERNAME")}
                    margin="normal"
                    variant="outlined"
                    type={"text"}
                />

                <TextField
                    className={"register-form__text-field register-form__email-field"}
                    inputRef={register}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                    name={'email'}
                    id="outlined-name"
                    label={t("AUTH.LOGIN.EMAIL")}
                    margin="normal"
                    variant="outlined"
                    type={"email"}
                />
            </Grid>

            <Grid>
                <TextField
                    autoComplete={"new-password"}
                    className={"register-form__text-field"}
                    inputRef={register}
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ''}
                    id="outlined-name"
                    name={'password'}
                    label={t("AUTH.LOGIN.PASSWORD")}
                    margin="normal"
                    variant="outlined"
                    type={"password"}
                />

                <TextField
                    className={"register-form__text-field"}
                    inputRef={register}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword ? errors.confirmPassword.message : ''}
                    id="outlined-name"
                    name={'confirmPassword'}
                    label={t("AUTH.LOGIN.CONFIRM_PASSWORD")}
                    margin="normal"
                    variant="outlined"
                    type={"password"}
                />
            </Grid>

            <Button className={"login__btn"} type={"submit"} variant="contained" color="primary">
                {t("AUTH.REGISTER.REGISTER")}
            </Button>

            <div className="forgot-password-button text-center">
                {t("AUTH.REGISTER.EXISTING_ACCOUNT")} <br/> <Link to={"/login"}>{t("AUTH.REGISTER.LOGIN_HERE")}</Link>
            </div>
        </form>
    );
};

export default withTranslation()(SelfServeRegister);
