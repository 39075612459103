/** @format */

import { call, put } from "redux-saga/effects";
import mixpanel from "mixpanel-browser";

import { Actions, ActionTypes } from "../actions";
import { getPageName, mapToolToTracking } from "../utils/tracking";
import {
  loadSolutionTrainings,
  loadSolutionSaveTool,
  loadSolutionDeleteTool,
} from "./network";

// All data sagas to add to middleware.
export default [
  [ActionTypes.GET_SOLUTION_TRAININGS, getSolutionTrainings],
  [ActionTypes.DO_SOLUTION_SAVE_TRAINING, doSolutionSaveTraining],
  [ActionTypes.DO_SOLUTION_REMOVE_SAVED_TRAINING, doSolutionRemoveSavedTraining],
];


function* getSolutionTrainings({ payload }) {
  const isLoadingFirstPage = payload.query.includes('page=1')
  yield put(Actions.loadingSolutionTrainings({ isLoadingFirstPage }));

  const response = yield call(loadSolutionTrainings, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    if(!response.data.next) {
      yield put(Actions.endOfTrainings(true))
    }

    yield put(Actions.loadedSolutionTrainings(response.data));
  }
}


function* doSolutionSaveTraining({ payload }) {
  const { page, tool_obj, ...basePayload } = payload

  const response = yield call(loadSolutionSaveTool, basePayload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const trainingID = response.data.tool;
    const companyTrainingId = response.data.id;
    yield put(
      Actions.doSolutionModifyTrainingInRow({
        trainingID: trainingID,
        value: true,
        companyTrainingId: companyTrainingId,
      })
    );
    yield put(Actions.doneSolutionSaveTraining(response.data));
    yield put(
      Actions.showToast({
        type: "success",
        message: "Training has been saved!",
      })
    );

    mixpanel.track(`${getPageName(page)}: Save Training`, mapToolToTracking(tool_obj))
  }
}

function* doSolutionRemoveSavedTraining({ payload }) {
  const response = yield call(loadSolutionDeleteTool, payload.companyToolId);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const trainingID = payload.toolId;
    const page = payload.page;
    switch (page) {
      case "trainings":
        yield put(
          Actions.doSolutionModifyTrainingInRow({
            trainingID: trainingID,
            value: false,
            companyTrainingID: null,
          })
        );
      default:
        yield put(
          Actions.showToast({
            type: "warning",
            message: "Training has been removed",
          })
        );
    }
    yield put(Actions.doneSolutionRemoveSavedTraining());

    mixpanel.track(`${getPageName(page)}: Remove From Saved`, mapToolToTracking(payload.tool_obj))
  }
}
