import React, { forwardRef, useMemo } from 'react'
import {
    HeatMapSideCell,
    HeatMapSideCellProps,
    IntersectionalityFilterRef,
    OrganizationIcon,
    PlusIcon,
} from '@diversioteam/diversio-ds'
import { ErrorBoundary } from '@sentry/react'
import { parseYearQuarter } from 'utils'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useGetCompanyProfiles } from 'hooks/companyProfiles/useGetCompanyProfiles'
import { AnalyzeView } from 'types/analyze.enum'
import { useFilters } from 'hooks/useFilters'
import { MetricSelect } from '../Table/MetricSelect'
import { TABLE_VARIANTS } from '../enums'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { profilesColors } from 'utils/profilesCors'
import { toASCII } from 'utils/toASCII/toASCII'
import { removeParenthesesContent } from 'utils/removeParenthesesContent/removeParenthesesContent'

import { HeatmapTableSideCellsProps } from './sideCells.types'
import * as S from './sideCells.styles'

const tooltipProps: HeatMapSideCellProps['textTooltipProps'] = {
    size: 'small',
    variant: 'light',
    enterDelay: 250,
    enterNextDelay: 250,
}

export const HeatmapTableSideCellsWithoutEB = forwardRef<IntersectionalityFilterRef, HeatmapTableSideCellsProps>(
    (
        {
            data,
            availableMetrics,
            selectedMetric,
            onSelectMetric,
            showAddProfile,
            onClick,
            selectedCellInfo,
            size,
            showFullTitle,
        }: HeatmapTableSideCellsProps,
        ref,
    ) => {
        const { setProfilesActive } = useFilters({ view: AnalyzeView.Inclusion })

        const { survey } = useSurveys()

        // Filter APIs only need year as a query param
        const filterParams = useMemo(() => {
            return {
                year: parseYearQuarter(survey),
            }
        }, [survey])

        const queryGetCompanyProfiles = useGetCompanyProfiles(AnalyzeView.Inclusion, filterParams)

        const profileSideCellProps: HeatMapSideCellProps = {
            text: 'Add Profile',
            leftIcon: <PlusIcon type="line" color="black" />,
            onClick: () => {
                setProfilesActive(true)

                if (ref && typeof ref !== 'function') {
                    ref.current?.onCreateNewProfile()
                }
            },
        }

        return (
            <S.SideCells size={size}>
                <thead>
                    <tr>
                        <S.MetricSelectContainer>
                            <MetricSelect
                                availableMetrics={availableMetrics}
                                selectedMetric={selectedMetric}
                                onSelectMetric={onSelectMetric}
                            />
                        </S.MetricSelectContainer>
                    </tr>

                    <S.TableHeader>
                        <S.EmptyCell />
                    </S.TableHeader>
                </thead>

                <tbody>
                    {data.data.map((inclusionData, rowId) => {
                        const { title, subtitle, type, code, tooltip, profileColor } = inclusionData

                        const textAlias = showFullTitle ? title : removeParenthesesContent(title)
                        const hasTextAlias = textAlias.length !== title.length
                        const text = toASCII(title).replaceAll(',', ', ')

                        const isClickable = (() => {
                            if (type === 'dimension') {
                                if (code === 'prefer_not_to_say') {
                                    return false
                                }

                                return true
                            }

                            if (type === 'profile') {
                                return true
                            }

                            return false
                        })()

                        const sideCellProps: HeatMapSideCellProps = {
                            text: hasTextAlias ? `${textAlias}...` : text,
                            helperText: '',
                            rightIcon: type === 'benchmark' ? <OrganizationIcon type="line" /> : null,
                            textTooltipTitle: tooltip ? tooltip : '',
                            textTooltipProps: tooltip ? tooltipProps : undefined,
                            textOverflowTooltipProps: { ...tooltipProps, ...(hasTextAlias ? { title: text } : {}) },
                            onClick: isClickable ? () => onClick(TABLE_VARIANTS.INCLUSION, rowId, null) : undefined,
                            selected: rowId === selectedCellInfo?.row && selectedCellInfo.column === null,
                            profileBadgeProps: profileColor
                                ? {
                                      color: profilesColors[profileColor - 1],
                                  }
                                : undefined,
                        }

                        if (subtitle && subtitle !== 'N/A' && type !== 'bespoke_demographic') {
                            sideCellProps.helperText = subtitle
                        }

                        return (
                            <S.TableRow key={rowId}>
                                <S.SideCell>
                                    <ErrorBoundary>
                                        <HeatMapSideCell {...sideCellProps} />
                                    </ErrorBoundary>
                                </S.SideCell>
                            </S.TableRow>
                        )
                    })}

                    {showAddProfile && (queryGetCompanyProfiles.data?.profiles.length || 0) < 4 ? (
                        <S.SideCell>
                            <HeatMapSideCell {...profileSideCellProps}>Add Profile</HeatMapSideCell>
                        </S.SideCell>
                    ) : null}
                </tbody>
            </S.SideCells>
        )
    },
)

export const HeatmapTableSideCells = withErrorBoundary<
    HeatmapTableSideCellsProps & { ref: React.Ref<IntersectionalityFilterRef> }
>(HeatmapTableSideCellsWithoutEB, {})
