import React, { Component } from 'react';
import Card from '../../utils/Card';
import diversioLogo from '../../../assets/images/DiversityVC/diversio_logo.png';
import diversityVCLogo from '../../../assets/images/DiversityVC/diversity_vc_logo.png';
import level1 from '../../../assets/images/DiversityVC/level_1.png';
import level2 from '../../../assets/images/DiversityVC/level_2.png';
import oneTechLogo from '../../../assets/images/DiversityVC/onetech_logo.png';
import ProgramList from './parts/ProgramList';
import RedNumberList from './parts/RedNumberList';
import { PROGRAMS, L1REQUIREMENTS, L2REQUIREMENTS } from './programs.js'
import './index.scss';


class About extends Component {

    render() {
        return (
            <Card className="diversity-vc__about">
                <div className="diversity-vc__logos">
                    <img src={diversityVCLogo} />
                    <img style={{ width: "auto", height: '11px' }} src={diversioLogo} />
                    <img src={oneTechLogo} />
                </div>
                <div className="diversity-vc__section diversity-vc__what-is-it">
                    <div>
                        <h1>What is the Diversity VC Standard?</h1>
                        <p>
                            The Diversity VC Standard is an assessment and certification process that provides venture capital funds with an understanding of how their Diversity and Inclusion (D&I) initiatives compare to a best-in class standard and to the industry at large.
                        </p>
                        <p>
                            The Standard assesses funds across four different policy buckets: Recruiting & HR, Internal Policy & Culture, Dealflow Sources, and Portfolio Guidance. Following a 1.5 hour assessment, in which each firm must provide evidence of various policies and programs, funds are supplied with a dashboard outlining their performance across these four policy areas. Firms that meet a predefined set of criteria receive certification under the Standard, which indicates both a commitment to D&I and position of leadership within the industry. 
                        </p>
                        <p>
                            However, the Standard is much more than a feel good, ‘check-the-box’ certification exercise. In addition to receiving detailed D&I performance data, the Standard provides funds with a customized set of evidence-based recommendations and best-practices that will assist them in becoming a more diverse and inclusive investor.
                        </p>
                        <p>
                            The recommendations that form the Diversity VC Standard are evidence-led and based on research from the Harvard Business Review, Deloitte, McKinsey, Diversity VC, and Diversio. The Standard has been developed in partnership with Diversio, the world’s leading D&I solution provider for investors, and One Tech. The Diversity VC Standard is strongly supported by the ILN and has been endorsed by The Rose Review.
                        </p>
                        <p>
                            To help funds implement the recommended practices and policies the Standard provides, Diversity VC and Diversio have curated a list of third party organisations who can advise on HR policies, leadership training, and more. These organisations have been chosen based on the quality and impact of their work. The team behind the Standard does not have commercial ties to any recommended providers.
                        </p>
                    </div>
                </div>
                <div className="diversity-vc__section--light diversity-vc__must-have">
                    <div className="diversity-vc__columns">
                        <div>
                            <h2>To Receive Level 1 Your Fund Must Have:</h2>
                            <img src={level1} style={{ width: "auto", height: '196px' }}/>
                            <RedNumberList items={L1REQUIREMENTS} />

                        </div>
                        <div>
                            <h2>To Receive Level 2 Your Fund Must Have:</h2>
                            <img src={level2} style={{ width: "auto", height: '196px' }} />
                            <RedNumberList items={L2REQUIREMENTS} />

                        </div>
                    </div>
                    <div className="diversity-vc__columns">
                        <div className="diversity-vc__additional">
                            <div className="diversity-vc__additional--large-red">+</div>
                            <div>An Additional <b>4</b> Programmes (1 from each section) From the List Below</div>
                        </div>
                        <div className="diversity-vc__additional">
                            <div className="diversity-vc__additional--large-red diversity-vc__additional--center">+</div>
                            <div>An Additional <b>8</b> Programmes (2 from each section) From the List Below</div>
                        </div>
                    </div>
                </div>
                <div className="diversity-vc__section--light diversity-vc__programs">
                    <div className="diversity-vc__columns">
                        {PROGRAMS.map((program, index) => <ProgramList key={index}{...program} />)}
                    </div>
                    <p>
                        *Participants have twelve months from initial assessment to implement programs marked with an
                        asterisk (*). Diversity VC reserves the right to audit VCs to ensure these criteria have been
                        met.
                    </p>
                </div>
            </Card>
        )
    }
}

About.propTypes = {};

export default About;
