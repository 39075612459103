import { call, put } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import { loadProfile, updateProfile, uploadProfileImage
    , loadAllPosts, createPost, editPost, deletePost
    , loadComments, createComment, editComment, deleteComment
    , likePost, likeComment
    , loadBookmark, updateBookmark
    , loadKpis } from './network';
import { stringifyQueryParams } from '../utils';
import * as Sentry from "@sentry/react";

export default [
    [
        ActionTypes.GET_PROFILE
        , getProfile
    ],
    [
        ActionTypes.DO_UPDATE_PROFILE
        , doUpdateProfile
    ],
    [
        ActionTypes.DO_UPLOAD_PROFILE_IMAGE
        , doUploadProfileImage
    ],
    [
        ActionTypes.GET_ALL_POSTS
        , getAllPosts
    ],
    [
        ActionTypes.DO_CREATE_POST
        , doCreatePost
    ],
    [
        ActionTypes.DO_EDIT_POST
        , doEditPost
    ],
    [
        ActionTypes.DO_DELETE_POST
        , doDeletePost
    ],
    [
        ActionTypes.GET_COMMENTS
        , getComments
    ],
    [
        ActionTypes.DO_CREATE_COMMENT
        , doCreateComment
    ],
    [
        ActionTypes.DO_EDIT_COMMENT
        , doEditComment
    ],
    [
        ActionTypes.DO_DELETE_COMMENT
        , doDeleteComment
    ],
    [
        ActionTypes.DO_LIKE_POST
        , doLikePost
    ],
    [
        ActionTypes.DO_LIKE_COMMENT
        , doLikeComment
    ],
    [
        ActionTypes.GET_BOOKMARK
        , getBookmark
    ],
    [
        ActionTypes.DO_UPDATE_BOOKMARK
        , doUpdateBookmark
    ],
    [
        ActionTypes.GET_FILTERED_POSTS
        , getFilteredPosts
    ],
    [
        ActionTypes.GET_ALL_KPIS
        , getAllKpis
    ]
];

function * getProfile () {
    yield put(Actions.loadingProfile());

    const response = yield call(loadProfile);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        Sentry.setContext("profile", response.data)
        yield put(Actions.loadedProfile(response.data));
    }
}

function * doUpdateProfile ({payload}) {
    yield put(Actions.doingUpdateProfile());

    const response = yield call(updateProfile, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: "Fields may not be left empty"}));        


        yield put(
            Actions.showToast({
              type: "warning",
              message: "Something went wrong",
            })
        );
    } else {
        yield put(Actions.doneUpdateProfile(response.data));

        yield put(
            Actions.showToast({
              type: "success",
              message: "Successfully updated profile.",
            })
        );
    }
}

function * doUploadProfileImage ({payload}) {
    yield put(Actions.doingUploadProfileImage());

    const response = yield call(uploadProfileImage, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneUploadProfileImage(response.data));
    }
}

function * getAllPosts () {
    yield put(Actions.loadingAllPosts());

    const response = yield call(loadAllPosts);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedAllPosts(response.data));
    }
}

function * doCreatePost ({payload}) {
    yield put(Actions.doingCreatePost());

    const response = yield call(createPost, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneCreatePost(response.data));
    }
}

function * doEditPost ({payload}) {
    yield put(Actions.doingEditPost());
    const response = yield call(editPost, stringifyQueryParams(payload), payload.post);
    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneEditPost(response.data));
    }
}

function * doDeletePost ({payload}) {
    yield put(Actions.doingDeletePost());

    const response = yield call(deletePost, stringifyQueryParams(payload));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneDeletePost(payload));
    }
}

function * getComments ({payload}) {
    yield put(Actions.loadingComments());

    const response = yield call(loadComments, stringifyQueryParams(payload));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedComments(response.data));
    }
}

function * doCreateComment ({payload}) {
    yield put(Actions.doingCreateComment());

    const response = yield call(createComment, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneCreateComment(response.data));
    }
}

function * doEditComment ({payload}) {
    yield put(Actions.doingEditComment());

    const response = yield call(editComment, stringifyQueryParams(payload), payload.comment);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneEditComment(response.data));
    }
}

function * doDeleteComment ({payload}) {
    yield put(Actions.doingDeleteComment());

    const response = yield call(deleteComment, stringifyQueryParams(payload));

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneDeleteComment(payload));
    }
}

function * doLikePost ({payload}) {
    yield put(Actions.doingLikePost());

    const response = yield call(likePost, stringifyQueryParams(payload), payload.like_count);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}))
    } else {
        yield put(Actions.doneLikePost(payload));
    }
}

function * doLikeComment ({payload}) {
    yield put(Actions.doingLikeComment());

    const response = yield call(likeComment, stringifyQueryParams(payload), payload.like_count);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneLikeComment(payload));
    }
}

function * getBookmark () {
    yield put(Actions.loadingBookmark());

    const response = yield call(loadBookmark);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedBookmark(response.data));
    }
}

function * doUpdateBookmark ({payload}) {
    yield put(Actions.doingUpdateBookmark());

    const response = yield call(updateBookmark, stringifyQueryParams(payload), payload.is_bookmarked);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.doneUpdateBookmark(response.data));
    }
}

function * getFilteredPosts ({payload}) {
    yield put(Actions.loadingFilteredPosts());

    yield put(Actions.loadedFilteredPosts(payload));
}

function * getAllKpis () {
    yield put(Actions.loadingAllKpis());

    const response = yield call(loadKpis);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code}));
    } else {
        yield put(Actions.loadedAllKpis(response.data));
    }
}
