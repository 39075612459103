/** @format */

import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { stringify } from "query-string";
import mixpanel from "mixpanel-browser";
import { Fade, Modal, Box, Backdrop } from "@material-ui/core";
import {
  Toast,
  Button,
  CloseIcon,
  VerticalStepper,
  ReturnButton,
  HorizontalStepper,
  ChevronRightIcon,
  CheckmarkIcon,
} from "@diversioteam/diversio-ds";

import { ExplorePrograms } from "../../../../components/Solutions/GoalsPages/ExplorePrograms";
import { SelectInsight } from "../../../../components/Solutions/GoalsPages/SelectInsight";
import { CreateGoal } from "../../../../components/Solutions/GoalsPages/CreateGoal";
import { Actions } from "../../../../actions";
import { getActiveCompany, getActiveCompanyID } from "../../../../utils";
import { mapT } from "../../../../translations/utils";
import * as m from "../../../../translations/mapping";

import { getPageName } from "../../../../utils/tracking";
import TAXONOMIES from "../../../../utils/taxonomies";
import { NO_DATA } from "components/AnalyzeV2/Inclusion/Heatmap/heatmap.utils";
import "./index.scss";
import { withHooks } from "config/withHooks/withHooks";

const PAGE_NAME = "goals"

let pages = {
  SELECT_INSIGHT: {
    number: 0,
    label: "Select Insight",
  },
  CREATE_GOAL: {
    number: 1,
    label: "Create Goal",
  },
  EXPLORE_PROGRAMS: {
    number: 2,
    label: "Explore Programs",
  },
};

/**
 * TODO:
 * - Handle hiding values with empty data, or what to do with it
 * - Reset selected goal card values when changing KPI or Target Group score
 * */
class SolutionGoals extends Component {
  constructor(props) {
    super(props);
    
    this.goalsPageContainerRef = createRef();

    this.state = {
      page: pages.SELECT_INSIGHT.number,
      modalOpen: false,
      isCustomGoal: false,
      insightListClippedHeight: 0,
      selectedImprovementScore: null,
      selectedKPI: null,
      selectedTargetGroup: null,
      selectedTimeline: null,
      selectedGoalString: null,
    };
    this.props.resetGlobalToast();
    // Initial data needed to be pulled from the backend on page load
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    const initialData = {
      data: { year: "", level: "", office: "", department: "" },
      id: activeID,
    };
    this.props.getSolutionGoalsOptions({ id: activeID });
    this.props.getSolutionGoalsInsightData(initialData);
    // this.props.getSolutionGoalsRecommendedPrograms({ id: activeID });
    this.props.getSolutionAllProgramsFilters({ id: activeID });
  }

  componentDidMount() {
    mixpanel.track(TAXONOMIES.VIEW_PAGE, {
      name: getPageName(PAGE_NAME)
    })
  }

  onNext = () => {
    /**
     * Move page state forward
     */
    const { page } = this.state;

    switch (page) {
      case pages.SELECT_INSIGHT.number:
        return this.setState({
          page: pages.CREATE_GOAL.number,
        });

      case pages.CREATE_GOAL.number:
        return this.setState({
          page: pages.EXPLORE_PROGRAMS.number,
        });
    }
  };

  onBack = () => {
    /**
     * Move page state backwards
     */
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.RETURN_BUTTON_CLICKED}`)

    const { page } = this.state;
    switch (page) {
      case pages.EXPLORE_PROGRAMS.number:
      // If a user has made it to the 3rd page, Explore Programs, that means they have created
      // a goal successfully. Clicking the return button at this state should result in the user being
      // sent back to the first page and the page "resetting"
      case pages.CREATE_GOAL.number:
        return this.setState({
          page: pages.SELECT_INSIGHT.number,
          selectedImprovementScore: null,
          selectedKPI: null,
          selectedTargetGroup: null,
          selectedTimeline: null,
          selectedGoalString: null,
        });
    }
  };

  /*
        Used if the user selects to create a custom goal, meaning we have to show a different component
    */
  onNextCustom = () => {
    /**
     * If the user clicks to create a "custom" goal, we want to have blank data for the create goal card
     */
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CLICKED_CREATE_CUSTOM_GOAL}`)

    this.setState({
      page: pages.CREATE_GOAL.number,
      insightListClippedHeight: 0,
      isCustomGoal: true,
      modalOpen: false,
      selectedImprovementScore: null,
      selectedKPI: null,
      selectedTargetGroup: null,
      selectedTimeline: null,
      selectedGoalString: null,
    });
  };
  /*
        Used if the user selects to pre defined goal
    */
  onNextRegular = (kpi, targetGroup, stringValue) => {
    /**
     * If the user selects to create a goal based off an insight provided, set the create goal card
     * with initial values to show
     */
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CLICKED_CREATE_GOAL_FROM_INSIGHT}`, {
      insightName: stringValue
    })

    const { mapping } = this.props.solutionGoals.insightData;
    this.setState({
      page: pages.CREATE_GOAL.number,
      insightListClippedHeight: 0,
      isCustomGoal: false,
      selectedImprovementScore: null,
      selectedTimeline: null,
      selectedKPI: kpi,
      selectedTargetGroup: targetGroup,
      selectedGoalString: stringValue,
      initialKpi: mapping.score_card_mapping[kpi],
      initialTargetGroup: mapping.target_group_mapping[targetGroup],
      modalOpen: false,
    });
  };

  onCreate = async (originalScore) => {
    /**
     * Called to create a new goal based off the user selected values
     */
    const {
      selectedImprovementScore,
      selectedKPI,
      selectedTargetGroup,
      selectedTimeline,
      isCustomGoal,
    } = this.state;

    const { mapping } = this.props.solutionGoals.insightData;
    const { insightData, options } = this.props.solutionGoals;
    const { kpi_mapping, kpi_values, target_group_mapping } = options;
    const valueCheck = [
      {
        label: "Please select an Improvement Score before continuing!",
        value: selectedImprovementScore,
      },
      {
        label: "Please select a KPI before continuing!",
        value: selectedKPI,
      },
      {
        label: "Please select a Target Group before continuing!",
        value: selectedTargetGroup,
      },
      {
        label: "Please select a Timeline before continuing!",
        value: selectedTimeline,
      },
    ];

    // Check to see if any required values were not selected
    for (const i in valueCheck) {
      const row = valueCheck[i];
      if (!row.value) {
        return this.props.showToast({ type: "error", message: row.label });
      }
    }

    const selectedKpiCode = selectedKPI;
    const kpiID = kpi_values.filter(
      (kpi) => kpi.code === selectedKPI
    )[0].id;

    const targetGroups = insightData.target_groups;
    const tg_array = [];
    for (const i in targetGroups) {
      if (targetGroups[i].name === selectedTargetGroup) {
        tg_array.push(targetGroups[i].id);
        break;
      }
    }

    const newGoal = {
      point_improvement: selectedImprovementScore,
      kpis: [kpiID],
      target_groups: tg_array,
      duration: parseFloat(options.timelineMapping[selectedTimeline]),
      survey: insightData.survey_id,
      original_score: originalScore?.toFixed(2),
      is_diversio_insight: !isCustomGoal
    };

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CLICKED_EXPLORE_PROGRAMS_TO_CREATE_GOAL}`, {
      pointImprovementChoice: selectedImprovementScore,
      kpiChoice: selectedKpiCode,
      targetGroupChoice: mapT(m.TARGET_GROUP, selectedTargetGroup),
      timeFrameChoice: selectedTimeline
    })

    await this.props.doSolutionGoalsCreate(newGoal);
  };

  scrollToTop = () => {
    /**
     * Helper function to set the page scroll back to 0 on each goal subpage render
     */
    const goalPageContainer = document.getElementsByClassName(
      "goals-page__container"
    )[0];
    goalPageContainer.scrollTop = 0;
  };

  handleLoadRecommendedPrograms = (page) => {
    /**
     * Load programs that are recommended based off a users selectedKPI.
     * To be updated with selected Target Group as well in the future
     */
    const { selectedKPI, selectedTargetGroup } = this.state;
    const { insightData } = this.props.solutionGoals;
    const { options } = this.props.solutionGoals;
    const { kpi_mapping, kpi_values, target_group_mapping } = options;

    const selectedKpiCode = selectedKPI;

    const kpiID = kpi_values.filter(
      (kpi) => kpi.code === selectedKpiCode
    )[0].id;

    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);

    const targetGroups = insightData.target_groups;
    const tg_array = [];
    for (const i in targetGroups) {
      if (targetGroups[i].name === selectedTargetGroup) {
        tg_array.push(targetGroups[i].id);
        break;
      }
    }

    const query = stringify({
      kpis: [kpiID],
      target_groups: tg_array,
      page: page,
      page_size: 10,
    });

    this.props.getSolutionGoalsRecommendedPrograms({
      id: activeID,
      query: query,
    });
  };

  handleParent = (key, value, groupMapping) => {
    /**
     * Function to be called to update the goal card's parent state
     */
    const { mapping } = this.props.solutionGoals.insightData;
    const { selectedKPI, selectedTargetGroup } = this.state;
    switch (key) {
      case "pointImprovement":
        return this.setState({
          selectedImprovementScore: parseFloat(value),
        });

      case "kpi":
        if (selectedKPI) {
          return this.setState({
            selectedKPI: value,
            selectedImprovementScore: null,
          });
        } else {
          return this.setState({
            selectedKPI: value,
          });
        }

      case "targetGroup":
        if (selectedTargetGroup) {
          return this.setState({
            selectedTargetGroup: groupMapping[value],
            selectedImprovementScore: null,
          });
        } else {
          return this.setState({
            selectedTargetGroup: groupMapping[value],
          });
        }

      case "timeline":
        return this.setState({
          selectedTimeline: value,
        });
    }
  };

  handleModalOpen = () => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHANGE_INSIGHT}`)

    this.setState({
      modalOpen: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      modalOpen: false,
    });
  };

  saveProgram = (row) => {
    const { insightData } = this.props.solutionGoals;
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    const program = {
      company: parseInt(activeID),
      solution: row.id,
      survey_id: insightData.survey_id,
      program_obj: row
    };

    this.props.doSolutionMyAddProgram({
      program: program,
      page: PAGE_NAME
    });
  };

  removeCompanySolution = (row) => {
    this.props.doSolutionRemove({
      id: row.company_solution.id,
      program: row.id,
      program_obj: row,
      page: PAGE_NAME
    });
  };

  activateProgram = (row) => {
    const { insightData } = this.props.solutionGoals;

    this.props.doSolutionActivate({
      solution: row?.company_solution?.id,
      id: insightData?.survey_id,
      fields: { is_active: true },
      page: PAGE_NAME
    });
  };

  deactivateProgram = (row) => {
    const { insightData } = this.props.solutionGoals;
    this.props.doSolutionDeactivate({
      solution: row?.company_solution?.id,
      id: insightData?.survey_id,
      fields: { is_active: false },
      page: PAGE_NAME
    });
  };

  handleSeeAll = () => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.SEE_ALL_SOLUTIONS}`)

    this.props.history.push("/programs")
  }

  handleScrollGoalsPageContainer = () => {
    const insightList = document.querySelector('.select-insight__list')
    
    insightList?.scrollTo({ top: this.goalsPageContainerRef.current?.scrollTop })
  }

  handleChangeInsightListClippedHeight = (insightListClippedHeight, insightListPosition) => {       
    this.setState({
      insightListClippedHeight
    }, () => {
      this.goalsPageContainerRef.current?.scrollTo({top: insightListPosition, behavior: 'smooth'})
    })
  }

  render() {
    const {
      page,
      isCustomGoal,
      selectedImprovementScore,
      selectedKPI,
      selectedTargetGroup,
      selectedTimeline,
      selectedGoalString,
      modalOpen,
      insightListClippedHeight
    } = this.state;
    const { insightData, options, recommendedPrograms, goalCreated, endOfPrograms, isLoaded } =
      this.props.solutionGoals;
    const { programsFilters } = this.props.solutionAllPrograms;
    const { insights, mapping } = insightData;
    const domGroupScore = insightData?.orgData?.["Dominant group"];
    const industryScore = insightData?.orgData?.["Industry Average"];
    const yourScore = insightData?.orgData?.["Your KPI score"];

    const groupScores = insightData?.groupData;

    const renderCardData =
      insightData?.orgData && groupScores && selectedTargetGroup && selectedKPI;
    const companyName = getActiveCompany(this.props.userMetadata?.companies)?.name;

    const insightsLoaded = insights !== undefined
    const hasInsights = insightsLoaded && insights?.length

    const numberOfCoreKpis = options?.kpis?.length
    const numberOfNaKpiScores = Object.values(industryScore || []).filter(score => score === "N/A").length
    const allKpisAreNa = numberOfNaKpiScores === numberOfCoreKpis
    const industryBenchmarkAvailable = industryScore !== undefined &&  !allKpisAreNa

    if (!insightsLoaded) {
      pages.SELECT_INSIGHT.label = ""
    } else if (hasInsights) {
      pages.SELECT_INSIGHT.label = "Select Insight"
    } else {
      if (industryBenchmarkAvailable) { // Scores are above Industry Benchmark scores
        pages.SELECT_INSIGHT.label = "Insight"
      } else {
        pages.SELECT_INSIGHT.label = "Start"
      }
    }

    const translateTargetGroups = () => {
      const translatedGroups = [];
      // used for reversing the translation when setting state
      const mapping = {};
      for (const i in options.groups) {
        const translated = mapT(m.TARGET_GROUP, options.groups[i]);
        translatedGroups.push(translated);
        mapping[translated] = options.groups[i];
      }
      return { translatedGroups, mapping };
    };

    const filteredTargetGroups = () => {
      const groups = translateTargetGroups().translatedGroups;
      if (isCustomGoal) return groups;
      if (!selectedTargetGroup) return null;

      // If not a custom goal then we want to remove the ability to change target groups
      let targetGroupOptions = options.groups;
      const filteredGroup = [];
      for (const i in targetGroupOptions) {
        if (targetGroupOptions[i] === selectedTargetGroup) {
          filteredGroup.push(mapT(m.TARGET_GROUP, targetGroupOptions[i]));
        }
      }
      return filteredGroup;
    };

    const filteredKpis = () => {
      if (isCustomGoal) return options.availableKpis;
      if (!selectedKPI) return null;

      // If not a custom goal then we want to remove the ability to change kpi
      let kpiOptions = options.kpis;
      const filteredKpi = [];
      for (const i in kpiOptions) {
        if (kpiOptions[i] === selectedKPI) {
          filteredKpi.push(kpiOptions[i]);
        }
      }
      return filteredKpi;
    };

    const getScore = (score) => {
      if (NO_DATA.includes(score)) return null;
    
      return score;
    };

    const targetGroupScore = () => {
      if (!selectedTargetGroup || !selectedKPI) return null;
      const score = groupScores[selectedTargetGroup][selectedKPI];

      return getScore(score)
    };

    const filteredImprovementRange = () => {
      const range =
        renderCardData &&
        options?.pointsImprovementRange?.filter(
          (value) => value + targetGroupScore() <= 10
        );
      if (range) return range;
      else return options.pointsImprovementRange;
    };

    const calculateScoreUpTo = () => {
      const { selectedImprovementScore } = this.state;
      const isSelectedImprovementInRange = filteredImprovementRange()?.includes(
        selectedImprovementScore
      );

      const score = renderCardData && targetGroupScore();

      if (NO_DATA.includes(score)) return null;

      if (isSelectedImprovementInRange) {
        return score + selectedImprovementScore;
      }

      return score;
    };

    // Create structure of object to be sent to the goal card component
    const goalCardData = {
      company: companyName,

      pointImprovement: selectedImprovementScore,
      pointImprovementOptions: filteredImprovementRange(),

      kpi: selectedKPI,
      kpiOptions: filteredKpis(),

      targetGroup: mapT(m.TARGET_GROUP, selectedTargetGroup),
      targetGroupOptions: filteredTargetGroups(),

      timeline: selectedTimeline,
      timelineOptions: options.timelineRange,

      scoreUpTo: calculateScoreUpTo(),

      onChange: (key, value) =>
        this.handleParent(key, value, translateTargetGroups().mapping),
      onClick: () => this.onCreate(targetGroupScore()),
    };

    // Initial score card data structure
    const scoreCardData = renderCardData && {
      kpi: {
        title: mapping?.score_card_mapping[selectedKPI],
        icon: selectedKPI,
        score: getScore(yourScore[selectedKPI])
      },
      industry: {
        score: getScore(industryScore[selectedKPI])
      },
      targetGroup: {
        category: mapT(m.GROUP_BREAKDOWN, selectedTargetGroup),
        score: targetGroupScore(),
      },
      groupComparison: {
        score: getScore(domGroupScore[selectedKPI])
      },
    };

    const toastError = this.props.message.toastError;

    const goalsPageWrapperStyle = { height: `calc(100% - 29px + ${insightListClippedHeight}px)` };

    if (goalCreated) {
      // Reset goalCreated prop flag to avoid recursion
      this.props.doneSolutionGoalsCreateError();
      this.setState({
        page: pages.EXPLORE_PROGRAMS.number,
      });
    }
    return (
      <div className={classNames("goals-page", { blur: modalOpen })}>
        {toastError?.type && (
          <div className="ds-toast-component__goals_page">
            <Toast
              open={true}
              type={toastError?.type}
              message={toastError?.message}
              onClick={() => this.props.resetGlobalToast()}
            />
          </div>
        )}

        <div className="goals-page__subpage-navbar">
          <div className="goals-page__return-button">
            {page !== 0 && <ReturnButton onClick={() => this.onBack()} />}
          </div>

          <HorizontalStepper
            activeStep={page}
            stepLabels={Object.values(pages).map((page) => page.label)}
          />
        </div>

        <div className="goals-page__container" onScroll={this.handleScrollGoalsPageContainer} ref={this.goalsPageContainerRef}>
          <div className="goals-page__wrapper" style={goalsPageWrapperStyle}>
            <div className={classNames({ 'goals-page__sticky-wrapper': page === 0 })}>
              <div className="goals-page__stepper">
                <VerticalStepper variant="primary" activeStep={page} />
              </div>

              {page === pages.SELECT_INSIGHT.number ? (
                <SelectInsight
                    insights={insights}
                    industryBenchmarkAvailable={industryBenchmarkAvailable}
                    onScrollToTop={() => this.scrollToTop()}
                    onNextRegular={(keySelected, targetGroup, goalString) =>
                      this.onNextRegular(keySelected, targetGroup, goalString)
                    }
                    onNextCustom={() => this.onNextCustom()}
                    onReset={() => this.props.getSolutionGoalsReset()}
                    viewRecommended={(values) =>
                      this.props.history.push("/recommended", values)
                    }
                    endOfGoalsPagePrograms={() => this.props.endOfGoalsPagePrograms(false)}
                    mapping={mapping}
                    mapT={mapT}
                    m={m}
                    onChangeInsightListClippedHeight={this.handleChangeInsightListClippedHeight}
                />
              ) : page === pages.CREATE_GOAL.number ? (
                <CreateGoal
                  isCustomGoal={isCustomGoal}
                  scoreCardData={scoreCardData}
                  goalCardData={goalCardData}
                  selectedGoalString={selectedGoalString}
                  onScrollToTop={() => this.scrollToTop()}
                  onModalOpen={() => this.handleModalOpen()}
                />
              ) : page === pages.EXPLORE_PROGRAMS.number ? (
                <ExplorePrograms
                  isCustomGoal={isCustomGoal}
                  recommendedPrograms={recommendedPrograms}
                  filters={programsFilters}
                  selectedGoalString={selectedGoalString}
                  selectedImprovementScore={selectedImprovementScore}
                  selectedKPI={mapping?.score_card_mapping[selectedKPI]}
                  selectedTargetGroup={selectedTargetGroup}
                  selectedTimeline={selectedTimeline}
                  companyName={companyName}
                  onBack={() => this.onBack()}
                  onScrollToTop={() => this.scrollToTop()}
                  onLoadRecommendedPrograms={(page) =>
                    endOfPrograms ? this.props.showToast({type: "warning", message: "No more programs available"}) : this.handleLoadRecommendedPrograms(page)
                  }
                  onSaveProgram={(row) => this.saveProgram(row)}
                  onRemoveCompanySolution={(row) =>
                    this.removeCompanySolution(row)
                  }
                  onActivateProgram={(row) => this.activateProgram(row)}
                  onDeactivateProgram={(row) => this.deactivateProgram(row)}
                  onSeeAll={() => this.handleSeeAll()}
                  mapT={mapT}
                  m={m}
                  pageName={PAGE_NAME}
                  isLoadedRecommendedPrograms={isLoaded.recommendedPrograms}
                />
                ) : null}
            </div>
          </div>
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalOpen}
          onClose={() => this.handleModalClose()}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalOpen}>
            <Box className="goals-page-modal">
              <div className="goals-page-modal__header">
                <h2 className="goals-page-modal__title">Change Insight</h2>

                <Button
                  iconOnly
                  color="transparent"
                  size="medium"
                  onClick={() => this.handleModalClose()}
                >
                  <CloseIcon type="line" />
                </Button>
              </div>

              <div className="goals-page-modal__body">
                {insights?.map((row, index) => {
                  const translatedTargetGroup = mapT(
                    m.TARGET_GROUP,
                    row.target_group
                  );
                  const cleanedTitle = row.title.replace(
                    row.target_group,
                    translatedTargetGroup
                  );

                  return (
                    <div key={index} className="goals-page-modal__row">
                      <p className="goals-page-modal__text">{cleanedTitle}</p>

                      {row.goal_created ? (
                        <div className="goals-page-modal__row--wrapper">
                          <CheckmarkIcon
                            type="line"
                            color="#43E09E"
                            height={31.5}
                            width={31.5}
                          />

                          <p className="select-insight__list-item-status-text">
                            Goal Created
                          </p>
                        </div>
                      ) : (
                        <Button
                          type="button"
                          size="medium"
                          rightIcon={<ChevronRightIcon type="bold" />}
                          color="secondary"
                          onClick={() =>
                            this.onNextRegular(
                              row.key,
                              row.target_group,
                              cleanedTitle
                            )
                          }
                        >
                          Create Goal
                        </Button>
                      )}
                    </div>
                  );
                })}

                <div className="goals-page-modal__row goals-page-modal__row--custom">
                  <p className="goals-page-modal__text">
                    Want to create a goal unrelated to the above insights?
                  </p>

                  <Button
                    type="button"
                    size="medium"
                    color="secondary"
                    rightIcon={<ChevronRightIcon type="bold" />}
                    onClick={() => this.onNextCustom()}
                  >
                    Create Custom
                  </Button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, Actions)(withHooks(SolutionGoals));
