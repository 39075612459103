import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import mixpanel from 'mixpanel-browser';

import Link from '../utils/Link';
import diversioLogo from '../../assets/images/diversio-logo.png'

import "./index.scss";

const Login = ({ onSubmit, t, message, username: user, passwordOnly = false }) => {
    const location = useLocation()

    useEffect(() => {
        if (location?.state?.resetMixpanel) {
            mixpanel.reset();
        }
    }, [])
    

    const loginSchema = yup.object().shape({
        username: yup.string().required(),
        password: yup.string().required(),
    });

    const {register, handleSubmit, errors} = useForm({
        resolver: yupResolver(loginSchema)
    });

    return (
        <form id={"auth-form"} className={'auth-form'} onSubmit={handleSubmit(onSubmit)}>
            { !passwordOnly && <img src={diversioLogo} className="logo" alt="Diversio Global"/>}

            {
                message && <div className={'auth-form__success-message'}>{message}</div>
            }

            <TextField
                className={classNames("connect-form__text-field", { hidden: passwordOnly })}
                inputRef={register}
                error={Boolean(errors?.username)}
                helperText={errors ? errors?.username?.message : ''}
                name={'username'}
                label={t("AUTH.LOGIN.USERNAME_EMAIL")}
                margin="normal"
                variant="outlined"
                type={"text"}
                value={user}
                readOnly={passwordOnly}
            />

            <TextField
                autoComplete={"new-password"}
                className={"connect-form__text-field"}
                inputRef={register}
                error={Boolean(errors?.password)}
                helperText={errors ? errors?.password?.message : ''}
                name={'password'}
                label={t("AUTH.LOGIN.PASSWORD")}
                margin="normal"
                variant="outlined"
                type={"password"}
            />

            <Button className={"login__btn"} type={"submit"} variant="contained" color="primary">
                {t("AUTH.LOGIN.LOGIN")}
            </Button>
            {
                !passwordOnly && <Link className={"auth-form__login-with-sso"} to={"/sso"}>{t("AUTH.SSO.LOGIN_WITH_SSO")}</Link>
            }

            {
                !passwordOnly &&
                <div className="forgot-password-button text-center">
                    {t("AUTH.LOGIN.FORGOT_PASSWORD")} <br/> <Link to={"/forgot-password"}>{t("AUTH.LOGIN.RESET_PASSWORD")}</Link>
                </div>
            }
        </form>

    );
};

export default withTranslation()(Login);
