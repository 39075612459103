import styled from 'styled-components'
import { colors, getFontStyle, Modal as DSModal } from '@diversioteam/diversio-ds'

import v1switch from 'images/home/v1switch.jpg'
import v2switch from 'images/home/v2switch.jpg'

type ImageProps = {
    $version: string
}

export const Modal = styled(DSModal)`
    margin-left: 0;

    .dds-modal__content {
        width: 530px;
    }

    .dds-modal__close-button {
        display: none;
    }
`

export const Filters = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
`

export const Image = styled.img<ImageProps>`
    background: ${({ $version }) => {
        return `url(${$version === 'v1' ? v1switch : v2switch});`
    }};
    height: 300px;
    width: 450px;
    background-size: cover;
    border-radius: 20px;
`

export const Title = styled.header`
    color: ${colors.primary90};
    padding: 0 30px;
    text-align: center;
    margin-top: 30px;

    ${getFontStyle('cardHeaderBold')}
`

export const Subtitle = styled.p`
    color: ${colors.secondary500};
    padding: 0 30px;
    text-align: center;
    margin-top: 18px;

    ${getFontStyle('p03Regular')}
`

export const Actions = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
    margin-top: 18px;
`
