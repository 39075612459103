import { useMutation } from '@tanstack/react-query'
import { loadInclusionDataOverTime } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import { InclusionDataProps } from 'hooks/useInclusionScoresOverTime'
import { Mutations } from 'api/mutations.enum'

export const useExportInclusionDataOverTime = (params: InclusionDataProps) => {
    const mutation = useMutation<string, Error, void>({
        mutationKey: [Mutations.exportInclusionDataOverTime, params],
        mutationFn: () => {
            return loadInclusionDataOverTime(stringifyQueryParams(params), 'file')
        },
    })

    return mutation
}
