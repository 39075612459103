import { AxiosError } from 'axios'

import { AuthErrorResponse, AuthResponse, LoginPayload } from 'api/actions/auth/authActions.types'

export type LoginProps = {
    isLoading: boolean
    isSuccess: boolean
    data?: AuthResponse
    isError: boolean
    error: AxiosError<AuthErrorResponse> | null
    disabled?: boolean
    onSubmit(values: LoginPayload): Promise<boolean>
}

export enum LoginFields {
    username = 'username',
    password = 'password',
}

export type LoginFormData = {
    [LoginFields.username]: string
    [LoginFields.password]: string
}
