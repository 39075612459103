import React from 'react'
import { InclusionHeatMapCard, InclusionHeatMapCardSkeleton } from '@diversioteam/diversio-ds'
import { Link } from 'react-router-dom'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useBenchmarks } from 'hooks/benchmarks/useBenchmarks'
import { useSetPrimaryBenchmark } from 'hooks/benchmarks/useSetPrimaryBenchmark'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { useGetInclusionHeatmap } from 'hooks/useGetInclusionHeatmap'
import { AppRoute } from 'routing/AppRoute.enum'

const InclusionHeatmapWithoutEB = () => {
    const { data, isLoading } = useGetInclusionHeatmap()
    const { primaryIndustryBenchmark, queryIndustryBenchmarks } = useBenchmarks()
    const mutationSetPrimaryIndustryBenchmark = useSetPrimaryBenchmark('industry')

    if (isLoading) {
        return <InclusionHeatMapCardSkeleton data-testid="inclusion-heatmap-card-skeleton" />
    }

    if (!data || data?.length === 0) {
        return null
    }

    return (
        <InclusionHeatMapCard
            results={data}
            titleWrapperProps={{
                component: Link,
                to: generateCompanyURI(AppRoute.AnalyzeInclusion),
            }}
            onSwitchPrimaryIndustry={mutationSetPrimaryIndustryBenchmark.mutate}
            switchOptions={
                queryIndustryBenchmarks.data?.map((industry) => ({
                    label: industry.label,
                    value: industry.uuid,
                })) || []
            }
            switchValue={primaryIndustryBenchmark?.uuid}
            data-testid="inclusion-heatmap-card"
        />
    )
}

export const InclusionHeatmap = withErrorBoundary(InclusionHeatmapWithoutEB, {})
