import styled from 'styled-components'

export const Table = styled.table`
    border-spacing: 4px;

    .dds-heat-map-class-cell {
        margin: 0 8px;
    }

    &.inclusion {
        z-index: 7;
        table-layout: fixed;

        .dds-heat-map-class-cell {
            border-bottom: 2px solid ${({ theme }) => theme.colors.primary35};

            &::before,
            &::after {
                background-color: ${({ theme }) => theme.colors.primary35};
            }
        }
    }

    .dds-heat-map-class-cell {
        border-bottom: 2px solid ${({ theme }) => theme.colors.primary30};

        &::before,
        &::after {
            background-color: ${({ theme }) => theme.colors.primary30};
        }
    }
`

export const TableHeader = styled.tr`
    th {
        width: 168px;

        div {
            align-items: center;
            padding: 0px 12px;
            text-align: center;
            background-color: ${({ theme }) => theme.colors.primaryWhite};
        }
    }
`
