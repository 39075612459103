import axios from 'axios'

export const apiUrl = process.env.REACT_APP_DASHBOARD_API_ROOT
export const apiUrlV2 = process.env.REACT_APP_DASHBOARD_API_ROOT_V2

export const diversioApi = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    baseURL: apiUrl,
})

export const diversioApiV2 = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    baseURL: apiUrlV2,
})
