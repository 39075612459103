import React from 'react';
import { Route, Switch} from 'react-router-dom';
import Dashboard from './containers/Dashboard';
import { LoginContainer } from 'containers/Auth/Login';
import { SSOContainer } from "./containers/Auth/Sso";
import { ForgotPasswordContainer } from "containers/Auth/ForgotPassword";
import { ResetPasswordContainer } from 'containers/Auth/ResetPassword';
import Register from './containers/Auth/Register';
import SelfServeRegister from './containers/Auth/SelfServeRegister';
import { SendVerificationCodeContainer } from 'containers/Auth/SendVerificationCode';
import { VerifyEmailCodeContainer } from 'containers/Auth/VerifyEmailCodeContainer';
import { VerifyBackupCodeContainer } from 'containers/Auth/VerifyBackupCodeContainer';
import ErrorPage from "./components/ErrorPages";
import { useQueryClient } from '@tanstack/react-query';
import { ScheduledMaintenance } from 'components/Auth/ScheduledMaintenance';

const Routes = () => {
    const queryClient = useQueryClient()

    return (
            <Switch>
                <Route path='/login' component={LoginContainer}/>
                <Route path='/register' component={Register}/>
                <Route path='/sso' component={SSOContainer}/>
                <Route path='/sso/callback' component={SSOContainer}/>
                <Route path='/self-serve-register' component={SelfServeRegister}/>
                <Route path='/forgot-password' component={ForgotPasswordContainer}/>
                <Route path='/reset-password/:token' component={ResetPasswordContainer}/>
                <Route path='/send-verification-code/' component={SendVerificationCodeContainer}/>
                <Route path='/two-step-verification' component={VerifyEmailCodeContainer}/>
                <Route path='/two-step-verification-backup' component={VerifyBackupCodeContainer} />
                <Route path='/scheduled-maintenance' component={ScheduledMaintenance}/>
                <Route path='/error' component={ErrorPage}/>
                <Route path={'/'} render={(props) => <Dashboard queryClient={queryClient} {...props} />} ></Route>
            </Switch>
    )
};

export default Routes;
