import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import mixpanel from 'mixpanel-browser'

import { AppRoute } from 'routing/AppRoute.enum'
import { RootState } from 'reducers/rootState.types'
import { VerifyEmailCode } from 'components/Auth/VerifyEmailCode'
import { VerifyEmailCodeFormData } from 'components/Auth/VerifyEmailCode/verifyEmailCode.types'
import { useRequestVerificationCode } from 'hooks/auth/useRequestVerificationCode/useRequestVerificationCode'
import { useValidateVerificationCode } from 'hooks/auth/useValidateVerificationCode/useValidateVerificationCode'

import TAXONOMIES from './../../../utils/taxonomies'
import { Actions } from './../../../actions'
import { RedirectAuthenticatedUser } from './../RedirectAuthenticatedUser'

export const VerifyEmailCodeContainer = () => {
    const { accessToken, refreshToken, isAuthenticated, backupCodes, username, email } = useSelector(
        (state: RootState) => state.auth,
    )

    const dispatch = useDispatch()

    const history = useHistory()

    const handleOnAction = () => {
        dispatch(Actions.doRefreshToken({ accessToken, refreshToken }))
    }

    const handleOnIdle = () => {
        dispatch(Actions.doSignout())
    }

    useIdleTimer({
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        timeout: 1000 * 60 * Number(process.env.REACT_APP_INACTIVE_EXPIRE_TIME),
        throttle: 1000 * 60 * Number(process.env.REACT_APP_TOKEN_REFRESH_TIME),
    })

    const mutationRequestVerificationCode = useRequestVerificationCode()
    const mutationValidateVerificationCode = useValidateVerificationCode()

    const handleResendCode = useCallback(async () => {
        try {
            await mutationRequestVerificationCode.mutateAsync({
                verification_type: 'email',
            })

            return true
        } catch (error) {
            return false
        }
    }, [mutationRequestVerificationCode])

    const handleSubmit = useCallback(
        async (values: VerifyEmailCodeFormData): Promise<boolean> => {
            try {
                mutationRequestVerificationCode.reset()

                mixpanel.track(TAXONOMIES.TWO_FACTOR_AUTHENTICATION_CODE_SUBMITTED, {
                    twoFactorClickedAt: new Date().toISOString(),
                    username: username,
                    verificationType: 'email',
                    trustDevice: values.trustedDevice,
                })

                await mutationValidateVerificationCode.mutateAsync({
                    verification_type: 'email',
                    ...values,
                })

                return true
            } catch (error) {
                return false
            }
        },
        [mutationValidateVerificationCode],
    )

    const handleClickBack = () => {
        return history.push({ pathname: AppRoute.SendVerificationCode, search: location.search })
    }

    if (!accessToken) {
        return <Redirect to={AppRoute.Login} />
    }

    if (isAuthenticated) {
        if (backupCodes?.length) {
            return (
                <Redirect
                    to={{
                        pathname: AppRoute.AccountMfa,
                        state: { backupCodes },
                    }}
                />
            )
        }

        return <RedirectAuthenticatedUser />
    }

    return (
        <VerifyEmailCode
            isLoading={mutationValidateVerificationCode.isLoading}
            isSuccess={mutationRequestVerificationCode.isSuccess}
            data={mutationRequestVerificationCode.data}
            isError={mutationValidateVerificationCode.isError || mutationRequestVerificationCode.isError}
            error={mutationValidateVerificationCode.error || mutationRequestVerificationCode.error}
            email={email}
            onResendCode={handleResendCode}
            onClickBack={handleClickBack}
            onSubmit={handleSubmit}
        />
    )
}
