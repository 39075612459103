const TAXONOMIES = {
    WORKFORCE: 'workforce',
    SELF_SERVE: 'self-serve',
    SELF: 'self-serve',
    CURR: 'returning-dashboard-user',
    SIGN_IN: 'Sign In',
    CLICKED_CREATE_SURVEY: 'SurveyInit: Clicked Create Survey Button',
    SURVEY_OPTIONS_CHANGED: 'SurveySetup: Options Changed',
    CLICKED_SURVEY_SETUP_NEXT: 'SurveySetup: Clicked Next',
    CLICKED_SURVEY_CREATE_NEXT: 'SurveyCreate: Clicked Next',
    CLICKED_SURVEY_SETUP_BACK: 'SurveySetup: Clicked Back',
    CLICKED_SURVEY_CREATE_BACK: 'SurveyCreate: Clicked Back',
    CLICKED_CANCEL_BUTTON: 'Survey: Clicked Cancel',
    SETUP_ONBOARDING_COMPLETE: 'SurveySetup: Onboarding Completed',
    SETUP_ONBOARDING_SKIPPED: 'SurveySetup: Onboarding Skipped',
    CREATE_ONBOARDING_COMPLETE: 'SurveyCreate: Onboarding Completed',
    CREATE_ONBOARDING_SKIPPED: 'SurveyCreate: Onboarding Skipped',
    LIVE_ONBOARDING_COMPLETE: 'SurveyLive: Onboarding Completed',
    LIVE_ONBOARDING_SKIPPED: 'SurveyLive: Onboarding Skipped',
    CLICKED_SAVE_DRAFT: 'SurveyCreate: Clicked Save Draft Button',
    CLICKED_CONTINUE_DRAFT: 'Survey: Clicked Continue Drafted Survey',
    EDIT_INTRO_SECTION: 'SurveyCreate: Edited Intro Section',
    ADD_ANOTHER_OPTION: 'SurveyCreate: Clicked Add Another Option',
    REMOVE_OPTION: 'SurveyCreate: Clicked Remove an Option',
    VIEWED_QUESTION_TOOLTIP: 'Survey: Viewed Question Tooltip',
    VIEWED_CHOICE_TOOLTIP: 'Survey: Viewed Choice Tooltip',
    NEXT_BUTTON_CLICKED: 'SurveyCreate: Next Button Clicked',
    COPY_SURVEY_LINK: 'SurveyLive: Copy Survey Link',
    REFRESH_SURVEY_RESPONSES: 'SurveyLive: Clicked Refresh Survey Responses',
    TOGGLED_COMMUNICATION_TEMPLATES_TAB: 'SurveyLive: Toggled Communication Templates Tab',
    COPIED_COMMUNICATION_TEMPLATE: 'SurveyLive: Copied Communication Template',
    TOGGLED_YOUR_SURVEY: 'SurveyLive: Toggled Your Survey Tab',
    CLICKED_CLOSE_SURVEY: 'SurveyLive: Clicked Close Survey Button',
    DASHBOARD_CREATED: 'DiversioBasic: Dashboard Created',
    DASHBOARD_SESSION_INITIALIZED: 'Dashboard Session Initialized',
    DASHBOARD_SESSION_ENDED: 'Dashboard Session Ended',
    VIEW_PAGE: 'View Page',
    PAGE_SESSION_ENDED: 'Page Session Ended',
    DOWNLOAD_BACKUP_CODE: 'Download Backup Code',
    REFRESH_BACKUP_CODE: 'Refresh Backup Code',
    SELECT_DIVERSITY_OR_INCLUSION_PAGE: 'Select Diversity or Inclusion Page',
    USE_FILTER: 'Use Filter',
    DIVERSITY_ROLE_GRANULAR_BREAKDOWN: 'Diversity Role Granular Breakdown',
    DIVERSITY_OVERVIEW_GRANULAR_BREAKDOWN: 'Diversity Overview Granular Breakdown',
    USE_PICHART: 'Use PiChart',
    TOP_PAIN_POINTS_BREAKDOWN: 'Top Pain Points Breakdown',
    DOWNLOAD_CSV: 'Download CSV',
    SELECT_TAB: 'Select Tab',
    INCLUSION_BREAKDOWN_TAB_CHANGED: 'Inclusion Breakdown Tab Changed',
    INCLUSION_OVER_TIME_BREAKDOWN: 'Inclusion Over Time Breakdown',
    INCLUSION_OVER_TIME_FILTERS_APPLIED: 'Inclusion Over Time Filters Applied',
    SELECT_DEMOGRAPHICS_OR_ENGAGEMENT_PAGE: 'Select Demographics or Engagement Page',
    DEMOGRAPHIC_OVERVIEW_GRANULAR_BREAKDOWN: 'Demographic Overview Granular Breakdown',
    CONTACT_US_CLICKED: 'Contact Us Clicked',
    LEARN_MORE_CLICKED: 'Learn More Clicked',
    ADD_YOUR_OWN_SOLUTION_CLICKED: 'Add Your Own Solution Clicked',
    LOAD_MORE_PROGRAMS: 'Load More Programs',
    PROGRAM_DETAILS_VIEWED: 'Program Details Viewed',
    TOOL_CLICKED: 'Tool Clicked',
    CHANGE_PAGE: 'Change Page',
    CHANGE_ROWS_PER_PAGE: 'Change Rows Per Page',
    SEARCH_TERM: 'Search term',
    CHOOSES_KPI_FILTER: 'Chooses KPI Filter',
    CHOOSES_TARGET_GROUP_FILTER: 'Chooses Target Group Filter',
    REMOVE_KPI_FILTER: 'Remove KPI Filter',
    REMOVE_TARGET_GROUP_FILTER: 'Remove Target Group Filter',
    SEARCH_CLICKED: 'Search Clicked',
    RETURN_BUTTON_CLICKED: 'Return Button Clicked',
    CLICKED_CREATE_CUSTOM_GOAL: `Clicked 'Create a Custom Goal'`,
    CLICKED_CREATE_GOAL_FROM_INSIGHT: `Clicked 'Create Goal' from Insight`,
    CLICKED_EXPLORE_PROGRAMS_TO_CREATE_GOAL: `Clicked 'Explore Programs' to create goal`,
    CHANGE_INSIGHT: 'Change Insight',
    SEE_ALL_SOLUTIONS: 'See All Solutions',
    UPDATES_GOAL: 'Updates Goal',
    DELETE_GOAL: 'DeleteGoal',
    SEE_PROGRAM_DETAILS: 'See Program Details',
    FINISHED_CREATING_GOAL_FROM_BANNER: 'Finished Creating Goal from banner.',
    CREATE_GOAL_FROM_BANNER: 'Create Goal from Banner',
    TOGGLE_INSIGHTS: 'Toggle Insights',
    LOAD_MORE_TOOLS: 'Load More Tools',
    SIGN_UP: 'Sign Up',
    RESET_PASSWORD: 'Reset Password',
    SAVE_PROGRAM: 'Save Program',
    REMOVE_PROGRAM_FROM_SAVED: 'Remove Program from Saved',
    ACTIVATE_PROGRAM: 'Activate Program',
    DEACTIVATE_PROGRAM: 'Deactivate Program',
    ARCHIVE_PROGRAM: 'Archive Program',
    REMOVE_FROM_ARCHIVED: 'Remove From Archived',
    SAVE_TOOL: 'Save Tool',
    REMOVE_FROM_SAVED: 'Remove From Saved',
    CLICKS_ON_TOOLS: 'Clicks on Tools',
    TWO_FACTOR_AUTHENTICATION_CODE_REQUESTED: 'Two Factor Authentication Code Requested',
    TWO_FACTOR_AUTHENTICATION_CODE_SUBMITTED: 'Two Factor Authentication Code Submitted',
    MYSOLUTIONS_UPDATE_GOAL: 'MySolutions: Updates Goal',
    MYSOLUTIONS_DELETE_GOAL: 'MySolutions: DeleteGoal',
    MYSOLUTIONS_SEE_PROGRAM_DETAILS: 'MySolutions: See Program Details',
    COMMUNICATION_TEMPLATES: 'Communication Templates',
    PORTCO: 'Diversio for Portfolios',
    COMPANY: 'Diversio for Companies',
}

export default TAXONOMIES
