import React, { useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
    Button,
    ToyFaces,
    ReloadIcon,
    ProgramCell,
    ProgramCellSkeleton,
    PeopleIcon,
    kpiMap,
} from '@diversioteam/diversio-ds'
import mixpanel from 'mixpanel-browser'
import { getActiveCompany } from 'utils'

import { useGetRecommendedProgramsList } from 'hooks/recommended/useGetRecommendedProgramsList'
import { useSaveRecommendedProgram } from 'hooks/recommended/useSaveRecommendedProgram'
import { useDeleteRecommendedProgram } from 'hooks/recommended/useDeleteRecommendedProgram'
import { useChangeRecommendedProgramStatus } from 'hooks/recommended/useChangeRecommendedProgramStatus'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { useGetSolutionGoalCardOptions } from 'hooks/goals/useGetSolutionGoalCardOptions'
import { mapIcons } from '../../../../utils/kpiUtils'
import { getPageName, mapProgramToTracking } from '../../../../utils/tracking'
import { getCleanedKpiCodes } from 'containers/ImprovePages/utils/getCleanedKpiCodes'
import { AppRoute } from 'routing/AppRoute.enum'
import { RecommendedProgram } from 'api/actions/recommended/recommendedActions.types'
import TAXONOMIES from '../../../../utils/taxonomies'

import { ExploreProgramsProps } from './explorePrograms.types'
import * as S from './explorePrograms.styles'

const PAGE_NAME = 'goals'

export const ExplorePrograms = ({
    isCustomGoal,
    kpi,
    timeline,
    goalTitle,
    targetGroup,
    pointImprovementScore,
    onScrollToTop,
}: ExploreProgramsProps) => {
    const queryUserMetaData = useUserMetadata()
    const mutationSaveRecommendedProgram = useSaveRecommendedProgram()
    const mutationDeleteRecommendedProgram = useDeleteRecommendedProgram()
    const mutationChangeRecommendedProgramStatus = useChangeRecommendedProgramStatus()

    const querySolutionGoalCardOptions = useGetSolutionGoalCardOptions()
    const kpiId = querySolutionGoalCardOptions.data?.kpiValues?.find(({ code }) => code === kpi)?.id
    const targetGroupId = querySolutionGoalCardOptions.data?.targetGroup[targetGroup].id

    const queryRecommendedProgramsList = useGetRecommendedProgramsList({
        ...(kpiId && { kpis: [kpiId] }),
        ...(targetGroupId && { target_groups_v2: [targetGroupId] }),
    })

    const companyName = getActiveCompany(queryUserMetaData.data?.companies).name

    useEffect(() => {
        onScrollToTop()
    }, [])

    const handleLoadMore = () => {
        queryRecommendedProgramsList.fetchNextPage()

        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.LOAD_MORE_PROGRAMS}`)
    }

    const handleOpenProgramDropDown = (program: RecommendedProgram) => {
        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.PROGRAM_DETAILS_VIEWED}`, mapProgramToTracking(program))
    }

    const handleClickProgramTool = (toolTitle: string) => {
        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.TOOL_CLICKED}`, {
            toolName: toolTitle,
        })
    }

    const programsSkeletons = new Array(10).fill('').map((_element, index) => {
        return <ProgramCellSkeleton key={index} />
    })

    const recommendedPrograms = queryRecommendedProgramsList.data?.pages

    return (
        <S.ExplorePrograms>
            <S.Header>
                <S.Steps className="explore-programs__steps">
                    <S.StepsItemSecondary>
                        <S.TitleSecondary>Insight</S.TitleSecondary>

                        <S.DescriptionSecondary>
                            {isCustomGoal ? 'You chose to create a custom goal unrelated.' : `${goalTitle}.`}
                        </S.DescriptionSecondary>
                    </S.StepsItemSecondary>

                    <S.StepsItemSecondary>
                        <S.TitleSecondary>Goal</S.TitleSecondary>

                        <S.DescriptionSecondary>
                            {`${companyName} will achieve a ${pointImprovementScore} point improvement in ${kpiMap[kpi]} for
              ${targetGroup} in ${timeline}.`}
                        </S.DescriptionSecondary>
                    </S.StepsItemSecondary>

                    <S.StepsItemPrimary>
                        <S.TitlePrimary as="h1">Explore Programs</S.TitlePrimary>

                        <S.ProgramsLogo height={30} width={240} variant="watermarkLight" />

                        <S.DescriptionPrimary>
                            Here is what we recommend to help you reach this goal.
                        </S.DescriptionPrimary>
                    </S.StepsItemPrimary>
                </S.Steps>

                <S.Avatars>
                    <ToyFaces numAvatars={2} />
                </S.Avatars>
            </S.Header>

            <S.SubHeader>
                <Button color="transparent" size="small" component={RouterLink} to={AppRoute.Programs}>
                    See All
                </Button>
            </S.SubHeader>

            <S.List>
                {queryRecommendedProgramsList.isLoading
                    ? programsSkeletons
                    : recommendedPrograms?.flatMap(({ results }) =>
                          results.map((program) => {
                              const { id, description, title, companySolution, painPoints } = program

                              const tools =
                                  program.tools?.map((tool) => {
                                      const { title, url, mediaType, type } = tool

                                      return {
                                          caption: title,
                                          href: url,
                                          icon: mapIcons(tool),
                                          title: mediaType,
                                          withBadge: type !== 'resource',
                                      }
                                  }) || []

                              const labels =
                                  program.targetGroupsV2?.map(({ name }) => ({
                                      icon: <PeopleIcon type="line" />,
                                      label: name,
                                  })) || []

                              const kpis = getCleanedKpiCodes(painPoints)

                              if (!kpis) {
                                  return
                              }

                              return (
                                  <ProgramCell
                                      key={id}
                                      kpis={kpis}
                                      programName={title}
                                      description={description}
                                      tools={tools}
                                      programLabels={labels}
                                      isSaved={!!companySolution}
                                      isAdded={companySolution?.isActive}
                                      onSave={() => mutationSaveRecommendedProgram.mutate({ solution: id })}
                                      onRemoveFromSaved={() =>
                                          companySolution && mutationDeleteRecommendedProgram.mutate(companySolution.id)
                                      }
                                      onAddToActive={() =>
                                          companySolution &&
                                          mutationChangeRecommendedProgramStatus.mutate({
                                              id: companySolution.id,
                                              is_active: true,
                                          })
                                      }
                                      onRemoveFromActive={() =>
                                          companySolution &&
                                          mutationChangeRecommendedProgramStatus.mutate({
                                              id: companySolution.id,
                                              is_active: false,
                                          })
                                      }
                                      onOpenDropdown={() => handleOpenProgramDropDown(program)}
                                      onClickTool={(toolTitle) => handleClickProgramTool(toolTitle)}
                                  />
                              )
                          }),
                      )}

                {queryRecommendedProgramsList.hasNextPage && (
                    <S.LoadMore>
                        <Button
                            type="button"
                            size="medium"
                            color="secondary"
                            rightIcon={<ReloadIcon type="line" />}
                            loading={queryRecommendedProgramsList.isFetchingNextPage}
                            onClick={handleLoadMore}
                        >
                            Load More
                        </Button>
                    </S.LoadMore>
                )}
            </S.List>
        </S.ExplorePrograms>
    )
}
