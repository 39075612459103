/** @format */

import { call, put } from "redux-saga/effects";
import mixpanel from "mixpanel-browser";

import { Actions, ActionTypes } from "../actions";
import { getPageName, mapProgramToTracking } from "../utils/tracking";
import {
  loadSolutionAllPrograms,
  loadSolutionAllProgramsFilters,
  loadSolutionPatch,
  loadSolutionMyAddProgram,
  loadSolutionDeleteCompanySolution,
  getAllPrograms,
  deleteProgram,
  saveProgram,
  changeProgramStatus,
  changeMySolutionStatus,
} from "./network";
import TAXONOMIES from "../utils/taxonomies";

// All data sagas to add to middleware.
export default [
  [ActionTypes.GET_SOLUTION_ALL_PROGRAMS, getSolutionAllPrograms],
  [ActionTypes.GET_ALL_PROGRAMS_V2, getAllProgramsV2],
  [
    ActionTypes.GET_SOLUTION_ALL_PROGRAMS_FILTERS,
    getSolutionAllProgramsFilters,
  ],
  [ActionTypes.DO_SOLUTION_MY_ADD_PROGRAM, doSolutionMyAddProgram],
  [ActionTypes.DO_SAVE_PROGRAM_V2, doSaveProgramV2],
  [ActionTypes.DO_SOLUTION_REMOVE, doSolutionRemove],
  [ActionTypes.DO_SOLUTION_REMOVE_V2, doSolutionRemoveV2],
  [ActionTypes.DO_SOLUTION_ACTIVATE, doSolutionActivate],
  [ActionTypes.DO_SOLUTION_DEACTIVATE, doSolutionDeactivate],
  [ActionTypes.DO_SOLUTION_ARCHIVE, doSolutionArchive],
  [ActionTypes.DO_CHANGE_SOLUTION_STATUS, doChangeSolutionStatus],
  [ActionTypes.DO_SOLUTION_ACTIVATE_V2, doSolutionActivateV2],
  [ActionTypes.DO_SOLUTION_DEACTIVATE_V2, doSolutionDeactivateV2],
  [ActionTypes.DO_SOLUTION_ARCHIVE_V2, doSolutionArchiveV2],
];

function* getSolutionAllPrograms({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  yield put(Actions.loadingSolutionAllPrograms());
  /*
        Call the api endpoint

    */
  const response = yield call(loadSolutionAllPrograms, payload);
  /*
        Process the response
    */
  if (!response.successful) {
    // Show message here
    yield put(
      Actions.showToast({
        type: "error",
        message: `${response.data.detail}`,
      })
    );
    yield put(Actions.loadedSolutionAllPrograms([]));
  } else {
    yield put(Actions.loadedSolutionAllPrograms(response.data));
  }
}

function* getAllProgramsV2({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  yield put(Actions.loadingAllProgramsV2());
  /*
        Call the api endpoint

    */
  const response = yield call(getAllPrograms, payload.query);
  /*
        Process the response
    */
  if (response.data?.detail) {
    // Show message here
    yield put(
      Actions.showToast({
        type: "error",
        message: `${response.data.detail}`,
      })
    );
    yield put(Actions.loadedAllProgramsV2([]));
  } else {
    yield put(Actions.loadedAllProgramsV2(response));
  }
}

function* getSolutionAllProgramsFilters({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  /*
        Call the api endpoint

    */
  const response = yield call(loadSolutionAllProgramsFilters, payload);
  /*
        Process the response
    */
  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.loadedSolutionAllProgramsFilters(response.data));
  }
}

function* doSolutionMyAddProgram({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  /*
        Call the api endpoint

    */
  const response = yield call(loadSolutionMyAddProgram, payload.program);
  /*
              Process the response
          */
  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    // Do this to update the UI to show the user saved the program
    const page = payload.page;
    switch (page) {
      case "all-programs":
        yield put(
          Actions.allProgramsPageAddProgram({
            program: response.data.solution,
            companySolution: response.data,
          })
        );  
      case "recommended":
        yield put(
          Actions.recommendedPageAddProgram({
            program: response.data.solution,
            companySolution: response.data,
          })
        );
      case "goals":
        yield put(
          Actions.goalsPageAddProgram({
            program: response.data.solution,
            companySolution: response.data,
          })
        );
      default:
        yield put(
          Actions.showToast({
            type: "success",
            message: "Program has been saved!",
          })
        );

      mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.SAVE_PROGRAM}`, mapProgramToTracking(payload.program.program_obj))
    }
  }
}

function* doSaveProgramV2({ payload }) {
  const response = yield call(saveProgram, payload);
  
  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(
      Actions.doneSaveProgramV2({
        program: response.solution,
        companySolution: response,
      })
    );

    yield put(
      Actions.showToast({
        type: "success",
        message: "Program has been saved!",
      })
    );
  }
}

function* doSolutionRemove({ payload }) {
  /*
    Loading que's if any

    example:
    yield put(Actions.loadingSomething())
    */
  /*
        Call the api endpoint

    */
  const response = yield call(loadSolutionDeleteCompanySolution, payload.id);
  /*
              Process the response
          */
  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    // Do this to update the UI to show the user saved the program
    const page = payload.page;
    switch (page) {
      case "all-programs":
        yield put(
          Actions.allProgramsPageRemoveProgram({ program: payload.program })
        );
      case "recommended":
        yield put(
          Actions.recommendedPageRemoveProgram({ program: payload.program })
        );
      case "goals":
        yield put(Actions.goalsPageRemoveProgram({ program: payload.program }));
      case "my-solutions":
        yield put(
          Actions.mySolutionPageRemoveProgram({
            companySolutionID: payload.id,
            isActive: payload.isActive,
          })
        );
      default:
        yield put(
          Actions.showToast({
            type: "warning",
            message: "Program removed",
          })
        );

      mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.REMOVE_PROGRAM_FROM_SAVED}`, mapProgramToTracking(payload.program_obj))
    }
  }
}

function* doSolutionRemoveV2({ payload }) {
  const response = yield call(deleteProgram, payload.id);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(
      Actions.doneSolutionRemoveV2({ solution: payload.id })
    );
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Program removed",
      })
    );
  }
}

function* doSolutionActivate({ payload }) {
  const response = yield call(loadSolutionPatch, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const page = payload.page;
    switch (page) {
      case "all-programs":
        yield put(
          Actions.allProgramsPageActivate({
            companySolution: response.data,
            program: response.data.solution,
          })
        );
      case "recommended":
        yield put(
          Actions.recommendedPageActivate({
            companySolution: response.data,
            program: response.data.solution,
          })
        );
      case "goals":
        yield put(
          Actions.goalsPageActivate({
            companySolution: response.data,
            program: response.data.solution,
          })
        );
      case "my-solutions":
        yield put(
          Actions.mySolutionPageActivate({
            companySolution: response.data,
            program: response.data.id,
          })
        );
      default:
        yield put(
          Actions.showToast({
            type: "success",
            message: "Activated program!",
          })
        );

      mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.ACTIVATE_PROGRAM}`, mapProgramToTracking(response, true))
    }
  }
}

function* doSolutionDeactivate({ payload }) {
  const response = yield call(loadSolutionPatch, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const page = payload.page;
    switch (page) {
      case "all-programs":
        yield put(
          Actions.allProgramsPageDeactivate({ program: response.data.solution })
        );
      case "recommended":
        yield put(
          Actions.recommendedPageDeactivate({ program: response.data.solution })
        );
      case "goals":
        yield put(
          Actions.goalsPageDeactivate({ program: response.data.solution })
        );
      case "my-solutions":
        yield put(
          Actions.mySolutionPageDeactivate({ program: response.data.solution })
        );
      default:
        yield put(
          Actions.showToast({
            type: "warning",
            message: "Deactivated program",
          })
        );
      
      mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.DEACTIVATE_PROGRAM}`, mapProgramToTracking(response, true))
    }
  }
}

function* doSolutionArchive({ payload }) {
  const response = yield call(loadSolutionPatch, payload);

  if (!response) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const page = payload.page;
    switch (page) {
      case "my-solutions":
        yield put(Actions.mySolutionPageArchive(response.data));
        yield put(Actions.updateSolutionArchiveCount(true));
      default:
        yield put(
          Actions.showToast({
            type: "warning",
            message: "Program has been archived",
          })
        );

      mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.ARCHIVE_PROGRAM}`, mapProgramToTracking(response, true))
    }
  }
}

function* doChangeSolutionStatus({ payload }) {
  yield put(Actions.doingChangeSolutionStatus());
  
  const response = yield call(changeProgramStatus, payload);
  /*
              Process the response
          */
  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const page = payload.page;
    yield put(
      Actions.showToast({
        type: response.isArchived ? "warning" : response.isActive ? "success" : "warning",
        message: `Program has been ${response.isArchived ? "archived" : response.isActive ? "activated" : "deactivated"}!`,
      })
    );
    
    yield put(Actions.doneChangeSolutionStatus(response))
  }
}

function* doSolutionActivateV2({ payload }) {
  const response = yield call(changeMySolutionStatus, {
    id: payload.solution,
    is_active: true
  });

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const page = payload.page;

    yield put(
      Actions.mySolutionPageActivate({
        program: response.id,
      })
    );
    yield put(
      Actions.showToast({
        type: "success",
        message: "Activated program!",
      })
    );

    // Since previous APIs had data in `data` field, reusing the same methods required us to put the whole data under the same field name
    mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.ACTIVATE_PROGRAM}`, mapProgramToTracking({data: {...response, solution: payload.solutionObj}}, true))
  }
}

function* doSolutionDeactivateV2({ payload }) {
  const response = yield call(changeMySolutionStatus, {
    id: payload.solution,
    is_active: false,
  });

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const page = payload.page;
    
    yield put(
      Actions.mySolutionPageDeactivate({ program: {...response, id: response.solution} })
    );

    yield put(
      Actions.showToast({
        type: "warning",
        message: "Deactivated program",
      })
    );

    // Since previous APIs had data in `data` field, reusing the same methods required us to put the whole data under the same field name
    mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.DEACTIVATE_PROGRAM}`, mapProgramToTracking({data: {...response, solution: payload.solutionObj}}, true))
  }
}

function* doSolutionArchiveV2({ payload }) {
  const response = yield call(changeMySolutionStatus, {
    id: payload.solution,
    is_archived: true
  });

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    const page = payload.page;
    yield put(Actions.mySolutionPageArchiveV2(response));
    yield put(Actions.updateSolutionArchiveCount(true));
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Program has been archived",
      })
    );

    // Since previous APIs had data in `data` field, reusing the same methods required us to put the whole data under the same field name
    mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.ARCHIVE_PROGRAM}`, mapProgramToTracking({data: {...response, solution: payload.solutionObj}}, true))
  }
}