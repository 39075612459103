import React, { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { UrlStateContext } from '../urlStateContext/UrlStateContext'

import { UrlState, UrlStateContextControllerProps } from './UrlStateContextController.types'

const parseSearchParams = (urlState: string) => ({ ...queryString.parse(urlState) })
const stringifySearchParams = (object: Record<string, any>) => queryString.stringify(object)

export const UrlStateContextController = ({ children }: UrlStateContextControllerProps) => {
    const history = useHistory()

    const { search } = useLocation()

    const searchParams = useMemo(() => new URLSearchParams(search), [search])

    const urlState = parseSearchParams(searchParams.toString())

    const setUrlState = (newState: UrlState) => {
        const newSearchParams = stringifySearchParams({
            ...parseSearchParams(searchParams.toString()),
            ...newState,
        })

        history.push({
            pathname: location.pathname,
            search: newSearchParams,
        })
    }

    const removeFromUrlState = (propertyKey: string) => {
        const newSearchParams = new URLSearchParams(searchParams)

        newSearchParams.delete(propertyKey)

        history.push({
            pathname: location.pathname,
            search: stringifySearchParams(newSearchParams),
        })
    }

    return (
        <UrlStateContext.Provider value={{ urlState, setUrlState, removeFromUrlState }}>
            {children}
        </UrlStateContext.Provider>
    )
}
