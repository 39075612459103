/** @format */

import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  GoalIcon,
  ToyFaces,
  TableCell,
  CheckmarkIcon,
  ChevronRightIcon,
  ReloadIcon
} from "@diversioteam/diversio-ds";
import classNames from "classnames";

import "./index.scss";

const ITEMS_PER_PAGE = 3;

export const SelectInsight = ({
  insights,
  industryBenchmarkAvailable,
  onScrollToTop,
  onNextRegular,
  onNextCustom,
  onReset,
  viewRecommended,
  endOfGoalsPagePrograms,
  mapping,
  mapT,
  m,
  onChangeInsightListClippedHeight
}) => {
  const [page, setPage] = useState(1)
  const insightListRef = useRef()

  const insightsLoaded = insights !== undefined
  const hasInsights = insightsLoaded && insights.length !== 0
  const paginatedInsights = insights?.slice(0, ITEMS_PER_PAGE * page);
  const isLoadMoreVisible = paginatedInsights?.length !== insights?.length
  const hasLoadedMore = page > 1

  useEffect(() => {
    onScrollToTop();
    onReset();
    endOfGoalsPagePrograms();
  }, []);

  useEffect(() => {
    if (hasLoadedMore) {
      const insightListLoadedMoreHeight = 390;
      
      const insightListClippedHeight = insightListRef.current?.scrollHeight - insightListRef.current?.offsetHeight;
      const insightListPosition = insightListRef.current?.scrollTop + insightListLoadedMoreHeight;

      onChangeInsightListClippedHeight(insightListClippedHeight, insightListPosition)
    }
  }, [page])

  const handleClickLoadMore = () => {
    setPage(page + 1)
  }

  const getSelectInsightTitle = () => {
    if (hasInsights) {
      return "Select an insight"
    } else {
      if (industryBenchmarkAvailable) {
        return "Congratulations!"
      } else {
        return "Get Started"
      }
    }
  }

  const getNoInsightsText = () => {
    if (industryBenchmarkAvailable) {
      return <><p>You're an industry leader scoring above the benchmark!</p><p>Set goals to maintain progress</p></>
    } else {
      return <p>Stay accountable when you create goals</p>
    }
  }

  const renderInsightsDisplay = () => (
    <div className="select-insight__list-wrapper">
      <div ref={insightListRef} className={classNames(["select-insight__list", { "select-insight__list--loaded-more": hasLoadedMore}])}>
        {paginatedInsights?.map(({ title, key, target_group, goal_created }, index) => {
          const translatedTargetGroup = mapT(m.TARGET_GROUP, target_group);
          const cleanedTitle = title.replace(
            target_group,
            translatedTargetGroup
          );

          return (
            <TableCell
              key={index}
              className="select-insight__list-item"
              onClick={
                goal_created
                  ? () =>
                      viewRecommended({
                        kpi: key,
                        targetGroup: target_group,
                        insightArrPosition: index,
                      })
                  : () => onNextRegular(key, target_group, cleanedTitle)
              }
            >
              <p className="select-insight__list-item-title">
                {cleanedTitle}
              </p>

              {goal_created ? (
                <div className="select-insight__list-item-status">
                  <CheckmarkIcon
                    type="line"
                    color="#43E09E"
                    height={31.5}
                    width={31.5}
                  />

                  <p className="select-insight__list-item-status-text">
                    Goal Created
                  </p>
                </div>
              ) : (
                <Button
                  type="button"
                  size="small"
                  color='transparent'
                  rightIcon={<ChevronRightIcon type="bold" />}
                >
                  Create Goal
                </Button>
              )}
            </TableCell>
          );
        })}
      </div>

        {
          isLoadMoreVisible && 
            <TableCell className="select-insight__load-more">
              <Button
                className="select-insight__load-more-button"
                rightIcon={<ReloadIcon type="line" />}
                type="button"
                color="transparent"
                onClick={handleClickLoadMore}
              >
                Load More
              </Button>
            </TableCell>
        }
      </div>
  )

  const renderNoInsightsDisplay = () => (
    <div className="select-insight__no-insights">
      <div className="select-insight__no-insights-text">
        {getNoInsightsText()}
      </div>
      <div onClick={onNextCustom}>
        <Button
          type="button"
          size="large"
          color="primary"
          rightIcon={<GoalIcon type="line" />}
        >
          Create Goal
        </Button>
      </div>
    </div>
  )


  return insightsLoaded && (
    <div className="select-insight">
      <div className="select-insight__steps">
      <h3 className="select-insight__steps-title">{getSelectInsightTitle()}</h3>
        {hasInsights &&
          <p className="select-insight__steps-description">
            Create a goal based on one of these insights
          </p>
        }
      </div>

      {hasInsights ? renderInsightsDisplay() : renderNoInsightsDisplay()}

      <div className="select-insight__avatars">
        <ToyFaces />
      </div>

        {hasInsights &&
          <div className="select-insight__custom" onClick={onNextCustom}>
            <Button
              type="button"
              size="small"
              color="transparent"
              rightIcon={<GoalIcon type="line" />}
            >
              Custom Goal
            </Button>

            <p className="select-insight__custom-description">
              Create a KPI goal for a target group
            </p>
          </div> 
        }
      </div>
  )
};
