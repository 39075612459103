import React from 'react'
import { CompareBadge, ToolCard } from '@diversioteam/diversio-ds'
import { toTitleCase } from 'utils/programFeaturesFunctions'
import { mapT } from 'translations/utils'
import * as m from 'translations/mapping'

import { trimExtension } from 'utils/trimExtension/trimExtension'
import { getCleanedKpiCodes } from 'containers/ImprovePages/utils/getCleanedKpiCodes'

import { ToolsLayoutProps } from './toolsLayout.types'
import * as S from './toolsLayout.styles'

export const ToolsLayout = ({
    title,
    description,
    painPoints,
    targetGroupsV2,
    url,
    mediaType,
    created,
    isNew,
    isSaved,
    hasAverageImpact,
    averageImpactData,
    size = 'medium',
    onProvideFeedback,
    onRemove,
    onSave,
}: ToolsLayoutProps) => {
    const formattedTargetGroups = targetGroupsV2?.map(({ name }) => mapT(m.GROUP_BREAKDOWN, name))

    const newLabel = isNew ? ({ variant: 'new' } as const) : undefined

    const customFooterContent = (
        <S.Footer>
            {Object.entries(averageImpactData).map(([_key, value]) =>
                value > 0 ? (
                    <>
                        <CompareBadge size="small" color="green" type="increase" value={value} />

                        <S.Label>Avg Impact</S.Label>
                    </>
                ) : null,
            )}
        </S.Footer>
    )

    if (size === 'medium') {
        return (
            <S.Tool>
                <ToolCard
                    category={toTitleCase(mediaType)}
                    kpis={getCleanedKpiCodes(painPoints)}
                    publicationYear={created}
                    description={description}
                    targetGroups={formattedTargetGroups}
                    title={trimExtension(title)}
                    onSave={onSave}
                    isSaved={isSaved}
                    newLabel={newLabel}
                    href={url}
                    onRemove={onRemove}
                    size="medium"
                    customFooterContent={hasAverageImpact ? customFooterContent : undefined}
                />
            </S.Tool>
        )
    }

    if (size === 'small') {
        return (
            <S.Tool>
                <ToolCard
                    category={toTitleCase(mediaType)}
                    kpis={getCleanedKpiCodes(painPoints)}
                    publicationYear={created}
                    description={description}
                    title={trimExtension(title)}
                    isSaved={isSaved}
                    newLabel={newLabel}
                    href={url}
                    onRemove={onRemove}
                    size="small"
                    onProvideFeedback={onProvideFeedback}
                />
            </S.Tool>
        )
    }

    return null
}
