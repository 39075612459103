/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { stringify } from "query-string";
import mixpanel from "mixpanel-browser";
import classNames from "classnames";

import {
  Chip,
  Toast,
  SingleSelect,
  SearchBox,
  PeopleIcon,
  ProgramCell,
  PainPointIcon,
  TablePagination,
} from "@diversioteam/diversio-ds";

import { ProgramCellListSkeleton } from "../../../../components/skeletons/programCellListSkeleton";
import { Actions } from "../../../../actions";
import {  mapIcons } from "../../../../utils/kpiUtils";
import { getActiveCompanyID } from "../../../../utils";
import { mapT } from "../../../../translations/utils";
import { getPageName, mapProgramToTracking } from "../../../../utils/tracking";
import { translateTargetGroups } from "../../../../utils/programFeaturesFunctions";
import * as m from "../../../../translations/mapping";

import "./index.scss";
import TAXONOMIES from "../../../../utils/taxonomies";
import { withHooks } from "config/withHooks/withHooks";
import { getCleanedKpiCodes } from "../../utils/getCleanedKpiCodes";

const PAGE_NAME = "all-programs";

/**
 * TODO
 * - Add dynamic sorting functionality
 */
class AllPrograms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      sort: "id",
      orderBy: "-",
      search: "",
      selectedKpiID: null,
      selectedTargetGroupID: null,
      selectedKPIValue: null,
      selectedTargetGroupValue: null,
      timer: null,
    };
    this.props.resetGlobalToast();
    const query = stringify({
      page: 1,
      page_size: 10,
      title: "",
    });
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getSolutionAllPrograms({ id: activeID, query: query });
    this.props.getSolutionAllProgramsFilters({ id: activeID });

    const initialData = {
      data: { year: "", level: "", office: "", department: "" },
      id: activeID,
    };
    this.props.getSolutionGoalsInsightData(initialData);
  }

  componentDidMount() {
    mixpanel.track(TAXONOMIES.VIEW_PAGE, {
      name: getPageName(PAGE_NAME),
    });
  }

  handlePageChanged = (event, newPage) => {
    /**
     * Handle a user changing to a new page to view
     */
    const { pageSize, search, selectedKpiID, selectedTargetGroupID } = this.state;

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHANGE_PAGE}`, {
      newPage: newPage + 1,
    });

    this.setState({
      page: newPage + 1,
    });
    let obj = {
      page: newPage + 1,
      page_size: pageSize,
      title: search,
    };
    if (selectedKpiID) obj.kpis = [selectedKpiID];
    if (selectedTargetGroupID) obj.target_groups = [selectedTargetGroupID];
    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getSolutionAllPrograms({ id: activeID, query: query });
    this.scrollToTop();
  };

  handleChangeRowsPerPage = (event) => {
    /**
     * Handle a user changing the rows per page to show in the table
     */
    const { search, selectedKpiID, selectedTargetGroupID } = this.state;
    const newRowsPerPage = parseInt(event.target.value, 10);

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHANGE_ROWS_PER_PAGE}`, {
      newRowsPerPage,
    });

    this.setState(() => ({
      pageSize: newRowsPerPage,
      page: 1,
    }));
    let obj = {
      page: 1,
      page_size: newRowsPerPage,
      title: search,
    };
    if (selectedKpiID) obj.kpis = [selectedKpiID];
    if (selectedTargetGroupID) obj.target_groups = [selectedTargetGroupID];
    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getSolutionAllPrograms({ id: activeID, query: query });
    this.scrollToTop();
  };

  handleSearchChange = (value) => {
    /**
     * Handle a user typing into the search box. We use settimeout
     * to have a small delay before making the api request to allow
     * the user to finish typing
     */
    const { pageSize, selectedKpiID, selectedTargetGroupID, timer } = this.state;
    const newSearch = value;
    this.setState({
      search: newSearch,
      page: 1,
    });
    let obj = {
      page: 1,
      page_size: pageSize,
      title: newSearch,
    };

    if (selectedKpiID) obj.kpis = [selectedKpiID];
    if (selectedTargetGroupID) obj.target_groups = [selectedTargetGroupID];
    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if (newSearch) {
        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.SEARCH_TERM}`, {
          searchTerm: newSearch,
        });
      }

      this.props.getSolutionAllPrograms({ id: activeID, query: query });
      this.scrollToTop();
    }, 800);
    this.setState({
      timer: newTimer,
    });
  };

  scrollToTop = () => {
    /**
     * Helper function to scroll to the top of the div provided
     */
    let div = document.getElementsByClassName("table-body-wrapper")[0];
    div.scrollTop = 0;
  };

  handleKpiChange = (id) => {
    /**
     * Handle changing KPI selection for filtering
     */
    const { pageSize, search, selectedTargetGroupID } = this.state;
    const { kpis } = this.props?.solutionAllPrograms.programsFilters;
    const kpiName = kpis.filter(({ value }) => value === id)[0].label;

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHOOSES_KPI_FILTER}`, {
      kpiName,
    });

    let kpiObj = null;
    for (const i in kpis) {
      if (kpis[i].value === id) {
        kpiObj = kpis[i];
      }
    }

    this.setState({
      selectedKpiID: id,
      selectedKPIValue: kpiObj,
      page: 1,
    });

    let obj = {
      page: 1,
      page_size: pageSize,
      title: search,
      kpis: [id],
    };
    if (selectedTargetGroupID) obj.target_groups = [selectedTargetGroupID];

    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getSolutionAllPrograms({ id: activeID, query: query });
    this.scrollToTop();
  };

  handleTargetGroupChange = (id) => {
    /**
     * Handle changing Pain Point selection for filtering
     */
    const { pageSize, search, selectedKpiID } = this.state;
    const { target_groups } = this.props?.solutionAllPrograms.programsFilters;
    const targetGroupName = target_groups.filter(({ value }) => value === id)[0].label;

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHOOSES_TARGET_GROUP_FILTER}`, {
      targetGroupName,
    });

    let targetGroupObj = null;
    for (const i in target_groups) {
      if (target_groups[i].value === id) {
        targetGroupObj = target_groups[i];
        break;
      }
    }
    this.setState({
      selectedTargetGroupID: id,
      selectedTargetGroupValue: targetGroupObj,
      page: 1,
    });
    let obj = {
      page: 1,
      page_size: pageSize,
      title: search,
      target_groups: [id],
    };
    if (selectedKpiID) obj.kpis = [selectedKpiID];
    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getSolutionAllPrograms({ id: activeID, query: query });
    this.scrollToTop();
  };

  saveProgram = (row) => {
    const { insightData } = this.props.solutionGoals;
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    const program = {
      company: parseInt(activeID),
      solution: row.id,
      survey_id: insightData.survey_id,
      program_obj: row,
    };

    this.props.doSolutionMyAddProgram({
      program: program,
      page: PAGE_NAME,
    });
  };

  removeCompanySolution = (row) => {
    this.props.doSolutionRemove({
      id: row.company_solution.id,
      program: row.id,
      program_obj: row,
      page: PAGE_NAME,
    });
  };

  activateProgram = (row) => {
    const { insightData } = this.props.solutionGoals;
    this.props.doSolutionActivate({
      solution: row?.company_solution?.id,
      id: insightData?.survey_id,
      fields: { is_active: true },
      page: PAGE_NAME,
    });
  };

  deactivateProgram = (row) => {
    const { insightData } = this.props.solutionGoals;
    this.props.doSolutionDeactivate({
      solution: row?.company_solution?.id,
      id: insightData?.survey_id,
      fields: { is_active: false },
      page: PAGE_NAME,
    });
  };

  removeKpiFilter = () => {
    const { selectedTargetGroupID, search, pageSize } = this.state;

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.REMOVE_KPI_FILTER}`);

    this.setState({
      selectedKPIValue: null,
      selectedKpiID: null,
    });
    const obj = {
      page: 1,
      page_size: pageSize,
      title: search,
    };
    if (selectedTargetGroupID) obj.target_groups = [selectedTargetGroupID];
    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getSolutionAllPrograms({ id: activeID, query: query });
  };

  removeTargetGroupFilter = () => {
    const { selectedKpiID, search, pageSize } = this.state;

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.REMOVE_TARGET_GROUP_FILTER}`);

    this.setState({
      selectedTargetGroupValue: null,
      selectedTargetGroupID: null,
    });
    const obj = {
      page: 1,
      page_size: pageSize,
      title: search,
    };
    if (selectedKpiID) obj.kpis = [selectedKpiID];
    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getSolutionAllPrograms({ id: activeID, query: query });
  };

  handleExpandSearchBox = () => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.SEARCH_CLICKED}`);
  };

  handleOpenProgramDropDown = (program) => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.PROGRAM_DETAILS_VIEWED}`, mapProgramToTracking(program));
  };

  handleClickProgramTool = (toolTitle) => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.TOOL_CLICKED}`, {
      toolName: toolTitle,
    });
  };

  // TODO: add in the sorting drodown filter
  // handleSort = () => {};

  render() {
    const { programs, count, programsFilters, isLoading } = this.props?.solutionAllPrograms;

    const { page, pageSize, search, selectedKPIValue, selectedTargetGroupValue } = this.state;
    const { kpis = [], target_groups } = programsFilters;
    const toastError = this.props.message.toastError;
    const targetGroups = target_groups ? translateTargetGroups(target_groups) : null;

    const hasPrograms = programs.length > 0;

    const filterApplied = selectedKPIValue || selectedTargetGroupValue ? true : false;

    return (
      <div className="all-programs-container">
        {toastError?.type && (
          <div className="ds-toast-component">
            <Toast
              open={true}
              type={toastError.type}
              message={toastError.message}
              onClick={() => {
                this.props.resetGlobalToast();
              }}
            />
          </div>
        )}
        <div className="filter-header-container">
          <span className="search-box-wrapper">
            <SearchBox
              maxWidth={198}
              value={search}
              onChange={(value) => this.handleSearchChange(value)}
              onExpand={() => this.handleExpandSearchBox()}
            />
          </span>

          <SingleSelect
            color="light"
            variant="block"
            listboxId="kpi"
            placeholder="KPI"
            isPlaceholderPermanent
            icon={<PainPointIcon type="line" />}
            options={kpis}
            value={selectedKPIValue ? selectedKPIValue.value : null}
            onChange={(id) => {
              this.handleKpiChange(id);
            }}
          />

          <SingleSelect
            color="light"
            variant="block"
            listboxId="targetGroup"
            placeholder="Target Group"
            isPlaceholderPermanent
            icon={<PeopleIcon type="line" />}
            options={targetGroups ? targetGroups : []}
            value={selectedTargetGroupValue ? selectedTargetGroupValue.value : null}
            onChange={(id) => {
              this.handleTargetGroupChange(id);
            }}
          />
        </div>

        {filterApplied ? (
          <div className="all-programs-chip-container">
            <div className="chip-container-wrapper">
              {selectedKPIValue ? (
                <div className="chip-wrapper">
                  <Chip
                    variant="primary"
                    label={selectedKPIValue.label}
                    onDelete={() => this.removeKpiFilter()}
                  />
                </div>
              ) : null}

              {selectedTargetGroupValue ? (
                <div className="chip-wrapper">
                  <Chip
                    variant="primary"
                    label={mapT(m.GROUP_BREAKDOWN, selectedTargetGroupValue.label)}
                    onDelete={() => this.removeTargetGroupFilter()}
                  />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <div className="table-body-container">
          <div className={classNames("table-body-wrapper", { "no-data": !hasPrograms })}>
            {isLoading.programs ? (
              <ProgramCellListSkeleton
                numberOfElements={this.state.pageSize}
                listElementWrapperClassName="solution-cell-wrapper"
              />
            ) : (
              programs.map((row, index) => {
                let tools = [];
                let labels = [];

                for (const i in row.tools) {
                  let withBadge = true;

                  if (row.tools[i].type === "resource") {
                    withBadge = false;
                  }

                  const cleanTool = {
                    caption: row.tools[i].title,
                    href: row.tools[i].url,
                    icon: mapIcons(row.tools[i]),
                    title: row.tools[i].media_type,
                    withBadge: withBadge,
                  };

                  tools.push(cleanTool);
                }

                for (const i in row.target_groups) {
                  const obj = {
                    icon: <PeopleIcon type="line" />,
                    label: mapT(m.GROUP_BREAKDOWN, row.target_groups[i].name),
                  };

                  labels.push(obj);
                }

                return (
                  <div className="solution-cell-wrapper">
                    <ProgramCell
                      key={row.id}
                      kpis={getCleanedKpiCodes(row.pain_points)}
                      programName={row.title}
                      labels={row.recommendation_label.map(({ text }) => text)}
                      description={row.description}
                      isSaved={row.is_saved}
                      isAdded={row?.company_solution?.is_active}
                      onSave={() => this.saveProgram(row)}
                      onRemoveFromSaved={() => this.removeCompanySolution(row)}
                      onAddToActive={() => this.activateProgram(row)}
                      onRemoveFromActive={() => this.deactivateProgram(row)}
                      tools={tools}
                      programLabels={labels}
                      onOpenDropdown={() => this.handleOpenProgramDropDown(row)}
                      onClickTool={(toolTitle) => this.handleClickProgramTool(toolTitle)}
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>

        {hasPrograms && (
          <div className="pagination-footer-container">
            <TablePagination
              count={count}
              page={page - 1}
              rowsPerPage={pageSize}
              onPageChange={(event, newPage) => {
                this.handlePageChanged(event, newPage);
              }}
              onRowsPerPageChange={(event) => this.handleChangeRowsPerPage(event)}
              style={{ width: "100%", backgroundColor: "#FFF" }}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, Actions)(withHooks(AllPrograms));
