import React, { useEffect, useState } from 'react'
import { SelectedDataItem } from '@diversioteam/diversio-ds/dist/components/core/CascaderFilters/cascaderFilters.types'
import { useParams } from 'react-router-dom'
import queryString from 'query-string'
import { parseYearQuarter } from 'utils'
import _ from 'underscore'
import omit from 'lodash/omit'
import { createFiltersFromPayload } from 'components/Analyze/Filters/utils'

import { FILTER_OPTIONS } from 'components/AnalyzeV2/Inclusion/Heatmap/enums'
import { SelectedFilters } from 'components/AnalyzeV2/Diversity'
import { InclusionParams } from 'components/AnalyzeV2/Inclusion/inclusion.types'
import { Demographic } from 'api/actions/analyze/companyFilters/companyFiltersActions.types'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'
import { useSurveys } from 'hooks/useSurveys/useSurveys'

import { FiltersContext } from './../filtersContext/FiltersContext'
import { FiltersContextControllerProps } from './FiltersContextController.types'

export const FiltersContextController = ({ children }: FiltersContextControllerProps) => {
    const { overTime } = useParams<InclusionParams>()
    const { filters_in_profiles, ...params } = queryString.parse(location.search)
    const filtersParams = omit(params, ['name', 'year', 'quarter']) as SelectedFilters

    const [filtersOpen, setFiltersOpen] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<SelectedDataItem[]>([])
    const [resultsOverTime, setResultsOverTime] = useState(overTime === FILTER_OPTIONS.OVER_TIME)

    const [selectedSurveysFilter, setSelectedSurveysFilter] = useState('')
    const [selectedFiltersPayload, setSelectedFiltersPayload] = useState<SelectedFilters>(filtersParams)
    const [profilesActive, setProfilesActive] = useState(false)
    const [defaultSelectedProfileId, setDefaultSelectedProfileId] = useState<string | undefined>(undefined)
    const [demographic, setDemographic] = useState<Demographic | undefined>()

    const { survey } = useSurveys()

    const hasFiltersInProfiles = filters_in_profiles !== undefined
    const defaultFiltersInProfiles = hasFiltersInProfiles ? filters_in_profiles === 'true' : true
    const [isFiltersInProfilesActive, setIsFiltersInProfilesActive] = useState(defaultFiltersInProfiles)

    const handleToggleFiltersInProfilesActive = () => {
        setIsFiltersInProfilesActive((prevState) => !prevState)
    }

    const apiParams = {
        year: parseYearQuarter(survey),
    }

    const queryGetCompanyFilters = useGetCompanyFilters(apiParams)

    useEffect(() => {
        if (queryGetCompanyFilters.isFetched) {
            _setSelectedFilters(
                createFiltersFromPayload(
                    filtersParams,
                    queryGetCompanyFilters.data?.filtersModal,
                ) as SelectedDataItem[],
            )

            setSelectedFiltersPayload(filtersParams)
        }
    }, [queryGetCompanyFilters.isFetched])

    const _setSelectedFilters = (filters: SelectedDataItem[]) => {
        if (queryGetCompanyFilters.isLoading) {
            return
        }

        const ogFilters = queryGetCompanyFilters.data?.filtersModal
        const withDefaultValues = ogFilters?.map((filter) => {
            const availableOptions =
                _.findWhere(filters, { name: filter.name }) || _.findWhere(filters, { name: filter.key })
            return {
                name: filter.name,
                key: filter.key,
                options: availableOptions?.options || [],
            }
        })
        if (withDefaultValues) {
            setSelectedFilters(withDefaultValues)
        }
    }

    return (
        <FiltersContext.Provider
            value={{
                filtersOpen,
                setFiltersOpen,
                showFilters,
                setShowFilters,
                selectedFilters,
                setSelectedFilters: _setSelectedFilters,
                resultsOverTime,
                setResultsOverTime,
                selectedSurveysFilter,
                setSelectedSurveysFilter,
                selectedFiltersPayload,
                setSelectedFiltersPayload,
                profilesActive,
                setProfilesActive,
                defaultSelectedProfileId,
                setDefaultSelectedProfileId,
                demographic,
                setDemographic,
                isFiltersInProfilesActive,
                handleToggleFiltersInProfilesActive,
            }}
        >
            {children}
        </FiltersContext.Provider>
    )
}
