export const createRenewalEmail = ({
    username,
    company,
    daysLeft,
    emailBodyFullName,
}: {
    username: string
    company: string
    daysLeft: number
    emailBodyFullName: string
}) => {
    const subject = `${username} at ${company} is requesting to RENEW their dashboard subscription`
    const body = `Hi Sales Team,
    
I am ${username} from ${company}. I have ${daysLeft} ${
        daysLeft === 1 ? 'day' : 'days'
    } left in my subscription. Can you setup a meeting with my admin (account holder) to renew our company subscription?
    
Thank you,
    
${emailBodyFullName}`

    return { subject, body }
}

export const createCancelRenewalEmail = ({
    username,
    company,
    emailBodyFullName,
    selectedCancelReasonsOptions,
}: {
    username: string
    company: string
    emailBodyFullName: string
    selectedCancelReasonsOptions: string[]
}) => {
    const subject = `${username} at ${company} is requesting to CANCEL their dashboard subscription`
    const body = `Hi Sales Team,
    
I am ${username} from ${company}. I would like to CANCEL my subscription because: 
${
    selectedCancelReasonsOptions.length > 0
        ? selectedCancelReasonsOptions.map((option) => `\n• ${option}`).join('')
        : "\n• There wasn't selected any reason"
}
    
Can you get in contact with the account holder to confirm this cancellation?
    
Thank you,
      
${emailBodyFullName}`

    return { subject, body }
}
