import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ActionTypes } from 'actions'

import { useVersion } from 'hooks/useVersion'
import { useTimeout } from 'hooks/useTimeout/useTimeout'

import MySolutionsArchiveV2 from './MySolutionsArchiveV2'
import MySolutionsArchive from './MySolutionsArchive'

const LOADING_TIMEOUT = 100

export const SolutionsArchiveContainer = (props: RouteComponentProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const versionContext = useVersion()
    const dispatch = useDispatch()

    const { reset: resetTimeout } = useTimeout(() => {
        setIsLoading(false)
    }, LOADING_TIMEOUT)

    useEffect(() => {
        dispatch({ type: ActionTypes.RESET_PROGRAMS_AND_SOLUTIONS })

        setIsLoading(true)

        resetTimeout()
    }, [versionContext?.version])

    if (versionContext?.version === versionContext?.AVAILABLE_VERSIONS.v2 && !isLoading) {
        return <MySolutionsArchiveV2 {...props} />
    } else if (!isLoading) {
        return <MySolutionsArchive {...props} />
    }

    return null
}
