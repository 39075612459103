import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Survey } from 'api/actions/analyze/shared.types'
import { useUrlState } from 'hooks/useUrlState/useUrlState'
import { AppRoute } from 'routing/AppRoute.enum'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { parseSurveyParam, validateSurveyParam } from 'utils/surveyQueryParam/surveyQueryParam'
import { useGetCompanyFilters } from 'hooks/companyFilters/useGetCompanyFilters'

import { SurveysContext } from './../surveysContext/SurveysContext'
import { SurveysContextControllerProps } from './SurveysContextController.types'

export const YEAR_PARAM_KEY = 'year'
export const QUARTER_PARAM_KEY = 'quarter'

const PATHS_WITH_SURVEY_PARAM = [AppRoute.Home, AppRoute.Analyze, AppRoute.AnalyzeDiversity, AppRoute.AnalyzeInclusion]

export const SurveysContextController = ({ children }: SurveysContextControllerProps) => {
    const { urlState, setUrlState, removeFromUrlState } = useUrlState()

    const location = useLocation()

    const yearParam = urlState[YEAR_PARAM_KEY]
    const quarterParam = urlState[QUARTER_PARAM_KEY]

    const hasSurveyParams = !!yearParam && !!quarterParam

    const [survey, setSurvey] = useState<Survey | null>(null)

    const companyFiltersParams = useMemo(
        () =>
            survey
                ? {
                      year: parseSurveyParam(survey),
                  }
                : {},
        [survey],
    )

    const isAllowedPath = PATHS_WITH_SURVEY_PARAM.some((path) => location.pathname.includes(generateCompanyURI(path)))

    const queryGetCompanyFilters = useGetCompanyFilters(companyFiltersParams, isAllowedPath)

    const validatedSurveyParams = validateSurveyParam(yearParam, quarterParam)

    useEffect(() => {
        if (hasSurveyParams && validatedSurveyParams === null) {
            removeFromUrlState(YEAR_PARAM_KEY)
            removeFromUrlState(QUARTER_PARAM_KEY)
        }
    }, [hasSurveyParams, removeFromUrlState, validatedSurveyParams])

    useEffect(() => {
        if (!survey) {
            return
        }

        if (isAllowedPath && !hasSurveyParams) {
            setUrlState({
                [YEAR_PARAM_KEY]: survey.year,
                [QUARTER_PARAM_KEY]: survey.quarter,
            })
        }
    }, [hasSurveyParams, survey, isAllowedPath])

    useEffect(() => {
        if (queryGetCompanyFilters.isLoading || !queryGetCompanyFilters.data) {
            return
        }

        if (queryGetCompanyFilters.data.surveys.length === 0) {
            return setSurvey(null)
        }

        if (survey === null) {
            if (hasSurveyParams) {
                const isSurveyParamValid = queryGetCompanyFilters.data.surveys.some(
                    ({ year, quarter }) => year.toString() === yearParam && quarter.toString() === quarterParam,
                )

                if (isSurveyParamValid) {
                    return setSurvey(validateSurveyParam(yearParam, quarterParam))
                }
            }

            setSurvey(queryGetCompanyFilters.data.surveys[0])
        }
    }, [
        hasSurveyParams,
        quarterParam,
        queryGetCompanyFilters.data,
        queryGetCompanyFilters.isLoading,
        survey,
        yearParam,
    ])

    useEffect(() => {
        if (isAllowedPath && survey) {
            setUrlState({
                [YEAR_PARAM_KEY]: survey.year,
                [QUARTER_PARAM_KEY]: survey.quarter,
            })
        }
    }, [survey, isAllowedPath])

    const handleChangeSurvey = (survey: Survey) => {
        setSurvey(survey)
    }

    const checkIfSurveyIsSelected = (surveyToBeChecked: Pick<Survey, 'year' | 'quarter'>) => {
        if (!survey) {
            return false
        }

        const isYearEqual = surveyToBeChecked.year === survey.year
        const isQuarterEqual = surveyToBeChecked.quarter === survey.quarter

        return isYearEqual && isQuarterEqual
    }

    return (
        <SurveysContext.Provider value={{ survey, onChangeSurvey: handleChangeSurvey, checkIfSurveyIsSelected }}>
            {children}
        </SurveysContext.Provider>
    )
}
