import React from 'react'
import { InclusionScoresOverTime, SecondaryHorizontalIcon, SingleSelect } from '@diversioteam/diversio-ds'
import { ErrorBoundary } from '@sentry/react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useExportInclusionDataOverTime } from 'hooks/inclusion/export/useExportInclusionDataOverTime'
import { InclusionDataProps, useInclusionScoresOverTime } from 'hooks/useInclusionScoresOverTime'
import { useFilters } from 'hooks/useFilters'
import { AnalyzeView } from 'types/analyze.enum'

import { DataRepresentationOverTimeProps } from './dataRepresentationOverTime.types'

import './index.scss'

const DOWNLOAD_CSV = 'Download CSV'

const SELECT_OPTIONS = [
    {
        label: DOWNLOAD_CSV,
        value: DOWNLOAD_CSV,
    },
]

const DataRepresentationOverTimeWithoutEB = ({ selectedTab }: DataRepresentationOverTimeProps) => {
    const { selectedFilters, selectedSurveysFilter } = useFilters({ view: AnalyzeView.Diversity, selectedTab })

    const inclusionDataParams: InclusionDataProps = {
        ...selectedFilters,
        years: selectedSurveysFilter,
        key: selectedTab,
    }

    const { data, isLoading } = useInclusionScoresOverTime({
        ...inclusionDataParams,
    })

    const mutationExportInclusionDataOverTime = useExportInclusionDataOverTime({
        ...inclusionDataParams,
        export_format: 'csv',
    })

    const handleDropdownOptionSelect = (value: string) => {
        switch (value) {
            case DOWNLOAD_CSV:
                return mutationExportInclusionDataOverTime.mutate()
        }
    }

    if (isLoading || !data) {
        return null
    }

    return (
        <div className="diversity-data-representation-over-time">
            <div className="title-container">
                <h2>Inclusion Scores Over Time</h2>

                <div className="title-container__actions">
                    <ErrorBoundary>
                        <SingleSelect
                            color="light"
                            variant="block"
                            listboxId="kpi"
                            isPlaceholderPermanent
                            placeholder=""
                            icon={<SecondaryHorizontalIcon type="line" />}
                            options={SELECT_OPTIONS}
                            value={null}
                            onChange={handleDropdownOptionSelect}
                            hideRightIcon
                        />
                    </ErrorBoundary>
                </div>
            </div>

            <ErrorBoundary>
                <InclusionScoresOverTime data={data} />
            </ErrorBoundary>
        </div>
    )
}

export const DataRepresentationOverTime = withErrorBoundary(DataRepresentationOverTimeWithoutEB, {})
