/*global location*/
/*eslint no-mixed-operators: "off"*/
import React from 'react';
import queryString from 'query-string';
import moment from 'moment';
import _ from 'underscore';
import _l from 'lodash';
import { jwtDecode } from "jwt-decode";
import { isBenchmarkingCompany, isHondaHTC, isMcrockDueDiligence } from "../utils/companyChecks";
import * as m from '../translations/mapping';
import { mapT } from '../translations/utils';
import * as Sentry from '@sentry/react';

// Generic utility functions
import { assign, clone } from 'lodash';
import i18n from "../i18n";
import { getCompanyUUID } from './getCompanyUUID/getCompanyUUID';

// Is a value string or null?
export function isStringOrNull (value) {
    return !value || typeof value === 'string';
}

// If the value has an id property return its id, else return the value
export function getId (value) {
    return value && value.id || value;
}

// Sort objects by id property of
export function sortById (a = {}, b = {}) {
    return a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
}

// Sort objects by name property
export function sortByName (a = {}, b = {}) {
    a = a.name && a.name.toUpperCase();
    b = b.name && b.name.toUpperCase();

    return (a > b) ? 1 : (a < b) ? -1 : 0;
}

// Return a new clone of the object with args assigned to it
export function compose (obj, ...args) {
    return assign(clone(obj), ...args);
}

// Convert a space seperated string to an upper snake cased string
// e.g. "cat and dog" -> "CAT_AND_DOG"
export function upperSnakeCase (string) {
    return string.split(' ').join('_').toUpperCase();
}

// Convert an underscore or space sepertated sting to a camel-cased string
// e.g. "eat RHUBARB_pie" -> "eatRhubarbPie"
export function camelCase (string) {
    let words = string.toLowerCase().split(/_| /);
    return words.reduce((word, next) =>
        word + next[0].toUpperCase() + next.slice(1));
}

// Log message with a timestamp.
export const log = logFactory();

// Log error with a timestamp.
export const logError = logFactory('error');

// Check for a number and parse to floating decimal point
export const parseNumber = function (number, fixed = 0) {
    number = Number(number);
    return Number.isNaN(number) ? '' : number.toFixed(fixed);
};

// Check whether number is decimal
export function isDecimal(n) {
    if (n.hasOwnProperty("toFixed")) {
        return (n - Math.floor(n)) !== 0;
    }
    return false
}

// Create new loggers with the specified severity
export function logFactory (severity = 'log') {
    return (...messages) => {
        let timestamp = new Date().toISOString();
        console[severity](timestamp, ...messages);
    };
}

export function replacePatternToComponent (text, pattern, Component) {
    const splitText = text.split(pattern);
    const matches = text.match(pattern);

    if (splitText.length <= 1) {
        return text;
    }

    return splitText.reduce((arr, element) => {
            if (!element) return arr;

            if(matches.includes(element)) {
                return [...arr, Component];
            }

            return [...arr, element];
        },
        []
    );
};

/**
 * getQueryParams
 * Returns query params object
 * e.g. "?q=123&b=321" -> {q: 123, b:321}
 *
 **/
export function getQueryParams() {
    let qParams = {
        ...queryString.parse(window.location.search),
        ...queryString.parse(window.location.hash)
    };

    return qParams;
}

/**
 * getQueryParam
 * Returns specified query param value
 * e.g. getQueryParams(q) => 123
 *
 **/

export function getQueryParam(param) {
    return getQueryParams()[param];
}

/**
 * stringifyQueryData
 * Returns stringified data for a specific query param
 * e.g. stringifyQueryParams([a, b]) => "a,b"
 *
 **/

function _stringifyQueryData (data) {
    let retData = '';

    if (_.isString(data) || _.isNumber(data) || _.isBoolean(data)) {
        retData = data;
    } else if (_.isArray(data)) {
        retData = data.join(",");
    } else if (_.isObject(data)) {
        retData = Object.keys(data).reduce((filtered, d) => {
            if (data[d]) {
                filtered.push(d);
            }
            return filtered;
        }, []).join(",");
    }

    return encodeURIComponent(retData);
}


// WARNING this is not an efficient solution, should not be used for large strings
export function replaceMaleAndFemale(str){
    return replaceAll(replaceAll(str,'Female','Women'),'Male','Men');
}

// WARNING this is not an efficient solution, should not be used for large strings
export function replaceAll(str, find, replace){
    return str.split(find).join(replace)
}

// TODO @jamie This is awful and proper re-write of Filters needs to be done to get rid of this.
export function mapChoiceLabel(str){
    const genderMap = {
        'Male':'Men',
        'male':'men',
        'Female':'Women',
        'female':'women',
        'POC': "Racial & Ethnic Minorities"
    };
    return genderMap[str]?genderMap[str]:str
}

export function renameEthnicity(str){
    const map = {
        'Ethnicity': 'Race and Ethnicity',
        'ethnicity': 'race and ethnicity'
    };
    return map[str]?map[str]:str
}

// this is used in other Cards - will refactor as
// we get to each component, replace with getIndustryCompType
export function industryOrRegional(str, companyName, replaceWith='regional'){
    if(companyName && companyName.startsWith('OLX-')){
        const lowercase = replaceWith.toLowerCase();
        const upperCase = lowercase[0].toUpperCase() + lowercase.slice(1);
        return replaceAll(replaceAll(str,'industry',lowercase), 'Industry', upperCase);
    }
    else if (companyName && isMcrockDueDiligence(companyName)) {
        return 'Portfolio';
    }
    return str;
}

export function getIndustryCompType(companyName) {
    if (!companyName) {
        return "HOME.COMPARISON_TYPE.INDUSTRY";
    }

    switch(true) {
        case (companyName.startsWith('OLX-')):
            return "HOME.COMPARISON_TYPE.REGIONAL";
        case (isMcrockDueDiligence(companyName)):
            return "HOME.COMPARISON_TYPE.PORTFOLIO";
        case (isHondaHTC(companyName)):
            return "HOME.COMPARISON_TYPE.HONDA";
        case (isBenchmarkingCompany(companyName)):
            return "HOME.COMPARISON_TYPE.BENCHMARKING";
        default:
            return "HOME.COMPARISON_TYPE.INDUSTRY";
    }
}


/**
 * ZCxI6_8!bun
 * stringifyQueryParams
 * Returns stringified query params to be consumed by the URIs
 * e.g. stringifyQueryParams({ ...value }) => "?key=value&..."
 *
 **/

export function stringifyQueryParams (queryParams = {}) {
    const value = Object.keys(queryParams)
        .filter(k => queryParams[k] !== 'W10=')
        .map(k => {
            return `${k}=${_stringifyQueryData(queryParams[k])}`
        })
        .join("&");
    
    return value
}

export function stringifyQueryParams2 (queryParams = {}) {
    return Object.keys(queryParams).map(k => {
        const value = queryParams[k];
        if (Array.isArray(value)){
            return value.map(x=>`${k}=${x}`).join("&");
        }
        return `${k}=${_stringifyQueryData(queryParams[k])}`
    }).join("&");
}

export function toQueryString(dict){
    if(Object.keys(dict).length <1){
        return '';
    }
    return `?${stringifyQueryParams2(dict)}`;
}

export function parseQueryString(str){
    return _l.chain(str)
        .replace('?', '')
        .split('&')
        .map(_l.partial(_l.split, _l, '=', 2))
        .fromPairs()
        .value();
}

export function getDefaultHeaders(){
    const accessToken = localStorage.getItem("auth_access_token");
    return {
        'Accept': 'application/json',
        'Authorization': `JWT ${accessToken}`,
        'Content-Type': 'application/json'
    };
}

/**
 * constructUrl
 * Returns constructed url stitched with endpoint uri and stringified query params.
 * e.g. constructUrl("https://blahblah.com", "myname", { ...values }) => "https://blahblah.com/myname?key=value&..."
 *
 **/

export function constructUrl (endpoint, uri, queryParams) {
    let queryString = stringifyQueryParams(queryParams);
    return `${endpoint}/${uri}?${queryString || ''}`;
}

export function parseDate (date) {
    return moment(date).calendar();
}

export function jwtIsExpired(token){
    const decoded = jwtDecode(token);
    return decoded.exp*1000>(new Date().getTime()/1000);
}

export function initializeUnderscoreMixins() {
    _.mixin({
        maybe: function() {
            if (arguments.length < 2) throw "you're doing it wrong";
            var obj = _(arguments).first();
            return _(arguments).chain().tail().reduce(function(o, prop) {
                if (!o) return o;
                var checkForMethod = /(.*)\(\)$/.exec(prop);
                return checkForMethod ? o[checkForMethod[1]]() : o[prop];
            }, obj).value();
        },
        deepClone: function (arrayOrObject) {
            try {
                return JSON.parse(JSON.stringify(arrayOrObject))
            } catch(err) {
                Sentry.captureException(err);
                return {};
            }
        },
        getQueryParams: function(queryString) {
            var query = (queryString || window.location.search).substring(1); // delete ?
            if (!query) {
                return false;
            }
            return _
                .chain(query.split('&'))
                .map(function(params) {
                    var p = params.split('=');
                    return [p[0], decodeURIComponent(p[1])];
                })
                .object()
                .value();
        }
    });
}

// attribution: https://stackoverflow.com/a/57366270/3700490
export function nameToInitials(fullName) {
    if (!fullName) {
        return null;
    }
    const namesArray = fullName.split(' ');
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`;
    else return `${namesArray[0].charAt(0)}${namesArray[namesArray.length - 1].charAt(0)}`;
}

export function copyToClipboard (input) {
    /* Select the text field */
    if (input.nodeName === 'INPUT') {
        input.select();
        input.setSelectionRange(0, 99999); /* For mobile devices */
        navigator.clipboard.writeText(input.value);
    } else {
        // Select the div content
        if (document.selection) { // IE
            const range = document.body.createTextRange();
            range.moveToElementText(input);
            range.select();
        } else if (window.getSelection) {
            const range = document.createRange();
            range.selectNode(input);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
        }
        navigator.clipboard.writeText(window.getSelection().toString());
    }

    setTimeout(() => {
        if (window.getSelection) {
            window.getSelection().removeAllRanges();
        } else if (document.selection) {
            document.selection.empty();
        }
    }, 5000);

    /* Copy the text inside the text field */
    return input.value || input.innerText;
}

export const kpiFormatter = (value) => {
    return mapT(m.METRIC, value);
}

export const capitalizeString = (string) => {
    return string.toLowerCase().split(" ").map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(" ")
}

// https://usehooks.com/usePrevious/
export function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
      ref.current = value;
    });
    return ref.current;
}

export function getGlobalTranslatedMessage (response, key) {
    return response?.data?.detail ? i18n.t(key) : null
}

export function getActiveCompanyID(companies) {
    return getActiveCompany(companies)?.id
}

export function getActiveCompany(companies) {
    return _.find(companies, { uuid: getCompanyUUID() }) || companies?.[0]
}

export function objectFlip(obj) {
    return Object.keys(obj).reduce((ret, key) => {
      ret[obj[key]] = key;
      return ret;
    }, {});

}
// Move object to end of array if name is PNTS
export const movePntsToEnd = (data) => {
    const PREFER_NOT_TO_ANSWER = "Prefer not to answer";

    // temp: make sure PNTS is last
    let newData = [];
    let pntsItem = null;
    for (const d of data) {
        if (d.name === PREFER_NOT_TO_ANSWER) {
            pntsItem = d;
        } else {
            newData.push(d);
        }
    }
    if (pntsItem) {
        newData.push(pntsItem);
    }

    return newData;
}

export const cleanString = (str) => {
    if (!str) {
        return ''
    }
    
    return str.replace(/[^A-Za-z]+/g, '').toLowerCase()
}

export const parseYearQuarter = (selectedSurvey) => {
    return selectedSurvey ? `${selectedSurvey.year}Q${selectedSurvey.quarter}` : ''
}

export const MINIMUM_DATA_THRESHOLD = 6;