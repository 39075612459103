import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const TrainingsWrapper = styled.div`
    display: flex;
    position: relative;
`

export const FiltersWrapper = styled.div`
    height: calc(100vh - 86px);
    flex: 0 0 250px;
`

export const Filters = styled.div`
    position: fixed;
    top: 86px;
    bottom: 0px;
    left: 256px;
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.colors.primaryGray1};
    border-right: 1px solid ${({ theme }) => theme.colors.primaryWhite};
    overflow: auto;
`

export const FiltersGroup = styled.div`
    padding: 24px;
    border-bottom: 1px solid rgba(221, 216, 240, 0.7);

    .MuiIconButton-root {
        padding: 0;
    }

    .MuiRadio-colorSecondary {
        color: ${({ theme }) => theme.colors.primary90};
    }

    .MuiIconButton-colorSecondary:hover {
        background-color: none;
    }

    .MuiFormControlLabel-label {
        padding: 4px 0;
        margin-left: 8px;
        color: ${({ theme }) => theme.colors.secondary500};

        ${getFontStyle('captionRegularM')};
    }

    .MuiFormControlLabel-root {
        margin-left: 2px;
        margin-right: 0;
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .MuiSvgIcon-root {
        fill: ${({ theme }) => theme.colors.primary90};
    }
`

export const FiltersGroupTitle = styled.div`
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.secondary500};

    ${getFontStyle('cardHeaderBoldSmall')};
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 32px;
    width: 100%;

    ${media.lg} {
        padding: 48px;
    }
`

export const Title = styled.h2`
    margin: 0 0 18px;
    padding: 8 12px;
    color: ${({ theme }) => theme.colors.primary90};

    ${getFontStyle('h1Bold')};
`

export const List = styled.ul`
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: auto;
    gap: 16px;
    list-style-type: none;

    ${media.md} {
        grid-template-columns: repeat(2, 1fr);
    }
`

export const ListItem = styled.li``

export const SidePanelWrapper = styled.div`
    position: fixed;
    top: 86px;
    bottom: 0;
    right: 0;
`
