import { Survey } from 'api/actions/analyze/shared.types'

const YEAR_QUARTER_SPLIT = 'Q'

export const parseSurveyParam = (survey: Survey | null) => {
    if (!survey) {
        return ''
    }

    const { year, quarter } = survey

    return `${year}${YEAR_QUARTER_SPLIT}${quarter}`
}

export const validateSurveyParam = (yearParam?: string, quarterParam?: string): Survey | null => {
    if (yearParam === undefined) {
        return null
    }

    if (quarterParam === undefined) {
        return null
    }

    if (!new RegExp('^[0-9]{4}$').test(yearParam)) {
        return null
    }

    if (!new RegExp('^[1-4]$').test(quarterParam)) {
        return null
    }

    const year = Number(yearParam)
    const quarter = Number(quarterParam)

    return {
        name: `Q${quarter}, ${year}`,
        quarter,
        year,
    }
}
