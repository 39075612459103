import { useMutation } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { AxiosError } from 'axios'
import { Actions } from 'actions'

import { Mutations } from 'api/mutations.enum'
import { AuthErrorResponse, LoginPayload, LoginResponse } from 'api/actions/auth/authActions.types'
import { loginAction } from 'api/actions/auth/authActions'
import { handleAuthCredentials } from 'utils/handleAuthCredentials'

export const useLogin = () => {
    const dispatch = useDispatch()

    const mutation = useMutation<LoginResponse, AxiosError<AuthErrorResponse>, LoginPayload>({
        mutationKey: [Mutations.login],
        mutationFn: loginAction,
        onSuccess: (response) => {
            dispatch(Actions.doneLogin(handleAuthCredentials(response)))
        },
        onError: (error) => {
            if (error.response?.data.detail === 'INVALID_DEVICE_ID') {
                localStorage.removeItem('mfa_device_id')

                dispatch(Actions.resetAuth())
            }

            dispatch(Actions.doSignout())
        },
    })

    return mutation
}
