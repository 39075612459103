import { useMutation } from '@tanstack/react-query'
import { getDiversityCompanyProfiles } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import { CompanyProfilesParams } from 'api/actions/analyze/companyProfiles/companyProfilesActions.types'
import { Mutations } from 'api/mutations.enum'

export const useExportDiversityProfilesData = (params: CompanyProfilesParams) => {
    const mutation = useMutation<string, Error, void>({
        mutationKey: [Mutations.exportDiversityProfilesData, params],
        mutationFn: () => {
            return getDiversityCompanyProfiles(stringifyQueryParams(params), 'file')
        },
    })

    return mutation
}
