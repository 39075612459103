import React, { Component } from 'react';
import PiChart from './PiChart';
import Filters, { Year, Office, Level, Department, Tenure, FiltersSelected, FilterContext } from '../Filters';
import { getYearAliasMap } from '../Filters/utils';
import './index.scss';
import MainCard from '../../utils/MainCard';
import { withTranslation } from 'react-i18next';
import { KEY_MAP } from '../../Home/DiversityComposition/utils';
import { DiversitySnapshotDetail } from './DiversitySnapshotDetail';
import { renameEthnicity, getYearLabel, getOfficeRegionLabel, getDepartmentLabel, getTenureLabel, getLevelLabel} from "../../../utils/labels";
import {getTranslation} from "../../../translations/utils";
import mixpanel from "mixpanel-browser";
import TAXONOMIES from '../../../utils/taxonomies';

class DiversitySnapshot extends Component {
    state = {
        activeDetail: null,
        activeDetailTitle: null,
        activeIndex: null,
        selectedFilters: {},
    }

    componentDidUpdate(prevProps) {
        if (this.props.breakdown !== prevProps.breakdown) {
          this.setState({
            activeDetail: null,
            activeDetailTitle: null,
            activeIndex: null,
          })
        }
      }

    mapListToObj = (breakdown) => {
        let breakdownObj = {};
        for (const dimension of breakdown) {
            const firstKey = Object.keys(dimension)[0];
            breakdownObj[firstKey] = dimension[firstKey];
        }
        return breakdownObj;
    }

    getNdgBreakdown = (breakdownObj, key) => {
        return breakdownObj[key];

    }

    updateDetail = (data, title, index) => {
        this.setState({
            activeDetail: data,
            activeDetailTitle: title,
            activeIndex: index
        });
    }

    track (diversityOverviewBreakdownName) {
        mixpanel.track(TAXONOMIES.DIVERSITY_OVERVIEW_GRANULAR_BREAKDOWN, {
            diversityOverviewBreakdownName
        })
    }

    render () {
        const { companyName, t, ndg, breakdown, composition, helpText, latestSurveyYear } = this.props;

        const formattedData = this.props.composition.map(d => {
            const label = Object.keys(d)[0];
            const data = d[label];
            return {
                label,
                data
            }
        });

        const mappedComposition = this.mapListToObj(composition);

        return (
            <MainCard title={t("ANALYZE.CARD_HEADERS.DIVERSITY_BREAKDOWN.title")}
                      cypressId={"diversity-breakdown"}
                      toolTipContent={<div dangerouslySetInnerHTML={{ __html: helpText}}/>}>
            <div className="analyze-diversity-snapshot">
                <Filters filters={this.props.filters} applyFilters={this.props.handleApplyFilters} yearAliasMap={getYearAliasMap(companyName)} latestSurveyYear={latestSurveyYear}>
                    <Year onOpen={() => this.track("Year")} label={getYearLabel(companyName)}/>
                    <Office onOpen={() => this.track("Office")} label={getOfficeRegionLabel(companyName)}/>
                    <Level onOpen={() => this.track("Level")} label={getLevelLabel(companyName)}/>
                    <Department onOpen={() => this.track("Department")} label={getDepartmentLabel(companyName)}/>
                    <Tenure onOpen={() => this.track("Tenure")} label={getTenureLabel(companyName)} />
                    {
                        <FilterContext.Consumer>
                            {data => {
                                if (this.state.selectedFilters !== data.values) {
                                    this.setState({
                                        ...this.state,
                                        selectedFilters: data.values,
                                    })
                                }
                            }}
                        </FilterContext.Consumer>
                    }
                </Filters>
                <div className="analyze-diversity-snapshot__detail-box">
                    {(!this.state.activeDetail
                    ? t("ANALYZE.DIVERSITY.DIVERSITY_BREAKDOWN.CLICK_PIE_CHART")
                    : <DiversitySnapshotDetail
                        title={this.state.activeDetailTitle}
                        compositionData={this.state.activeDetail}
                        breakdownData={this.mapListToObj(breakdown)[this.state.activeDetailTitle]}
                        ndg={ndg[KEY_MAP[this.state.activeDetailTitle]]}
                        profile={this.props.profile}
                        t={t}
                        />)}
                </div>
                <div className="pie-chart-container">
                    {
                        formattedData
                        ? formattedData.map((d, id) =>
                            <div className="analyze-diversity-snapshot__single-pie">
                            <PiChart colors={this.props.colors} key={id} id={id} data={d.data} label={renameEthnicity(d.label)} showMessage={this.props.showMessage} breakdown={this.props.breakdown} composition={this.props.composition}
                            innerRadius={20}
                            outerRadius={60}
                            clickCallback={() => {
                                this.updateDetail(mappedComposition[d.label], d.label, id);

                                mixpanel.track(TAXONOMIES.USE_PICHART, {
                                    name: "Diversity PiChart",
                                    type: d.label
                                })
                                }}
                            selected={id === this.state.activeIndex}
                            profile={this.props.profile}
                            />
                            </div>
                        )
                        : null
                    }
                </div>
                <div className="analyze-diversity-snapshot__filter-box">
                    <FiltersSelected
                    values={this.state.selectedFilters}
                    allFilters={this.props.filters}
                        companyName={this.props.companyName}
                    />
                </div>
            </div>
            </MainCard>
        );
    };
};

export default withTranslation()(DiversitySnapshot);
