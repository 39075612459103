import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { ChevronRightIcon } from '@diversioteam/diversio-ds'

import { AppRoute } from './../../../routing/AppRoute.enum'
import { Footer } from './../Footer'
import { Toast } from './../Toast'
import { ssoSchema } from './sso.schema'
import { SsoFields, SsoFormData, SsoProps } from './sso.types'
import Link from './../../utils/Link'
import diversioLogo from './../../../assets/images/diversio-logo-with-symbol-for-light-bg.svg'
import * as S from './sso.styles'

export const Sso = ({ isLoading, isError, error, onSubmit, emailPlaceholder }: SsoProps) => {
    const { t } = useTranslation()

    const { register, handleSubmit, errors } = useForm<SsoFormData>({
        resolver: yupResolver(ssoSchema),
    })

    const handleSubmitCallback = useCallback(
        async (data: SsoFormData) => {
            await onSubmit(data)
        },
        [onSubmit],
    )

    const getErrorMessage = (fieldName: SsoFields) => {
        return t(errors[fieldName]?.message || '')
    }

    return (
        <S.Container>
            <S.Form onSubmit={handleSubmit(handleSubmitCallback)}>
                <Toast isLoading={isLoading} isError={isError} error={error} translationPrefix="AUTH.SSO.MESSAGES" />

                <S.Logo src={diversioLogo} className="logo" alt="Diversio" />

                <S.Title>{t('AUTH.SSO.LOGIN_WITH_SSO')}</S.Title>

                <S.Fields>
                    <S.TextField
                        id={SsoFields.email}
                        inputRef={register}
                        error={Boolean(errors[SsoFields.email])}
                        helperText={getErrorMessage(SsoFields.email)}
                        name={SsoFields.email}
                        label={t('AUTH.LOGIN.EMAIL')}
                        defaultValue={emailPlaceholder}
                        type="text"
                        disabled={isLoading}
                        fullWidth
                    />
                </S.Fields>

                <S.Button
                    loading={isLoading}
                    type="submit"
                    color="primary"
                    size="large"
                    rightIcon={<ChevronRightIcon type="bold" />}
                    fullWidth
                >
                    {t('AUTH.LOGIN.LOGIN')}
                </S.Button>

                <S.TextLink as={Link} to={{ pathname: AppRoute.Login, search: location.search }}>
                    {t('AUTH.SSO.GO_BACK')}
                </S.TextLink>

                <S.RegionSwitch sso />

                <Footer />
            </S.Form>
        </S.Container>
    )
}
