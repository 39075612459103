import React, {useEffect, useState} from 'react';
import './index.scss'
import Login from "../../Auth/loginV1";
import {connect} from "react-redux";
import {Actions} from "../../../actions";
import classNames from "classnames";
import {copyToClipboard} from "../../../utils";
import _ from "underscore";
import mixpanel from "mixpanel-browser";
import TAXONOMIES from '../../../utils/taxonomies';

const BackupCodes = (props) => {
    const { backupCodes: ogBackupCodes = [] } = props?.location?.state || {};
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState({});
    const [backupCodes, setBackupCodes] = useState(ogBackupCodes);

    useEffect(() => {
        if (props?.backupCodes?.length) {
            setIsLoggedIn(true);
            setBackupCodes([...props.backupCodes])
        }
    }, [props.backupCodes])

    useEffect(() => {
        return () => {
            props.resetBackupCodes();
        }
    }, [])

    const _handleCodes = res => {
        if (![200, 201].includes(res.code)) {
            props.showMessage({
                type: "error",
                code: res.code,
                message: _.maybe(res, "data", "detail")
            });
        } else {
            setIsLoggedIn(true);
            setBackupCodes([...res.data.backup_codes])
        }
    }

    const _handleAuthSubmit = (user) => {
        setUser(user);
        mixpanel.track(TAXONOMIES.DOWNLOAD_BACKUP_CODE, {
            backupClickedAt: new Date().toISOString()
        });
        props.doFetchExistingBackupCodes(user);
    }

    const _fetchNewCodes = () => {
        props.doFetchNewBackupCodes(user);
        mixpanel.track(TAXONOMIES.REFRESH_BACKUP_CODE, {
            refreshBackupClickedAt: new Date().toISOString()
        })
    }

    return (
        <div id="backup-codes">
            <h2>2-Factor Authentication</h2>
            {
                isLoggedIn || backupCodes.length
                    ? <>
                        <p>Please select below to copy your backup code for your 2 factor authentication.</p>
                        <div className={classNames("copy-link-container")}>
                            <textarea cols={30} rows={15} className={"collector-link"} type="text" value={backupCodes.join("\n")}/>
                            <div className={"copy"} onClick={e => {
                                copyToClipboard(e.target.previousSibling);
                                const target = e.target;
                                target.firstChild.innerText = "Copied";
                                setTimeout(() => {
                                    target.firstChild.innerText = "Copy";
                                }, 5000);
                            }}>
                                <p>Copy</p>
                            </div>
                        </div>
                        <a onClick={_fetchNewCodes}>Get New Codes</a>
                    </>
                    : <>
                        <p>Please login again to download your backup codes.</p>
                        <Login onSubmit={_handleAuthSubmit} message={""} username={props.username} passwordOnly/>
                    </>
            }
        </div>
    );
};

export default connect((state) => {return { 
    backupCodes: state.auth.backupCodes,
    username: state.auth.username
 }}, Actions)(BackupCodes);
