import React from 'react'
import { Link } from 'react-router-dom'
import { ChevronRightIcon, colors } from '@diversioteam/diversio-ds'

import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { useBreadcrumbs } from 'hooks/useBreadcrumbs'
import { AppRoute } from 'routing/AppRoute.enum'

import * as S from './analyzeBreadcrumbs.styles'

export const AnalyzeBreadcrumbs = () => {
    const breadcrumbs = useBreadcrumbs()
    const paths = breadcrumbs?.links

    return (
        <S.AnalyzeBreadcrumbsWrapper>
            {paths && (
                <S.Breadcrumbs
                    separator={<ChevronRightIcon type="line" height={8.75} color={colors.secondary300} />}
                    aria-label="breadcrumb"
                >
                    {paths.length > 0 && <Link to={generateCompanyURI(AppRoute.Analyze)}>Analyze</Link>}

                    {paths.map((path, id) => {
                        return (
                            <Link to={path.to} color="textPrimary" key={id}>
                                {path.label}
                            </Link>
                        )
                    })}
                </S.Breadcrumbs>
            )}
        </S.AnalyzeBreadcrumbsWrapper>
    )
}
