import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
  }

  body {
    .dds-single-select__popper {
      z-index: 1000;
    }
  }

  div[id*="react-joyride-step-"] {
    .__floater {
      transition: opacity 0.3s ease 0s, transform 0s !important;
    }
  }
`
