/** @format */

import mixpanel from "mixpanel-browser";
import { call, put } from "redux-saga/effects";
import TAXONOMIES from "../utils/taxonomies";

import { Actions, ActionTypes } from "../actions";
import { getPageName, mapProgramToTracking } from "../utils/tracking";
import { loadSolutionMySolutionsArchive, loadSolutionPatch, loadMyArchivedProgramsV2, changeMySolutionStatus } from "./network";

// All data sagas to add to middleware.
export default [
  [ActionTypes.GET_SOLUTION_MY_SOLUTIONS_ARCHIVE, getSolutionMySolutionsArchive],
  [ActionTypes.DO_SOLUTION_UNARCHIVE, doSolutionUnarchive],
  [ActionTypes.GET_SOLUTION_MY_SOLUTIONS_ARCHIVE_V2, getSolutionMySolutionsArchiveV2],
  [ActionTypes.DO_SOLUTION_UNARCHIVE_V2, doSolutionUnarchiveV2],
];

function* getSolutionMySolutionsArchive({ payload }) {
  yield put(Actions.loadingSolutionMySolutionsArchive());

  const response = yield call(loadSolutionMySolutionsArchive, payload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "error",
        message: `${response.data.detail}`,
      })
    );
    yield put(Actions.loadedSolutionMySolutionsArchive([]));
  } else {
    yield put(Actions.loadedSolutionMySolutionsArchive(response.data));
  }
}

function* doSolutionUnarchive({ payload }) {
  const { page, program_obj, ...basePayload } = payload
  const response = yield call(loadSolutionPatch, basePayload);

  if (!response.successful) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.doneSolutionUnarchive(response.data));
    yield put(Actions.updateSolutionArchiveCount(false));

    yield put(
      Actions.showToast({
        type: "success",
        message: "Program has been restored!",
      })
    );

    mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.REMOVE_FROM_ARCHIVED}`, mapProgramToTracking(program_obj))
  }
}

function* getSolutionMySolutionsArchiveV2({ payload }) {
  yield put(Actions.loadingSolutionMySolutionsArchive());

  const response = yield call(loadMyArchivedProgramsV2, payload);

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "error",
        message: `${response.data.detail}`,
      })
    );
    yield put(Actions.loadedSolutionMySolutionsArchiveV2([]));
  } else {
    yield put(Actions.loadedSolutionMySolutionsArchiveV2(response));
  }
}

function* doSolutionUnarchiveV2({ payload }) {
  const response = yield call(changeMySolutionStatus, {
    id: payload.solution,
    is_archived: false
  });

  if (response.data?.detail) {
    yield put(
      Actions.showToast({
        type: "warning",
        message: "Something went wrong",
      })
    );
  } else {
    yield put(Actions.doneSolutionUnarchive(response));
    yield put(Actions.updateSolutionArchiveCount(false));

    yield put(
      Actions.showToast({
        type: "success",
        message: "Program has been restored!",
      })
    );

    const { page, program_obj } = payload
    mixpanel.track(`${getPageName(page)}: ${TAXONOMIES.REMOVE_FROM_ARCHIVED}`, mapProgramToTracking(program_obj))
  }
}
