import React, {Component} from 'react';
import { Actions } from '../../actions';
import { connect } from 'react-redux';
import {Redirect} from "react-router-dom";
import { withTranslation } from 'react-i18next';
import Register from "../../components/Auth/register";
import SelfServeRegister from "../../components/Auth/self-serve-register";
import {getQueryParams} from "../../utils";
import queryString from 'query-string'

class SelfServeRegisterContainer extends Component {
    state = {
        refId: null
    };

    _handleOnSubmit (values) {
        this.props.doSelfServeRegistration({
            username: values.username,
            password: values.password,
            password_confirm: values.confirmPassword,
            email: values.email,
            company: values.company,
            ref_id: this.state.refId
        });
    }

    componentDidMount() {
        this.setState({
            refId: getQueryParams()["ref_id"]
        })
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        const { to } = queryString.parse(location.search)
        const { t } = this.props;

        if (this.props.isAuthenticated) {
            return <Redirect to={to || from} />
        }

        if (this.props.isRegistrationDone) {
            return <Redirect to={{
                pathname: "/login",
                state: { message: t("AUTH.LOGIN.REGISTER_SUCCESS") }
            }} />
        }

        return <SelfServeRegister onSubmit={this._handleOnSubmit.bind(this)}/>;
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth
    }
};

export default connect(mapStateToProps, Actions)(withTranslation()(SelfServeRegisterContainer));
