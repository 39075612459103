import React, {useEffect} from 'react';
import Progress from "./Progress";
import Link from '../utils/Link';
import {Button} from "react-bootstrap";
import surveyDraft from '../../assets/images/survey-draft.svg'
import mixpanel from "mixpanel-browser";
import TAXONOMIES from "../../utils/taxonomies";
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';

const Draft = ({ savedSurveys, setSelectedSurvey, selfServe }) => {
    useEffect(() => {
        setSelectedSurvey(savedSurveys[0]);
    }, []);

    return (
        <div className={"survey-review"}>
            <Progress selected={"create"} selfServe={selfServe}/>

            <div id={"survey-generic"}>
                <div className={"survey-content-container"}>
                    <img src={surveyDraft} alt="Survey Draft"/>
                    <h5>Welcome to Your D&I Survey Dashboard</h5>
                    <p>Create your survey to get a deep dive analysis of your organization’s culture</p>
                    <Link to={generateCompanyURI("/survey/create")} onClick={() => {
                        mixpanel.track(TAXONOMIES.CLICKED_CONTINUE_DRAFT)
                    }}>
                        <Button variant={"primary"}>Continue Drafted Survey</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Draft;
