import React, { useState, useEffect } from 'react';
import { Nav, Tab, Row, Col, Dropdown } from 'react-bootstrap';
import './index.scss';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Button } from "react-bootstrap";
import _, { filter } from 'underscore';
import { getDepartmentLabel, getOfficeRegionLabel, getLevelLabel, getYearLabel } from "../../../../utils/labels";
import { withTranslation } from 'react-i18next';
import * as m from '../../../../translations/mapping';
import { mapT } from '../../../../translations/utils';
import Filters, { Department, Level, Office, Tenure, Year } from "../../Filters";
import { createFiltersPayload, getYearAliasMap } from "../../Filters/utils"
import mixpanel from "mixpanel-browser";
import { FilterContext } from '../../Filters';
import TAXONOMIES from '../../../../utils/taxonomies';

const FiltersComponent = ({ company, filters, applyFilters, onChange, t, diversityComposition, latestSurveyYear }) => {
    let ogState = {
        office: null,
        level: null,
        department: null,
        year: null,
        group_a: {},
        group_b: {}
    }
    let ogFilters = filters

    const [values, setValues] = useState(ogState);
    const [filtersToHide, setFiltersToHide] = useState([])
    const [newFilters, setNewFilters] = useState(ogFilters);

    /*      PAGE LOAD
        1.) On page load we first check for partial categorization by checking each value in
            the dashboards diversity composition to see if the array is empty or not. If empty 
            we update array filtersToHide local state value and add the missing group.
            orientations, genders, ethnicities, disabilities, etc. Then we delete the key/value
            from a cloned filters.groups object
        
        2.) Now that we've cleaned the data we create 2 new cloned objects of the cleaned data
            which represent groups a & b. We then iterate over each objects key's to prefill
            gender values for groups a & b comparison, this represents the initial bar chart state the 
            user will see. Finally we assign the components local state "values" as well as the components
            parent's state  
    */
    useEffect(() => {
        /*
            We check to see if any categorization is missing from the 4 options below.
            If any are missing we do not display the dropdown from being shown and only
            show the dropdowns that the user can select from
        */
       let cleanedGroups = {...newFilters.groups}

        const objectMap = {
            'Gender': 'genders',
            'Ethnicity': 'ethnicities',
            'Sexual Orientation': 'orientations',
            'Disability': 'disabilities'
        }
      
        for(const i in diversityComposition) {
            const key = Object.keys(diversityComposition[i])[0]
            const groupKey = objectMap[key]

            if(!diversityComposition[i][key]?.length) {
                if(groupKey === 'disabilities') {
                    const choices = ['physical_', 'cognitive_', 'mental_', '']
                    for(const i in choices) {
                        const choice = choices[i] + groupKey
                        setFiltersToHide(filtersToHide => [...filtersToHide, choice])
                    }
                } else {
                    setFiltersToHide(filtersToHide => [...filtersToHide, groupKey])
                } 
                delete cleanedGroups[groupKey]
            }
        }

        let cleanedGroupsA = {...cleanedGroups}
        let cleanedGroupsB = {...cleanedGroups}

        for(const i in cleanedGroupsA) {
            if(i === 'genders' ) {
                cleanedGroupsA[i] = ['Male']
                continue
            }
            cleanedGroupsA[i] = []
        }

        for(const i in cleanedGroupsB) {
            if(i === 'genders' ) {
                cleanedGroupsB[i] = ['Female']
                continue
            }
            cleanedGroupsB[i] = []
        }

        ogState.group_a = {...cleanedGroupsA}
        ogState.group_b = {...cleanedGroupsB}
        setValues(ogState)
        onChange(ogState);
    }, []);

    const _clearFilters = () => {
        setValues(ogState);
        setNewFilters(ogFilters);
        onChange(ogState);
        setTimeout(() => { applyFilters(createFiltersPayload(ogState, ogFilters)) }, 0)
    };

    const _addOrRemoveValues = (key, option, val) => {
        let clonedValues = {};
        if (option in values[key]) {
            if (values[key][option].includes(val)) {
                values[key][option] = _.reject(values[key][option], function (d) { return d === val });
            } else {
                values[key][option].push(val);
            }
        } else {
            values[key][option] = [val];
        }
        clonedValues = { ...values };
        _setValues(clonedValues);
    };

    const _setValues = (values) => {
        setValues(values);
        onChange(values);
    };

    const _getCheckbox = (name, label, checked, onChange) => {
        return (
            <>
                <Dropdown.ItemText as={"span"}>
                    <FormControlLabel
                        className={"option-label"}
                        control={
                            <Checkbox
                                color={"primary"}
                                name={name}
                                checked={checked}
                                onChange={onChange}
                            />
                        }
                        label={mapT(m.GROUP_BREAKDOWN, label)}
                    />
                </Dropdown.ItemText>
            </>
        )
    };

    const selectOrRemoveAll = (groupType, group) => {
        /*
            Get all the value options for a specified group (genders, ethnicities, etc)
            and add/remove them to/from our "selected" array.

            groupType equals either 'group_a' or 'group_b'
            group is either ethnicities, genders, orientations, physical/mental/cognitive_disabilities
        */

        // Used to check if all values have been selected or not
        let clonedValues = {}

        // Source
        // https://stackoverflow.com/a/53606357
        let checker = (arr, target) => target.every(v => arr && arr.includes(v));

        // current selected values for specified groupType and group
        const selectedGroupValues = values[groupType][group]

        // All possible values for specified group 
        // group_a and group_b share the same 'group' values
        const allGroupValues = newFilters["groups"][group]

        // Returns true if all values are selected, so we know to remove instead of add again
        const allValuesAreSelected = checker(selectedGroupValues, allGroupValues)

        if (allValuesAreSelected) {
            // Let's remove all selected groups
            values[groupType][group] = []
            clonedValues = { ...values }
            _setValues(clonedValues)
        } else {
            // Empty any pre selected values before assigning all values so we don't get duplicates
            values[groupType][group] = []
            values[groupType][group].push(...allGroupValues)
            clonedValues = { ...values }
            _setValues(clonedValues)
        }
    }

    const areAllValuesSelected = (groupType, group) => {
        // current selected values for specified groupType and group
        const selectedGroupValues = values[groupType][group]?.sort()
        // All possible values for specified group 
        // group_a and group_b share the same 'group' values
        const allGroupValues = newFilters["groups"][group]?.sort()

        // Note .sort(), we have to alphabetize

        if (_.isEqual(selectedGroupValues, allGroupValues)) {
            return true
        }
        return false
    }

    return (
        <div id={"inclusion_breakdown-filters__container"}>
            <div className={"filters-container"}>
                <div className={"filter-actions"}>
                    <p>{t("ANALYZE.INCLUSION_BREAKDOWN.FILTERS_TITLE")}</p>
                    <div className={"filter-actions-btns"}>
                        <Button variant={"primary"} className={"apply-filter"} onClick={() => applyFilters(createFiltersPayload(values, newFilters))}>{t("ANALYZE.FILTERS.SUBMIT")}</Button>
                        <Button variant={"light"} className={"clear-filter"} onClick={_clearFilters}>{t("ANALYZE.FILTERS.RESET")}</Button>
                    </div>
                </div>
            </div>
            <div className={"inclusion_breakdown-filters"}>
                <Tab.Container defaultActiveKey="group_a">
                    <Nav onSelect={selectedKey => {
                        mixpanel.track(TAXONOMIES.INCLUSION_BREAKDOWN_TAB_CHANGED, {
                            inclusionBreakdownTabName: selectedKey
                        });
                    }}>
                        <Nav.Item>
                            <Nav.Link eventKey="group_a">{t("ANALYZE.FILTERS.GROUP_A")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="group_b">{t("ANALYZE.FILTERS.GROUP_B")}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="office">{getOfficeRegionLabel(company)}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="level">{getLevelLabel(company)}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="department">{getDepartmentLabel(company)}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="year">{t("ANALYZE.FILTERS.YEAR")}</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane className='group-a-options' eventKey="group_a">
                            <Row>
                                {
                                    _.keys(newFilters.groups).map((group, i) => {
                                        // Check to see if the dropdown can be shown or not
                                        const show = !filtersToHide.includes(group)
                                        return (
                                            <Col key={i} className={show ? "options-column" : "options-column-hide"}>
                                                {show && <Dropdown>
                                                    <Dropdown.Toggle as={"h6"} id={`dropdown-group-a-${i}`}>
                                                        {mapT(m.GROUP_BREAKDOWN, newFilters.metadata.groups[group])}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {_getCheckbox('group_a', 'Select All', areAllValuesSelected('group_a', group), () => { selectOrRemoveAll('group_a', group) })}
                                                        {
                                                            newFilters.groups[group].map((option, i) => {
                                                                return _getCheckbox(group, option,
                                                                    group in values.group_a && values.group_a[group].includes(option),
                                                                    () => _addOrRemoveValues("group_a", group, option))
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                }
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="group_b">
                            <Row>
                                {
                                    _.keys(newFilters.groups).map((group, i) => {
                                        // Check to see if the dropdown can be shown or not
                                        const show = !filtersToHide.includes(group)
                                        return (
                                            <Col key={i} className={show ? "options-column" : "options-column-hide"}>
                                                {show && <Dropdown>
                                                    <Dropdown.Toggle as={"h6"} id={`dropdown-group-b-${i}`}>
                                                        {mapT(m.GROUP_BREAKDOWN, newFilters.metadata.groups[group])}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {_getCheckbox('group_b', 'Select All', areAllValuesSelected('group_b', group), () => { selectOrRemoveAll('group_b', group) })}
                                                        {
                                                            newFilters.groups[group].map((option, i) => {
                                                                return _getCheckbox(group, option,
                                                                    group in values.group_b && values.group_b[group].includes(option),
                                                                    () => _addOrRemoveValues("group_b", group, option))
                                                            })
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                }
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="office">
                            <Row>
                                <Col className={"options-column"}>
                                    <Filters
                                        propsToStateChange={true}
                                        filters={newFilters}
                                        values={values}
                                        submittable={false}
                                        onChange={newValues => { newValues.office && _setValues({ ...values, office: newValues.office })}}
                                        companyName={company}
                                        latestSurveyYear={latestSurveyYear}
                                    >
                                        <Office label={getOfficeRegionLabel(company)} />
                                    </Filters>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="level">
                            <Row>
                                <Col className={"options-column"}>
                                    <Filters
                                        propsToStateChange={true}
                                        filters={newFilters}
                                        values={values}
                                        submittable={false}
                                        onChange={newValues => { newValues.level && _setValues({ ...values, level: newValues.level })}}
                                        companyName={company}
                                        latestSurveyYear={latestSurveyYear}
                                    >
                                        <Level />
                                    </Filters>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="department">
                            <Row>
                                <Col className={"options-column"}>
                                    <Filters
                                        propsToStateChange={true}
                                        filters={newFilters}
                                        values={values}
                                        submittable={false}
                                        onChange={newValues => { newValues.department && _setValues({ ...values, department: newValues.department }) }}
                                        companyName={company}
                                        latestSurveyYear={latestSurveyYear}
                                    >
                                        <Department label={getDepartmentLabel(company)} />
                                    </Filters>
                                </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="year">
                            <Row>
                                <Col className={"options-column"}>
                                    <Filters
                                        propsToStateChange={false}
                                        filters={ogFilters}
                                        values={values}
                                        submittable={false}
                                        onChange={(newValues, filters) => { 
                                            newValues.year && _setValues({ ...values, year: newValues.year }) 
                                            if (filters && newFilters !== filters) {
                                                setNewFilters(filters);
                                            }
                                        }}
                                        yearAliasMap={getYearAliasMap(company)}
                                        companyName={company}
                                        latestSurveyYear={latestSurveyYear}
                                    >
                                        <Year label={getYearLabel(company)}/>
                                    </Filters>
                                </Col>
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </div>
    );
};

export default withTranslation()(FiltersComponent);
