import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import mixpanel from 'mixpanel-browser'

import { AppRoute } from 'routing/AppRoute.enum'
import { RootState } from 'reducers/rootState.types'
import { VerifyBackupCodeFormData } from 'components/Auth/VerifyBackupCode/verifyBackupCode.types'
import { VerifyBackupCode } from 'components/Auth/VerifyBackupCode'
import { useValidateVerificationCode } from 'hooks/auth/useValidateVerificationCode/useValidateVerificationCode'

import TAXONOMIES from './../../../utils/taxonomies'
import { Actions } from './../../../actions'
import { RedirectAuthenticatedUser } from './../RedirectAuthenticatedUser'

export const VerifyBackupCodeContainer = () => {
    const { accessToken, refreshToken, isAuthenticated, backupCodes, username } = useSelector(
        (state: RootState) => state.auth,
    )

    const dispatch = useDispatch()

    const history = useHistory()

    const handleOnAction = () => {
        dispatch(Actions.doRefreshToken({ accessToken, refreshToken }))
    }

    const handleOnIdle = () => {
        dispatch(Actions.doSignout())
    }

    useIdleTimer({
        onIdle: handleOnIdle,
        onAction: handleOnAction,
        timeout: 1000 * 60 * Number(process.env.REACT_APP_INACTIVE_EXPIRE_TIME),
        throttle: 1000 * 60 * Number(process.env.REACT_APP_TOKEN_REFRESH_TIME),
    })

    const mutationValidateVerificationCode = useValidateVerificationCode()

    const handleSubmit = useCallback(
        async (values: VerifyBackupCodeFormData): Promise<boolean> => {
            try {
                mixpanel.track(TAXONOMIES.TWO_FACTOR_AUTHENTICATION_CODE_SUBMITTED, {
                    twoFactorClickedAt: new Date().toISOString(),
                    username: username,
                    verificationType: 'backup_code',
                    trustDevice: values.trustedDevice,
                })

                await mutationValidateVerificationCode.mutateAsync({
                    verification_type: 'backup_code',
                    ...values,
                })

                return true
            } catch (error) {
                return false
            }
        },
        [mutationValidateVerificationCode],
    )

    const handleClickBack = () => {
        return history.push({ pathname: AppRoute.SendVerificationCode, search: location.search })
    }

    if (!accessToken) {
        return <Redirect to={AppRoute.Login} />
    }

    if (isAuthenticated) {
        if (backupCodes?.length) {
            return (
                <Redirect
                    to={{
                        pathname: AppRoute.AccountMfa,
                        state: { backupCodes },
                    }}
                />
            )
        }

        return <RedirectAuthenticatedUser />
    }

    return (
        <VerifyBackupCode
            isLoading={mutationValidateVerificationCode.isLoading}
            isError={mutationValidateVerificationCode.isError}
            error={mutationValidateVerificationCode.error}
            onClickBack={handleClickBack}
            onSubmit={handleSubmit}
        />
    )
}
