import React, { useEffect, useRef, useState } from 'react'
import {
    HeatMapHeaderCell,
    InfoIcon,
    Tooltip,
    ChevronDownIcon,
    ChevronUpIcon,
    Card,
    Button,
    UnstableChip as Chip,
} from '@diversioteam/diversio-ds'
import { ErrorBoundary } from '@sentry/react'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { CardTitle } from '../../inclusion.styles'

import { isDataUnavailable } from './../heatmap.utils'
import { HeatmapHeaderProps, TooltipProps } from './header.types'
import { getCompareBadgeCells } from './header.utils'
import * as S from './header.styles'

const renderTooltip = (data: TooltipProps) => {
    if (isDataUnavailable(data.tooltip)) {
        return undefined
    }

    return (
        <Tooltip title={data.tooltip} size="medium" variant="light">
            <div>
                <InfoIcon type="line" width={10} height={10} color="#aeabb8" />
            </div>
        </Tooltip>
    )
}

const HeatmapHeaderWithoutEB = ({ data, hasInclusionChip }: HeatmapHeaderProps) => {
    const content = useRef<HTMLDivElement>(null)

    const [headerExpanded, setHeaderExpanded] = useState<boolean>(false)
    const [enableShowMore, setEnableShowMore] = useState(false)

    const dataWithScores = data.filter(({ score }) => !isDataUnavailable(score))

    useEffect(() => {
        const scrollHeight = content.current?.scrollHeight || 0
        const offsetHeight = content.current?.offsetHeight || 0

        if (scrollHeight > offsetHeight) {
            setEnableShowMore(true)
        } else {
            setEnableShowMore(false)
        }
    }, [content])

    if (!dataWithScores.length) {
        return null
    }

    return (
        <Card>
            <S.CardHeader>
                <CardTitle>
                    Employee Experience {hasInclusionChip && <Chip label="Inclusion Scores" color="gray" />}
                </CardTitle>

                {enableShowMore && (
                    <Button
                        color="transparent"
                        leftIcon={
                            headerExpanded ? (
                                <ChevronUpIcon type="line" width={16} height={16} />
                            ) : (
                                <ChevronDownIcon type="line" width={16} height={16} />
                            )
                        }
                        onClick={() => setHeaderExpanded(!headerExpanded)}
                    >
                        Show {headerExpanded ? 'Less' : 'All'}
                    </Button>
                )}
            </S.CardHeader>

            <S.Overview expanded={headerExpanded} ref={content}>
                {dataWithScores.map((overallData, idx) => {
                    if (isDataUnavailable(overallData.score)) {
                        return null
                    }

                    return (
                        <ErrorBoundary key={`heatmap-header-overall-${idx}`}>
                            <HeatMapHeaderCell
                                name={overallData.title}
                                value={overallData.score}
                                size="small"
                                nameChildren={renderTooltip(overallData)}
                                compareBadgeCells={getCompareBadgeCells(overallData)}
                            />
                        </ErrorBoundary>
                    )
                })}
            </S.Overview>
        </Card>
    )
}

export const HeatmapHeader = withErrorBoundary(HeatmapHeaderWithoutEB, {})
