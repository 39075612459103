import React from 'react'
import { InclusionFilterPanelSkeleton } from '@diversioteam/diversio-ds'

export const FilterPaneSkeleton = () => {
    return (
        <div className="ddsi-filter-panel">
            <InclusionFilterPanelSkeleton />
        </div>
    )
}
