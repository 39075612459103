import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Card from '../../../utils/Card';
import { DashboardTooltip } from '../../../utils/DashboardTooltip';
import './index.scss';
import level0 from '../../../../assets/images/DiversityPE/level_0.png';
import level1 from '../../../../assets/images/DiversityPE/level_1.png';
import level2 from '../../../../assets/images/DiversityPE/level_2.png';
import PropTypes from 'prop-types';
import Score from '../../../DiversityVC/Overview/Header/parts/Score.js'
import Select from '../../../utils/Select';
import _ from 'underscore';

Score.propTypes = {
    recruiting: PropTypes.number,
    culture: PropTypes.number,
    dealflow_sources: PropTypes.number,
    portfolio_guidance: PropTypes.number,
    overall: PropTypes.number,
};

const initialBenchmarkState = {
    currentBenchmark: {},
    benchmarkNames: [],
    benchmarkValues: [],
}


class Header extends Component {

    constructor(props) {
        super(props);

        this.state = initialBenchmarkState;
    }

    componentDidUpdate() {

        if (!_.isEmpty(this.props.benchmark) && _.isEmpty(this.state.currentBenchmark)) {
            const benchmarkFromProps = _.maybe(this.props.benchmark, "selected_benchmark") || {};
            const benchmarkNamesFromProps = _.maybe(this.props.benchmark, "benchmark_names") || {};
            const benchmarkValuesFromProps = _.maybe(this.props.benchmark, "all_benchmarks") || {};

            this.setState({
                currentBenchmark: benchmarkFromProps,
                benchmarkNames: benchmarkNamesFromProps,
                benchmarkValues: benchmarkValuesFromProps
            })
        }
    }

    onChange = (selectedValues) => {
        this.setState({
            currentBenchmark: this.state.benchmarkValues.find(n => n.name == selectedValues) || {}
        });
    }

    getLevel = (level) => {
        if (level == 0) {
            return level0
        }
        if (level == 1) {
            return level1
        }
        if (level == 2) {
            return level2
        }
    }

    render() {

        const { score, benchmark: benchmarkObj = {}, t } = this.props;
        const { all_benchmarks, benchmark_names = [] } = benchmarkObj;

        return (
            <div className={'summary'}>
                <Card className={'certification'}>
                    <div className="title-tooltip-wrapper">
                        <div className="your-certification-title">
                            <h1>Your Certification<br/>Status</h1>
                        </div>
                        <div className="icon-question-mark-wrapper">
                            <DashboardTooltip content={t("DIVERSITY_PE.CERTIFICATION.TOOLTIP")}>
                                <img className="icon icon-question-mark" />
                            </DashboardTooltip>
                        </div>
                    </div>
                    {
                        this.props.score && this.props.score.level !== undefined ?
                            <img src={this.getLevel(this.props.score.level)} className="certification-image"/> : null
                    }
                </Card>
                <Card className={'overview'}>
                    <div className="title-tooltip-wrapper">
                        <div>
                            <h1 style={{ display: 'inline-block' }}>Overview</h1>
                        </div>
                        <div className="icon-question-mark-wrapper">
                            <DashboardTooltip content={t("DIVERSITY_PE.OVERVIEW.TOOLTIP")}>
                                <img className="icon-question-mark" />
                            </DashboardTooltip>
                        </div>
                    </div>
                    <p>Percentage of recommended policies, programs, and best practices that your fund has in place.</p>
                    <div className="score-benchmark-select-container">
                        <Select
                            multiple={false}
                            options={this.state.benchmarkNames}
                            selectedValues={this.state.currentBenchmark.name || ""}
                            label="Benchmark"
                            onChange={this.onChange}
                            renderValue={(e) => (e)}
                            mapChoiceLabel={(e) => (e)}
                            extraClasses=""
                            styleClass="diversityVc"
                        />
                    </div>
                    <div className="score-container">
                        <Score
                            recruiting={score.recruiting}
                            culture={score.culture}
                            dealflow_sources={score.dealflow_sources}
                            portfolio_guidance={score.portfolio_guidance}
                            overall={score.overall}
                            companyType={'diversitype'}
                        />
                        <Score
                            isIndustry
                            companyType={'diversitype'}
                            {...this.state.currentBenchmark}
                        />
                    </div>
                </Card>
            </div>
        );
    }
}

export default withTranslation()(Header);
