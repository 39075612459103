const DEMO_COMPANIES = [
    "diversio demo"
]

const HIDE_LONGITUDINAL_COMPANY_NAMES = [
    "biocanrx", "sheertex", "infrastructure ontario"
];

const AMAZING_INVESTOR = "amazing investor"

export const PORTFOLIO_COMPANIES = [
    AMAZING_INVESTOR,
    "mcrock",
    "mcrock portfolio(without offices)",
    "bdc",
    "omers portfolio",
    "interac",
    "bessemer venture partners brief portfolio",
    "hggc portfolio",
    "bdc-industrial innovation venture fund",
    "bdc-it ventures",
    "bdc-industrial, clean energy",
    "bdc-co-investments",
    "oxford sciences innovation portfolio",
    "m12 portfolio"
]

export const ORGANIZATION_COMPANIES = [
    "hrpa",
    "canadian institute of actuaries",
    "equalby30",
]

const OFFICE_IS_COUNTRY_COMPANIES = [
    "equalby30",
]

export const OLX_COMPANIES = [
    "OLX-uzbekistan", "OLX-united states", "OLX-united arab emirates",
    "OLX-ukraine", "OLX-turkey", "OLX-spain", "OLX-south africa",
    "OLX-russia", "OLX-romania", "OLX-portugal", "OLX-poland",
    "OLX-peru", "OLX-pakistan", "OLX-netherlands", "OLX-lebanon", "OLX-kazakhstan",
    "OLX-indonesia", "OLX-india", "OLX-germany", "OLX-egypt", "OLX-ecuador",
    "OLX-columbia", "OLX-bulgaria", "OLX-brazil", "OLX-bosnia and herzegovina",
    "OLX-argentina",
]

export const BENCHMARKING_COMPANIES = [
    "pensions",
    "pensions & financial services"
]

// Random "regular" companies chosen for test purposes only
export const REGULAR_COMPANIES = [
    "arterra", 
    "peerwell", 
    "transalta", 
    "danone", 
]

export const UNILEVER_COMPANIES = [
    'unilever',
    'unilever south africa',
    'unilever india',
    'unilever netherlands',
    'unilever philippines',
    'unilever mexico',
    'unilever brazil'
]

/* 
 * Use one of the functions below to create a `is<Identifier>` 
 * function for simple checks or string comparisons in other files
 */
export const nameMatches = (companyName, match_str) => {
    return typeof (companyName) === 'string' && match_str.toLowerCase() === companyName.toLowerCase()
};

export const nameIncludes = (companyName, substring) => {
    return typeof (companyName) === "string" && companyName.toLowerCase().includes(substring.toLowerCase())
};

export const arrayIncludes = (array, companyName) => {
    return typeof (companyName) === "string" && array.includes(companyName.toLowerCase())
};



export const isDemoCompany = (companyName) => {
    return arrayIncludes(DEMO_COMPANIES, companyName)
}

export const isOrganizationCompany = (companyName) => {
    return arrayIncludes(ORGANIZATION_COMPANIES, companyName)
}

export const officeIsCountry = (companyName) => {
    return arrayIncludes(OFFICE_IS_COUNTRY_COMPANIES, companyName)
}

export const isPortfolioCompany = (companyName) => {
    return arrayIncludes(PORTFOLIO_COMPANIES, companyName) || nameIncludes(companyName, AMAZING_INVESTOR)
}

export const isBenchmarkingCompany = (companyName) => {
    return arrayIncludes(BENCHMARKING_COMPANIES, companyName)
}

export const isMcrockDueDiligence = (companyName) => {
    return nameMatches(companyName, "mcrock due diligence")
};

export const isHondaHTC = (companyName) => {
    return nameMatches(companyName, "honda htc");
};

export const isHRPA = (companyName) => {
    return nameMatches(companyName, "hrpa");
};

export const isCia = (companyName) => {
    return nameMatches(companyName, "canadian institute of actuaries");
};

export const isBessemerBriefPortfolio = (companyName) => {
    return nameMatches(companyName, "bessemer venture partners brief portfolio");
};

export const isBessemerAnonymized = (companyName) => {
    return nameMatches(companyName, "acme capital");
};

export const hideLongitudinal = (companyName) => {
    return arrayIncludes(HIDE_LONGITUDINAL_COMPANY_NAMES, companyName)
}

export const isCPAOntario = (companyName) => {
    return nameMatches(companyName, "CPA Ontario");
}

export const isCPAMembersOntario = (companyName) => {
    return nameMatches(companyName, "CPAO-Members");
}

export const isCifarScholars = (companyName) => {
    return nameIncludes(companyName, "cifar global scholars")
}

export const isCifarGlobalCall = (companyName) => {
    return nameIncludes(companyName, "cifar global call")
}

export const isCifarDLRL = (companyName) => {
    return nameIncludes(companyName, "cifar dlrl")
}

export const isBMCWinterSchool = (companyName) => {
    return nameIncludes(companyName, "bmc winter school")
}

export const isCifarCore = (companyName) => {
    return nameIncludes(companyName, "cifar core")
}

export const isCifarGov = (companyName) => {
    return nameIncludes(companyName, "cifar gov")
}

export const isCifarPanCanadianAIStrategy = (companyName) => {
    return nameIncludes(companyName, "cifar pan canadian ai strategy")
}

export const isCifarGlobalCallOverall = (companyName) => {
    return nameIncludes(companyName, "cifar global call overall")
}

export const isCifarSolutionsNetwork = (companyName) => {
    return nameIncludes(companyName, "cifar solutions network")
}

export const isCifarWithStages = (companyName) => {
    return isCifarScholars(companyName) || isCifarGlobalCall(companyName) || isCifarDLRL(companyName) || isBMCWinterSchool(companyName) || isCifarSolutionsNetwork(companyName)
}

export const isBrucePower = (companyName) => {
    return nameMatches(companyName, "Bruce Power")
}

export const isKingSettCapital = (companyName) => {
    return nameMatches(companyName, "KingSett Capital")
}

export const isSickKidsFoundation = (companyName) => {
    return nameMatches(companyName, "SickKids Foundation")
}

export const isBlueOwl = (companyName) => {
    return nameMatches(companyName, "Blue Owl")
}

export const isCenterbridgeLeadership = (companyName) => {
    return nameMatches(companyName, "Centerbridge Leadership")
}

export const isCaiPortfolio = (companyName) => {
    return nameMatches(companyName, "CAI Capital Partners Portfolio")
}

export const isOntarioTeachersPensionPlanBoardOTPP = (companyName) => {
    return nameMatches(companyName, "OTPP Full Data (Self-ID and Script)")  || nameMatches(companyName, "OTPP Partial Data (Self-ID Only)")
}

export const isUnilever = (companyName) => {
    return arrayIncludes(UNILEVER_COMPANIES, companyName)
}

export const isBigHand = (companyName) => {
    return nameMatches(companyName, "BigHand");
}

export const isMitacsBoard = (companyName) => {
    return nameMatches(companyName, "Mitacs Board");
}

// BCI mapping based on https://diversio.slack.com/archives/C03UG7JML12/p1694619125308469
export const isBCI = (companyName) => {
    return nameIncludes(companyName, "BCI Employees") && !nameIncludes(companyName, "Benchmark");
}

// BCI mapping based on https://diversio.slack.com/archives/C03UG7JML12/p1694619125308469
export const isBCIStudents = (companyName) => {
    return nameIncludes(companyName, "BCI Students") && !nameIncludes(companyName, "Benchmark");
}

// BCI mapping based on https://diversio.slack.com/archives/C03UG7JML12/p1694619125308469
export const isBCIApplicants = (companyName) => {
    return nameIncludes(companyName, "BCI Applicants") || nameIncludes(companyName, "BCI Student Applicants");
}

export const isDiversioDemo = (companyName) => {
    // Newly created demo amazing investor dashboards follow the format "Diversio Demo Amazing Investor <Username>"
    // hence we need to check if the name doesn't include "Amazing Investor"
    return nameIncludes(companyName, "Diversio Demo") && !nameIncludes(companyName, AMAZING_INVESTOR);
}

export const isTrueVentures = (companyName) => {
    return nameIncludes(companyName, "True Ventures");
}

export const isEnergySolutions = (companyName) => {
    return nameMatches(companyName, "Energy Solutions");
};

export const isIllinoisShines = (companyName) => {
    return nameIncludes(companyName, "Illinois Shines");
}

export const IsAltasPortfolio = (companyName) => {
    return nameMatches(companyName, "Altas Portfolio") || nameMatches(companyName, "Altas - Portfolio");
}

export const isMila = (companyName) => {
    return nameMatches(companyName, "Mila");
}

export const isClydeCo= (companyName) => {
    return nameIncludes(companyName, "Clyde & Co");
}
