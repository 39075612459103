import React, { Component } from 'react';
import Card from '../../utils/Card';
import level1 from '../../../assets/images/DiversityPE/level_1.png';
import level2 from '../../../assets/images/DiversityPE/level_2.png';
import ProgramList from '../../DiversityVC/About/parts/ProgramList';
import RedNumberList from '../../DiversityVC/About/parts/RedNumberList';
import { PROGRAMS, L1REQUIREMENTS, L2REQUIREMENTS } from './programs.js'
import './index.scss';


class About extends Component {

    render() {
        return (
            <Card className="diversity-pe__about">
                <div className="diversity-pe__logos">
                </div>
                <div className="diversity-pe__section diversity-pe__what-is-it">
                    <div>
                        <h1>What is the Diversio PE Standard?</h1>
                        <p>
                            The Diversio Private Equity (PE) Standard is a certification and assessment process that sets a benchmark on best practice for Diversity & Inclusion (D&I) in Private Equity. It is a signal to prospective employees, LPs, and markets that a fund is a D&I leader.                        </p>
                        <p>
                            The PE Standard provides private equity funds with the tools and recommendations they need to create a diverse and inclusive culture where everyone feels that they have a place in the industry. Additionally, the Standard encourages funds to extend their D&I commitment beyond their own workplace and to exert their influence to affect meaningful D&I change within their portfolio companies. This is accomplished by examining the internal policies a fund has in place and providing practical recommendations for how existing portfolio companies can implement their own D&I policies.                        </p>
                        <p>
                            The assessment and recommendations that form the Diversio PE Standard are evidence-led and based on research from the Harvard Business Review, Deloitte, McKinsey, and Diversio. The framework behind the Standard is aligned with the Diversity in Action Guidelines launched by the Institutional Limited Partners Association (ILPA) in 2021, an industry body that also endorses Diversio.
                        </p>
                    </div>
                </div>
                <div className="diversity-pe__section--light diversity-pe__must-have">
                    <div className="diversity-pe__columns">
                        <div>
                            <h2>To Receive a Level 1 Standard You Must Have:</h2>
                            <img style={{width: '183px'}} src={level1} />
                            <RedNumberList items={L1REQUIREMENTS} />

                        </div>
                        <div>
                            <h2>To Receive a Level 2 Standard You Must Have:</h2>
                            <img style={{width: '183px'}} src={level2} />
                            <RedNumberList items={L2REQUIREMENTS} />

                        </div>
                    </div>
                    <div className="diversity-pe__columns">
                        <div className="diversity-pe__additional">
                            <div className="diversity-pe__additional--large-red">+</div>
                            <div>
                                An Additional <b>4</b> Programs & Policies<br />
                                You must have <b>1</b> policy from each section:
                                <p>Human Capital & Recruitment, Internal Policy & Culture, Dealflow Sources, and Portfolio Company Guidance</p>
                                <p>List of Programs & Policies Shown Below</p>
                            </div>
                        </div>
                        <div className="diversity-pe__additional">
                            <div className="diversity-pe__additional--large-red diversity-pe__additional--center">+</div>
                            <div>
                                An Additional <b>8</b> Programs & Policies<br />
                                You must have <b>2</b> policies from each section:
                                <p>Human Capital & Recruitment, Internal Policy & Culture, Dealflow Sources, and Portfolio Company Guidance</p>
                                <p>List of Programs & Policies Shown Below</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="diversity-pe__section--light diversity-pe__programs">
                    <div className="diversity-pe__columns">
                        {PROGRAMS.map((program, index) => <ProgramList key={index}{...program} />)}
                    </div>
                    <p>
                        *Participants have twelve months from initial assessment to implement programs marked with an asterisk (*). 
                        Diversio reserves the right to audit to ensure these criteria have been met.
                    </p>
                </div>
            </Card>
        )
    }
}

About.propTypes = {};

export default About;
