import styled from 'styled-components'
import { colors, getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

export const Inclusion = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .recharts-tooltip-wrapper {
        z-index: 2000;
    }
`

export const Content = styled.div`
    flex: 1;
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
`

export const LeftBox = styled.div`
    min-width: 251px;
`

export const RightBox = styled.div`
    position: relative;
    flex: 1;
    width: calc(100% - 250px);
    min-width: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10;
    padding: ${({ theme }) => theme.columnsSpacing.sm.margin}px;

    ${media.lg} {
        padding: ${({ theme }) => theme.columnsSpacing.lg.margin}px;
    }

    .dds-card {
        margin-bottom: 24px;
    }
`

export const CardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ${colors.primaryGray2};
    padding-bottom: 30.5px;
`

export const CardTitle = styled.div`
    display: flex;
    gap: 12px;

    ${getFontStyle('cardHeaderBold')}
`
