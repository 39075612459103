import { ActionTypes } from '../../actions';

const defaultState = {
    filterMap: {},
};


export default (state = defaultState, action) => {
    try {

        switch(action.type) {
            case ActionTypes.LOADED_FILTER_MAP:
                return {
                    ...state,
                    filterMap: action.payload
                }
            case ActionTypes.DONE_SIGNOUT:
                return {
                    ...defaultState
                }

            default:
                return state;

        }

    } catch(error) {
        console.error(`Error in appState reducer: ${ error.message || error.code || error }`, error);
    }

};
