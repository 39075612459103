import { useContext, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { parseYearQuarter } from 'utils'
import queryString from 'query-string'
import _ from 'underscore'

import { FiltersContext } from 'context/filters/filtersContext/FiltersContext'
import { InclusionParams } from 'components/AnalyzeV2/Inclusion/inclusion.types'
import { FILTER_OPTIONS } from 'components/AnalyzeV2/Inclusion/Heatmap/enums'
import { AnalyzeView } from 'types/analyze.enum'

import { useGetCompanyFilters } from './companyFilters/useGetCompanyFilters'
import { useSurveys } from './useSurveys/useSurveys'

type FiltersParams = {
    view: AnalyzeView
    selectedTab?: string
}

export const useFilters = ({ view, selectedTab }: FiltersParams) => {
    const filtersContext = useContext(FiltersContext)

    const { overTime } = useParams<InclusionParams>()

    const history = useHistory()

    const { survey } = useSurveys()

    const apiParams = {
        year: parseYearQuarter(survey),
    }

    const queryGetCompanyFilters = useGetCompanyFilters(apiParams)

    const { resultsOverTime } = filtersContext || {}

    if (!selectedTab) {
        selectedTab = queryGetCompanyFilters.data?.filtersPane.demographics?.[0]?.name || 'target_groups'
    }

    useEffect(() => {
        const params = _.omit(
            {
                ...queryString.parse(location.search),
                ...filtersContext?.selectedFiltersPayload,
            },
            function (value) {
                // W10= is the base64 value of an empty array "[]" which we don't want to send as a query param
                return value === 'W10='
            },
        )

        const search = queryString.stringify(params)

        history.replace({ pathname: location.pathname, search })
    }, [filtersContext?.selectedFiltersPayload, history])

    useEffect(() => {
        const currentSearchParams = new URLSearchParams(location.search)

        if (selectedTab === 'target_groups' && filtersContext?.isFiltersInProfilesActive) {
            currentSearchParams.set('filters_in_profiles', filtersContext.isFiltersInProfilesActive.toString())
        } else {
            currentSearchParams.delete('filters_in_profiles')
        }

        const newSearch = currentSearchParams.toString()

        history.replace({ pathname: location.pathname, search: newSearch })
    }, [filtersContext?.isFiltersInProfilesActive, selectedTab, history])

    useEffect(() => {
        if (resultsOverTime && overTime !== FILTER_OPTIONS.OVER_TIME) {
            if (selectedTab) {
                return history.replace(`/analyze2/${view}/${selectedTab}/${FILTER_OPTIONS.OVER_TIME}${location.search}`)
            }
        } else if (!resultsOverTime && overTime === FILTER_OPTIONS.OVER_TIME) {
            if (selectedTab) {
                return history.replace(`/analyze2/${view}/${selectedTab}${location.search}`)
            }
        }
    }, [resultsOverTime])

    if (filtersContext === undefined) {
        throw new Error('useFilters must be used within an FiltersContextController')
    }

    return filtersContext
}
