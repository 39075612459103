import React, { useCallback, useEffect } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import mixpanel from 'mixpanel-browser'

import { AppRoute } from 'routing/AppRoute.enum'
import { LoginPayload } from 'api/actions/auth/authActions.types'
import { RootState } from 'reducers/rootState.types'
import { Login } from 'components/Auth/Login'
import { useLogin } from 'hooks/auth/useLogin/useLogin'

import { LocalStorageKey } from './../auth.types'
import { RedirectAuthenticatedUser } from './../RedirectAuthenticatedUser'
import { LocationState } from './login.types'

export const LoginContainer = () => {
    const { mfaRequired, mfaDeviceId, isAuthenticated } = useSelector((state: RootState) => state.auth)

    const history = useHistory()

    const { state } = useLocation<LocationState>()

    useEffect(() => {
        if (state?.resetMixpanel) {
            history.replace(location.pathname, '')

            mixpanel.reset()
        }
    }, [])

    const mutationLogin = useLogin()

    const handleSubmit = useCallback(
        async (values: LoginPayload): Promise<boolean> => {
            try {
                history.replace({ pathname: location.pathname, search: location.search }, '')

                const mfaDeviceId = localStorage.getItem(LocalStorageKey.MfaDeviceId)

                if (mfaDeviceId) {
                    values['2fa_device_id'] = mfaDeviceId
                }

                await mutationLogin.mutateAsync(values)

                return true
            } catch (error) {
                return false
            }
        },
        [mutationLogin],
    )

    if (mfaRequired && !mfaDeviceId) {
        return <Redirect to={{ pathname: AppRoute.SendVerificationCode, search: location.search }} />
    }

    if (isAuthenticated) {
        return <RedirectAuthenticatedUser />
    }

    return (
        <Login
            isLoading={mutationLogin.isLoading}
            isSuccess={!!state?.mutation?.isSuccess}
            data={state?.mutation?.data}
            isError={mutationLogin.isError}
            error={mutationLogin.error}
            onSubmit={handleSubmit}
        />
    )
}
