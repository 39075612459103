/** @format */

import { ActionTypes } from "../actions";

const defaultState = {
  events: [],
  tools: [],
  toolsFilters: [],
  pagination: {},
  endOfTools: false,
  isLoading: {
    tools: false
  },
  isLoadingMore: {
    tools: false
  }
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.LOADING_SOLUTION_TOOLS: {
        const loading = {}

        if (action.payload.isLoadingFirstPage) {
          loading.isLoading = {
            tools: true
          }
        } else {
          loading.isLoadingMore = {
            tools: true
          }
        }

        return {
          ...state,
          ...loading
        }
      }
      case ActionTypes.LOADED_SOLUTION_EVENTS:
        return {
          ...state,
          events: action.payload,
        };
      case ActionTypes.LOADED_SOLUTION_TOOLS: {
        const appendedTools = [...state.tools];
        const newTools = action.payload.results;
        const isLoadingFirstPage = !action.payload.previous
        const loading = {}

        for (const i in newTools) {
          appendedTools.push(newTools[i]);
        }

        if (isLoadingFirstPage) {
          loading.isLoading = {
            tools: false
          }
        } else {
          loading.isLoadingMore = {
            tools: false
          }
        }

        return {
          ...state,
          ...loading,
          tools: appendedTools,
          pagination: action.payload,
        };
      }
      case ActionTypes.LOADED_SOLUTION_TOOLS_FILTERS:
        return {
          ...state,
          toolsFilters: action.payload,
        };
      case ActionTypes.RESET_TOOLS:
        return {
          ...state,
          events: [],
          tools: [],
          toolsFilters: [],
          pagination: {},
          endOfTools: false
        };
      case ActionTypes.RESET_TOOL_CHOICES:
        return {
          ...state,
          tools: [],
        };
      case ActionTypes.DONE_SOLUTION_SAVE_TOOL:
      case ActionTypes.DONE_SOLUTION_REMOVE_SAVED_TOOL:
        return {
          ...state,
        };
      case ActionTypes.DO_SOLUTION_MODIFY_TOOL_IN_ROW: {
        const toolID = action.payload.toolID;
        const value = action.payload.value;
        const companyToolId = action.payload.companyToolId;
        const toolsCopy = [...state.tools];

        for (const i in toolsCopy) {
          if (toolsCopy[i].id === toolID) {
            toolsCopy[i].is_saved = value;
            toolsCopy[i].company_tool_id = companyToolId;
            break;
          }
        }
        return {
          ...state,
          tools: toolsCopy,
        };
      }
      case ActionTypes.END_OF_TOOLS:
        return{
          ...state,
          endOfTools: action.payload
        }
      case ActionTypes.RESET_PROGRAMS_AND_SOLUTIONS:
        return {
          ...defaultState
        }
      default:
        return state;
    }
  } catch (error) {
    console.error(
      `Error in appState reducer: ${error.message || error.code || error}`,
      error
    );
  }
};
