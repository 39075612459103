import React from 'react';
import {Column, Row} from "../utils/FlexBox";
import DiversitySnapshot from "./DiversitySnapshot";
import DiversityRoleBreakdown from "./DiversityRoleBreakdown";
import mixpanel from 'mixpanel-browser';
import TAXONOMIES from '../../utils/taxonomies';
import { useUserMetadata } from 'hooks/metadata/useUserMetadata';
import { getActiveCompany } from 'utils';
import { useVersion } from 'hooks/useVersion';
import { Redirect } from 'react-router-dom';
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';


const Diversity = (props) => {
    const { data: userMetadata } = useUserMetadata();
    const versionsContext = useVersion()

    const [steps, setSteps] = React.useState([
        {
            target: '.nav-link.active',
            content: 'The Analyze page contains two tabs: Diversity and Inclusion. The first tab is Diversity, shown here. This page gives you a breakdown of the company makeup.'
        },
        {
            target: '.pie-chart-container',
            content: 'Clicking on these pie charts will let you view different demographic breakdowns.'
        },
        {
            target: '.analyze-diversity-snapshot__detail-box',
            content: 'The breakdowns will pop up here.'
        },
        {
            target: '.filters-container.bootstrap-override',
            content: 'Modifying these filters allow you to adjust what data you are viewing below.',
            placementBeacon: "top",
            placement: "left-start"
        },
        {
            target: '.custom-card',
            content: 'You will quickly find that these panels are all designed with similar filters and charts.',
            placementBeacon: "top",
            placement: "right-start",
        },
        {
            target: '.analyze-nav',
            content: 'Click on Inclusion to explore the inclusion tab!',
            placementBeacon: "left"
        }
        
    ])

    React.useEffect(() => {
        mixpanel.track(TAXONOMIES.SELECT_DIVERSITY_OR_INCLUSION_PAGE, {
            analyzePageName: "Diversity"
        })
    }, []);

    const companyName = getActiveCompany(userMetadata?.companies)?.name

    const isV2Enabled = versionsContext?.version === versionsContext?.AVAILABLE_VERSIONS.v2
    const { enableDebugMode } = props;

    if (isV2Enabled && !enableDebugMode) {
        return <Redirect to={generateCompanyURI("/analyze2/diversity")} />
    }

    return (
        <div>
            <Row>
                <Column grow={1}>
                    <DiversitySnapshot colors={props.colors}
                        ndg={props.analyze.filters.non_dominant_group}
                        composition={props.analyze.diversityComposition}
                        helpText={props.analyze.diversityBreakdownHelpText}
                        breakdown={props.analyze.diversityBreakdown}
                        groups={props.extendedCategories}
                        companyName={companyName}
                        filters={props.analyze.filters}
                        handleApplyFilters={data => {
                            mixpanel.track(TAXONOMIES.USE_FILTER, {
                                name: "Diversity Breakdown"
                            });

                            props.getDiversityBreakdownData({ data })}
                        }
                        showMessage={props.showMessage}
                        profile={props.profile}
                        latestSurveyYear={props.analyze.filters.years?.[0]}
                    />
                </Column>
            </Row>
            <Row>
                <Column grow={1}>
                    <DiversityRoleBreakdown
                        diversityBreakdownByRole={props.analyze.diversityBreakdownByRole}
                        helpText={props.analyze.diversityBreakdownByRole.helpText}
                        companyName={companyName}
                        filters={props.analyze.filters}
                        handleApplyFilters={data => {
                            mixpanel.track(TAXONOMIES.USE_FILTER, {
                                name: "Diversity Breakdown By Role"
                            });
                            props.getDiversityBreakdownByRoleData({ data })}
                        }
                        latestSurveyYear={props.analyze.filters.years?.[0]}
                    />
                </Column>
            </Row>
        </div>
    );
};

export default Diversity;
