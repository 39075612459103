import { PastSurvey, Industry } from 'api/actions/home/pastSurveys/pastSurveysActions.types'

export const parseIndustry = (survey: Industry) => {
    return {
        label: survey.label,
        isPrimary: survey.isPrimary,
        score: survey.score?.toString() || '',
    }
}

export const parseSurvey = (survey: PastSurvey) => {
    return {
        yearQuarterLabel: survey.yearQuarterLabel,
        score: survey.inclusionScore.score?.toString() || '',
        industries: survey.inclusionScore.industries.map(parseIndustry),
    }
}
