import React from 'react';
import {Actions} from '../../actions';
import {connect} from 'react-redux';
import DiversityVC from "../../components/DiversityVC";

const mapStateToProps = (state, ownProps) => {
    return {
        analyze: state.analyze,
        diversityvc: state.diversityvc,
        home: state.home,
        improve: state.improve,
        ...ownProps
    };
};

export default connect(mapStateToProps, Actions)(DiversityVC);
