import styled from 'styled-components'
import { InclusionScoreAnalysisCard as DdsInclusionScoreAnalysisCard } from '@diversioteam/diversio-ds'

export const InclusionScoreAnalysisCard = styled(DdsInclusionScoreAnalysisCard)`
    &.dds-inclusion-score-analysis-card {
        z-index: 99;

        .recharts-tooltip-wrapper {
            margin-top: 35px;
        }

        &:before {
            content: '';
            height: 100%;
            width: 100%;
            background: white;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(60, 36, 120, 0.2);
            backdrop-filter: blur(5px);
            z-index: 1;
            display: none;
        }

        &--expanded {
            width: calc(100vw - 473px);

            &:before {
                display: block;
            }
        }

        .dds-inclusion-score-analysis-card__right-block,
        .dds-inclusion-score-analysis-card__left-block {
            z-index: 10;
        }
    }
`
