import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import mixpanel from 'mixpanel-browser'
import { GrowthBookProvider } from '@growthbook/growthbook-react'
import { ThemeProvider } from 'styled-components'
import * as Sentry from '@sentry/react'
import { useQueryClient } from '@tanstack/react-query'
import { Router } from 'react-router-dom'

import { GlobalStyle } from 'styles/globalStyle'
import { Queries } from 'api/queries.enum'
import { BreadcrumbsContextController } from 'context/breadcrumbs/breadcrumbsContextController/BreadcrumbsContextController'
import { VersionsContextController } from 'context/versions/versionsContextController/VersionsContextController'
import { theme } from 'styles/theme/theme'
import { setupInterceptors } from 'api/axios/setupInterceptors'
import { UrlStateContextController } from 'context/urlState/urlStateContextController/UrlStateContextController'
import { CheckSentryController } from 'hoc/CheckSentryController'

import history from './history'
import Routes from './routes'
import GlobalMessage from './containers/GlobalMessage'
import { featureFlagger } from './featureFlagger'
import { AppProps } from './App.types'

const App = ({ store }: AppProps) => {
    const queryClient = useQueryClient()
    const mixpanelApiKey = process.env.REACT_APP_MIXPANEL

    setupInterceptors(store)

    document.addEventListener('visibilitychange', (e) => {
        if (document.visibilityState === 'visible') {
            queryClient.refetchQueries({
                queryKey: [Queries.getUserMetadata],
            })
        }
    })

    if (mixpanelApiKey) {
        mixpanel.init(mixpanelApiKey)
    }

    const ERRORS_IGNORE_LIST = ['ResizeObserver']

    useEffect(() => {
        // Load features from the GrowthBook API and initialize the SDK
        featureFlagger.loadFeatures()
    }, [])

    if (process.env.NODE_ENV === 'development') {
        window.onerror = (message, url, line) => {
            const shouldIgnore = ERRORS_IGNORE_LIST.filter((ignoreMessage) => {
                if (typeof message === 'string') {
                    return message.indexOf(ignoreMessage) > -1
                }
            })?.[0]

            if (!shouldIgnore) {
                alert(`${message} - ${line}`)
            }
        }
    }

    return (
        <Provider store={store}>
            <VersionsContextController>
                <ThemeProvider theme={theme}>
                    <Router history={history}>
                        <CheckSentryController>
                            <UrlStateContextController>
                                <BreadcrumbsContextController>
                                    <GrowthBookProvider growthbook={featureFlagger}>
                                        <React.Fragment>
                                            <GlobalStyle />
                                            <Routes />
                                            <GlobalMessage />
                                        </React.Fragment>
                                    </GrowthBookProvider>
                                </BreadcrumbsContextController>
                            </UrlStateContextController>
                        </CheckSentryController>
                    </Router>
                </ThemeProvider>
            </VersionsContextController>
        </Provider>
    )
}

export default Sentry.withProfiler(App)
