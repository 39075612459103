import { endpoints } from './../../endpoints'
import { diversioApi } from './../../axios/axiosInstance'
import {
    AuthResponse,
    InitializeSsoPayload,
    InitializeSsoResponse,
    LoginPayload,
    LoginResponse,
    RequestPasswordResetPayload,
    RequestVerificationCodePayload,
    ResetPasswordPayload,
    SsoCallbackPayload,
    ValidateVerificationCodePayload,
} from './authActions.types'

export const loginAction = async (values: LoginPayload): Promise<LoginResponse> => {
    const response = await diversioApi.post(endpoints.auth.login, values)

    return response.data
}

export const requestPasswordResetAction = async (values: RequestPasswordResetPayload): Promise<AuthResponse> => {
    const response = await diversioApi.post(endpoints.auth.requestPasswordReset, values)

    return response.data
}

export const resetPasswordAction = async (values: ResetPasswordPayload): Promise<AuthResponse> => {
    const response = await diversioApi.post(endpoints.auth.resetPassword, values)

    return response.data
}

export const initializeSsoAction = async (values: InitializeSsoPayload): Promise<InitializeSsoResponse> => {
    const response = await diversioApi.post(endpoints.auth.initializeSso, values)

    return response.data
}

export const ssoCallbackAction = async (values: SsoCallbackPayload): Promise<LoginResponse> => {
    const response = await diversioApi.post(endpoints.auth.ssoCallback, values)

    return response.data
}

export const requestVerificationCodeAction = async (values: RequestVerificationCodePayload): Promise<AuthResponse> => {
    const response = await diversioApi.post(endpoints.auth.requestVerificationCode, values)

    return response.data
}

export const validateVerificationCodeAction = async (
    values: ValidateVerificationCodePayload,
): Promise<LoginResponse> => {
    const response = await diversioApi.post(endpoints.auth.validateVerificationCode, values)

    return response.data
}
