import {Actions} from '../../actions';
import {connect} from 'react-redux';
import NextSteps from "../../components/DiversityVC/NextSteps";

const mapStateToProps = (state, ownProps) => {
    return {
        diversityvc: state.diversityvc,
        home: state.home,
        improve: state.improve,
        ...ownProps
    };
};

export default connect(mapStateToProps, Actions)(NextSteps);
