import styled from 'styled-components'
import { Card as DdsCard, colors, getFontStyle } from '@diversioteam/diversio-ds'

import cardBgShadow from 'images/cardBgShadow.png'

export const Reporting = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1080px;
    width: 85%;
    padding: 40px 0;
    margin: 0 auto;
`

export const Card = styled(DdsCard)`
    && {
        min-height: 260px;
        flex-direction: row;
        align-items: center;
        padding: 0 0 0 40px;
        background: url(${cardBgShadow});
        background-repeat: no-repeat;
        background-size: cover;
    }
`

export const CardTitle = styled.h2`
    margin: 0 0 12px;
    color: ${colors.primary20};

    ${getFontStyle('h1Bold')}
`

export const CardDescription = styled.h2`
    color: ${colors.primary30};

    ${getFontStyle('p02Regular')}
`

export const Filters = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 30px 0;
`

export const Reports = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    width: 100%;
    gap: 18px;
    margin-bottom: 10px;
`

export const ToolCardWrapper = styled.div`
    flex: 1 0 calc(25% - 10px);
    min-width: 300px;
    max-width: 342px;
`

export const LoadMore = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 100%;
    margin-top: 18px;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 6px 12px 30px rgba(0, 0, 0, 0.06);
`
