/** @format */

import { ActionTypes } from "../actions";

const defaultState = {
  trainings: [],
  pagination: {},
  endOfTrainings: false,
  isLoading: {
    trainings: false
  },
  isLoadingMore: {
    trainings: false
  }
};

export default (state = defaultState, action) => {
  try {
    switch (action.type) {
      case ActionTypes.LOADING_SOLUTION_TRAININGS: {
        const loading = {}

        if (action.payload.isLoadingFirstPage) {
          loading.isLoading = {
            trainings: true
          }
        } else {
          loading.isLoadingMore = {
            trainings: true
          }
        }

        return {
          ...state,
          ...loading
        }
      }
      case ActionTypes.LOADED_SOLUTION_TRAININGS: {
        const appendedTrainings = [...state.trainings];
        const newTrainings = action.payload.results;
        const isLoadingFirstPage = !action.payload.previous
        const loading = {}

        for (const i in newTrainings) {
          appendedTrainings.push(newTrainings[i]);
        }

        if (isLoadingFirstPage) {
          loading.isLoading = {
            trainings: false
          }
        } else {
          loading.isLoadingMore = {
            trainings: false
          }
        }

        return {
          ...state,
          ...loading,
          trainings: appendedTrainings,
          pagination: action.payload,
        };
      }
      case ActionTypes.RESET_TRAININGS:
        return {
          ...state,
          trainings: [],
          pagination: {},
          endOfTrainings: false
        };
      case ActionTypes.DONE_SOLUTION_SAVE_TRAINING:
      case ActionTypes.DONE_SOLUTION_REMOVE_SAVED_TRAINING:
        return {
          ...state,
        };
      case ActionTypes.DO_SOLUTION_MODIFY_TRAINING_IN_ROW: {
        const trainingID = action.payload.trainingID;
        const value = action.payload.value;
        const companyTrainingId = action.payload.companyTrainingId;
        const trainingsCopy = [...state.trainings];

        for (const i in trainingsCopy) {
          if (trainingsCopy[i].id === trainingID) {
            trainingsCopy[i].is_saved = value;
            trainingsCopy[i].company_tool_id = companyTrainingId;
            break;
          }
        }
        return {
          ...state,
          trainings: trainingsCopy,
        };
      }
      case ActionTypes.END_OF_TRAININGS:
        return{
          ...state,
          endOfTrainings: action.payload
        }
      case ActionTypes.RESET_PROGRAMS_AND_SOLUTIONS:
        return {
          ...defaultState
        }
      default:
        return state;
    }
  } catch (error) {
    console.error(
      `Error in appState reducer: ${error.message || error.code || error}`,
      error
    );
  }
};
