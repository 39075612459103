import React, {Component} from 'react';
import "./index.scss";
import Select from '../../utils/Select';
import Loader from "../../utils/Loader";
import _, { filter } from 'underscore';
import equal from 'fast-deep-equal';

import MainCard from '../../utils/MainCard';
import * as m from '../../../translations/mapping';
import {getTranslation, mapT} from '../../../translations/utils';
import { withTranslation } from 'react-i18next';

import { MOCK_INDUSTRY_DATA_2, MOCK_DATA } from './mockData';
import { IDATA_SUFFIX } from './constants';

import DiversityLineChart from './DiversityLineChart';
import Legend from './Legend';
import { getIndustryCompType } from '../../../utils';

import Filters, { Year, Office, Level, Department, Tenure, FilterContext, FiltersSelected } from '../Filters';

import {getOfficeRegionLabel, getDepartmentLabel, getTenureLabel, getLevelLabel, getYearLabel} from "../../../utils/labels";
import mixpanel from "mixpanel-browser";
import TAXONOMIES from '../../../utils/taxonomies';
import { isMila } from 'utils/companyChecks';
import { getYearAliasMap } from '../Filters/utils';

class DiversityRoleBreakdown extends Component {
    state = {
        selectedGroups: [],
        selectedFilters: {},
        demographics: {},
        data: [],
        idata: [],
        dataFilled: [],
        idataFilled: [],
        availableGroups: [],
        availableRoles: [],
        showIndustry: false,
        filterApplied: false,
        triggerReRender: false,
    };

    onChange = (selectedValues) => {
        this.setState({
            selectedGroups: selectedValues
        });
    }

    componentDidMount() {
        this.loadData();
    }

    cleanPreviousGroups(prevGroups) {
        // Clean out previously selected groups which are no longer selectable
        const currentGroups = this.state.availableGroups || [];
        const cleanedGroups = prevGroups.filter(g => currentGroups.includes(g));
        if (_.isEmpty(cleanedGroups)) {
            // cleaned list is empty, so select the first item from this.availableGroups
            return [
                this.availableGroups[0] || ""
            ]
        } else {
            return cleanedGroups;
        }
    }

    // If user navigates to this card before the data is available,
    // we need to update state again with the new incoming props
    // bc componentDidMount only runs once
   async componentDidUpdate(prevProps, prevState) {
        if (this.props.diversityBreakdownByRole.data) {
            if(!equal(this.props.diversityBreakdownByRole.data,
                prevProps.diversityBreakdownByRole.data))
            {
                await this.loadData();

                // prevState.filterApplied is a boolean flag that is set to true each time the filter is applied.
                // Since each time the filter is applied it causes a re-render, the group selected
                // was defaulting back to just the initial selection.
                // We use this code to re-apply the previously selected filters.

                // However note that dominant groups can change between surveys,
                // So we must unselect the ones that are no longer valid.
                // If all values get unselected then we select the first item in the list of available groups.

                if (prevState.filterApplied) {
                    const cleanGroups = this.cleanPreviousGroups(prevState?.selectedGroups || []);

                    this.setState({
                        selectedGroups: [...cleanGroups],
                        filterApplied: false,
                        triggerReRender: !this.state.triggerReRender, // see note on line 200
                    })
                }
            }
        }
    }

    loadData = async () => {

        const { diversityBreakdownByRole: { data, labels, loading } } = this.props;
        const industryData = MOCK_INDUSTRY_DATA_2;
        const parsedIndustryData = this._parseData(industryData, labels, true);
        // parse data last so that available roles and groups are set on the basis of the
        // actual data, not the industry data
        const parsedData = await this._parseData(data, labels);

        await this.setState({
            data: parsedData,
            idata: parsedIndustryData,
            availableRoles: this.availableRoles,
            availableGroups: this.availableGroups,
        })
    }

    _makeMtnmList (parsedData) {
        if (!parsedData) {
            return null;
        }

        let mtnmList = {};
        for (const [i, data] of parsedData.entries()) {
            mtnmList[i] = [];
            for (const cat of Object.keys(data)) {
                if (data[cat] == null) {
                    mtnmList[i].push(cat);
                }
            }
        }
        return mtnmList;
    }

    _parseData (data, labels, isInd=false) {
        if (!data || !Object.keys(data).length) { return null; }

        if (!isInd) {
            this.availableGroups = Object.keys(data);
            const selectedGroup = Object.keys(data)[0];
            this.availableRoles = Object.keys(data[selectedGroup]) || [];
        } else {
            this.availableGroups = [];
            this.availableRoles = [];
        }

        return this.availableRoles.filter(role => !!labels[role]).map(role => {
            return {
                role: labels[role].split(',')[0],
                label: `${labels[role]}${isInd ? IDATA_SUFFIX : ""}`,
                ...this.availableGroups.reduce((segregationByGroup, group) => {
                    segregationByGroup = {
                        ...segregationByGroup,
                        [group]: data[group][role]
                    };
                    return segregationByGroup;
                }, {})
            }
        });
    }

    handleSwitch = () => {
        this.setState({
            showIndustry: !this.state.showIndustry
        });
    }

    labelMapper = (value) => {
        return mapT(m.GROUP_DOM_NONDOM, value);
    }

    track (diversityOverviewBreakdownName) {
        mixpanel.track(TAXONOMIES.DIVERSITY_ROLE_GRANULAR_BREAKDOWN, {
            diversityOverviewBreakdownName
        })
    }

    render() {
        const { diversityBreakdownByRole: { data, labels, loading },
                t,
                companyName, helpText } = this.props;


        if (isMila(companyName)) {
            return null;
        }

        const filtersComponent = (
            <Filters
                filters={this.props.filters}
                applyFilters={this.props.handleApplyFilters}
                filterWasApplied={() => this.setState({filterApplied: true})}
                yearAliasMap={getYearAliasMap(companyName)}
                latestSurveyYear={this.props.latestSurveyYear}
            >
                <Year onOpen={() => this.track("Year")} label={getYearLabel(companyName)} />
                <Office label={getOfficeRegionLabel(companyName)} onOpen={() => this.track("Office")}/>
                <Level onOpen={() => this.track("Level")} label={getLevelLabel(companyName)}/>
                <Department label={getDepartmentLabel(companyName)} onOpen={() => this.track("Department")}/>
                <Tenure label={getTenureLabel(companyName)} onOpen={() => this.track("Tenure")}/>
                {
                    <FilterContext.Consumer>
                        {data => {
                            if (this.state.selectedFilters !== data.values) {
                                this.setState({
                                    ...this.state,
                                    selectedFilters: data.values,
                                })
                            }
                        }}
                    </FilterContext.Consumer>
                }
            </Filters>
        );

        let content;

        if (loading) {
            content =  (<div className="role-breakdown"><Loader inline={true}/>Loading...</div>)
        } else {
            if (_.isEmpty(this.state.selectedGroups) && (this.state.availableGroups && this.state.availableGroups[0])
        ) {
            this.setState({ selectedGroups: [this.state.availableGroups[0]] })
        }



            content = (
                <>
            <div className="role-breakdown">
                <div className="role-breakdown__filter">
                        {/*
                        From @sometimescasey
                        For some reason the Select component does not re-render immediately
                        when 'this.state.selectedGroups' changes. There is a delay of a few seconds.
                        So we manually flip the value on "key" in order to trigger a re-render.
                        */}
                        <Select
                            key={this.state.triggerReRender}
                            multiple={true}
                            options={this.state.availableGroups}
                            selectedValues={this.state.selectedGroups}
                            label={t("ANALYZE.FILTERS.GROUP")}
                            onChange={this.onChange}
                            renderValue={selected => selected.map(this.labelMapper).join(', ')}
                            mapChoiceLabel={this.labelMapper}
                            extraClasses="role-breakdown__demographic-filters"
                        />
                </div>
                <div className="role-breakdown__pre-chart">
                    <div className="role-breakdown__subtitle">
                        {getTranslation('ANALYZE.DIVERSITY_BREAKDOWN_BY_ROLE.SUBTITLE', this.props.companyName)}
                    </div>
                    {/* <div className="role-breakdown__industry-toggle-wrapper">
                    <FormGroup row className="role-breakdown__industry-toggle">
                        <FormControlLabel
                            control={<Switch
                            checked={this.state.showIndustry}
                            onChange={this.handleSwitch}
                            disableRipple
                            name="industrySwitch" />}
                            label={`${t('ANALYZE.DIVERSITY_BREAKDOWN_BY_ROLE.SHOW')} ${t(getIndustryCompType(this.props.companyName))} ${t("AVERAGE")}`}
                        />
                    </FormGroup>
                    </div> */}
                </div>
                <div className="role-breakdown__chart">
                    <DiversityLineChart
                    data={this.state.data}
                    idata={this.state.idata}
                    showIndustry={this.state.showIndustry}
                    label={labels}
                    selected={this.state.selectedGroups}
                    groups={this.state.availableGroups}
                    mtnmList={this._makeMtnmList(this.state.data)}
                    companyName={this.props.companyName} />
                </div>
                <div className="role-breakdown__legend-wrapper">
                    <Legend
                        selected={this.state.selectedGroups}
                        groups={this.state.availableGroups}/>
                </div>
                <FiltersSelected
                    values={this.state.selectedFilters}
                    allFilters={this.props.filters}
                    companyName={this.props.companyName}
                    />
            </div>
                </>
                )

        }

        const hasRoles = this.state.data != null

        return (
            <div>
                {
                    hasRoles &&
                        <MainCard 
                            title={t("ANALYZE.CARD_HEADERS.DIVERSITY_BREAKDOWN_BY_ROLE.title")}
                            cypressId={"diversity-breakdown-by-role"}
                            toolTipContent={helpText}
                        >
                            {filtersComponent}
                            {content}
                        </MainCard>
                }
            </div>
        )
    }
}

export default withTranslation()(DiversityRoleBreakdown);
