/* Mapping file to link English string payload values from backend
to JSON keys for translation. */

// Home
//// DiversityComposition

export const GROUP = {
    "Gender": "GROUP_TYPES.GENDER",
    "Ethnicity":  "GROUP_TYPES.ETHNICITY",
    "Sexual Orientation":  "GROUP_TYPES.SEXUAL_ORIENTATION",
    "Disability":  "GROUP_TYPES.DISABILITY",
}

export const GROUP_MISC = {
    "Prefer not to answer": "GROUP_MISC.PNTS"
}

export const GROUP_BREAKDOWN = {
    "Male": "GROUP.GENDER.MALE",
    "Male+": "GROUP.GENDER.MALE_NONDOM",
    "Female": "GROUP.GENDER.FEMALE",
    "Female+": "GROUP.GENDER.FEMALE_NONDOM",
    "Intersex": "GROUP.GENDER.INTERSEX",
    "Two-Spirit": "GROUP.GENDER.TWO_SPIRIT",
    'Gender Identity': 'GROUP.GENDER.IDENTITY',
    'Racial & Ethnic Background': 'GROUP.GENDER.ETHNIC_BACKGROUND',
    'POC': "GROUP.ETHNICITY.NONDOM",
    // ETHNICITY
    "Asian": "GROUP.ETHNICITY.ASIAN",
    "Black": "GROUP.ETHNICITY.BLACK",
    "Indigenous": "GROUP.ETHNICITY.INDIGENOUS",
    "Latin / Hispanic": "GROUP.ETHNICITY.LATIN_HISPANIC",
    "Middle Eastern": "GROUP.ETHNICITY.MIDDLE_EASTERN",
    "Jewish": "GROUP.ETHNICITY.JEWISH",
    "Mixed Race": "GROUP.ETHNICITY.MIXED_RACE",
    // SEXUAL_ORIENTATION
    'Gay': 'GROUP.SEXUAL_ORIENTATION.GAY',
    'Asexual': 'GROUP.SEXUAL_ORIENTATION.ASEXUAL',
    'Bisexual': 'GROUP.SEXUAL_ORIENTATION.BISEXUAL',
    'Other': 'GROUP.SEXUAL_ORIENTATION.OTHER',
    'Queer': 'GROUP.SEXUAL_ORIENTATION.QUEER',
    'Pansexual': 'GROUP.SEXUAL_ORIENTATION.PANSEXUAL',
    'Lesbian': 'GROUP.SEXUAL_ORIENTATION.LESBIAN',
    'Transgender(yes)': 'GROUP.SEXUAL_ORIENTATION.TRANSGENDER',
    'Sexual Identity': 'GROUP.SEXUAL_ORIENTATION.SEXUAL_IDENTITY',
    // DISABILITY
    'People with Physical disability': 'GROUP.DISABILITY.PHYSICAL',
    'People with Mental health issue': 'GROUP.DISABILITY.MENTAL',
    'People with Cognitive disability': 'GROUP.DISABILITY.COGNITIVE',
    'People with multiple disabilities': 'GROUP.DISABILITY.MULTIPLE',
    "People with disabilities": "GROUP.DISABILITY.NONDOM",
    "Persons with disability": "GROUP.DISABILITY.NONDOM",
    "People without disabilities": "GROUP.DISABILITY.DOM",
    "Persons without disability": "GROUP.DISABILITY.DOM",
    'Gender Fluid': "GROUP.SEXUAL_ORIENTATION.GENDER_FLUID",
    'Non-Binary': "GROUP.SEXUAL_ORIENTATION.NON_BINARY",
    'Prefer not to answer': "GROUP_MISC.PNTS",
    'Inter*': "GROUP.SEXUAL_ORIENTATION.INTER",
    'White': 'GROUP.ETHNICITY.WHITE',
    'Person of Color': "GROUP.ETHNICITY.PERSON_OF_COLOR",
    'Native / Indigenous': "GROUP.ETHNICITY.NATIVE_INDIGENOUS",
    'Dominant Group': "ANALYZE.HEATMAP.SIDE_HEADER.DOMINANT_GROUP",
    'LGBTQ+': "GROUP.SEXUAL_ORIENTATION.NONDOM",
    'Racial & Ethnic Minorities': "GROUP.ETHNICITY.NONDOM"
}

export const TARGET_GROUP = {
    "Female+": "Women and Non-Binary Individuals",
    "Male+": "Men and Non-Binary Individuals",
    "Racial & Ethnic Minorities": "Racial and Ethnic Minorities",
    "People with disabilities": "People With Physical / Cognitive / Mental Conditions",
    "People without disabilities": "People Without Physical / Cognitive / Mental Conditions",
    "LGBTQ+": "LGBTQ2+ Individuals",
    "Heterosexual": "Heterosexual Individuals",
}

export const GROUP_DOM_NONDOM = {
    "Male": "GROUP.GENDER.DOM",
    "Female": "GROUP.GENDER.NONDOM",
    "Male+": "GROUP.GENDER.MALE_NONDOM",
    "Female+": "GROUP.GENDER.FEMALE_NONDOM",
    'Transgender(yes)': 'GROUP.SEXUAL_ORIENTATION.TRANSGENDER',
    "White": "GROUP.ETHNICITY.DOM",
    "POC": "GROUP.ETHNICITY.NONDOM",
    "Heterosexual": "GROUP.SEXUAL_ORIENTATION.DOM",
    "LGBTQ+": "GROUP.SEXUAL_ORIENTATION.NONDOM",
    "LGBTQ2+": "GROUP.SEXUAL_ORIENTATION.NONDOM2",
    "People with disabilities": "GROUP.DISABILITY.NONDOM",
    "Persons with disability": "GROUP.DISABILITY.NONDOM",
    "People without disabilities": "GROUP.DISABILITY.DOM",
    "Persons without disability": "GROUP.DISABILITY.DOM",
    "Prefer not to answer": "GROUP_MISC.PNTS",
    "Dominant Group": "ANALYZE.HEATMAP.SIDE_HEADER.DOMINANT_GROUP"
}

// Home
//// HeatMap

export const METRIC = {
    "Inclusive Culture": "METRIC.INCLUSIVE_CULTURE",
    "Fair Management": "METRIC.FAIR_MANAGEMENT",
    "Career Development": "METRIC.CAREER_DEVELOPMENT",
    "Workplace Flexibility": "METRIC.WORKPLACE_FLEXIBILITY",
    "Workplace Safety": "METRIC.WORKPLACE_SAFETY",
    "Recruiting & Hiring": "METRIC.RECRUITING_HIRING",
}

export const METRIC_TO_TOOLTIP = {
    "Inclusive Culture": "HOME.HEATMAP.TOOLTIPS.INCLUSIVE_CULTURE",
    "Fair Management": "HOME.HEATMAP.TOOLTIPS.FAIR_MANAGEMENT",
    "Unbiased Community": "HOME.HEATMAP.TOOLTIPS.FAIR_MANAGEMENT",
    "Career Development": "HOME.HEATMAP.TOOLTIPS.CAREER_DEVELOPMENT",
    "Workplace Flexibility": "HOME.HEATMAP.TOOLTIPS.WORKPLACE_FLEXIBILITY",
    "Flexibility": "HOME.HEATMAP.TOOLTIPS.WORKPLACE_FLEXIBILITY",
    "Workplace Safety": "HOME.HEATMAP.TOOLTIPS.WORKPLACE_SAFETY",
    "Safe Environment": "HOME.HEATMAP.TOOLTIPS.WORKPLACE_SAFETY",
    "Recruiting & Hiring": "HOME.HEATMAP.TOOLTIPS.RECRUITING_HIRING",
    "Member onboarding": "HOME.HEATMAP.TOOLTIPS.RECRUITING_HIRING",
}

export const HEATMAP_SIDEBAR = {
    "Industry Average": "HOME.HEATMAP.SIDE_HEADER.INDUSTRY_AVERAGE",
    "Your KPI score": "HOME.HEATMAP.SIDE_HEADER.YOUR_KPI"
}

export const ANALYZE_HEATMAP_SIDEBAR = {
    "Your KPI score": "HOME.HEATMAP.SIDE_HEADER.YOUR_KPI",
    "Dominant group": "ANALYZE.HEATMAP.SIDE_HEADER.DOMINANT_GROUP",
    "People with disabilities": "GROUP.DISABILITY.NONDOM",
    "People without disabilities": "GROUP.DISABILITY.DOM",
    "LGBTQ+": "GROUP.SEXUAL_ORIENTATION.NONDOM",
    "Women": "GROUP.GENDER.NONDOM",
    "Male+": "GROUP.GENDER.MALE_NONDOM",
    "Female+": "GROUP.GENDER.FEMALE_NONDOM",
    "Female": "GROUP.GENDER.NONDOM",
    "POC": "GROUP.ETHNICITY.NONDOM",
    "Industry Average": "HOME.HEATMAP.SIDE_HEADER.INDUSTRY_AVERAGE",
}

export const HEATMAP_TOOLTIPS = {
    "An environment where everyone can share their perspective regardless of appearance or background.": "HOME.HEATMAP.TOOLTIPS.INCLUSIVE_CULTURE",
    "A review and promotion process that is objective, fair, and free from unconscious bias.": "HOME.HEATMAP.TOOLTIPS.FAIR_MANAGEMENT",
    "Having access to formal and informal networks without regard to appearance or background.": "HOME.HEATMAP.TOOLTIPS.CAREER_DEVELOPMENT",
    "Enabling parents and other caregivers to balance work and home care obligations.": "HOME.HEATMAP.TOOLTIPS.WORKPLACE_FLEXIBILITY",
    "A working environment that is free from sexual, physical and mental harassment.": "HOME.HEATMAP.TOOLTIPS.WORKPLACE_SAFETY",
    "Recruiting and Hiring": "HOME.HEATMAP.TOOLTIPS.RECRUITING_HIRING",
    "Recruiting & Hiring": "HOME.HEATMAP.TOOLTIPS.RECRUITING_HIRING"
}

export const HEATMAP_SIDE_TOOLTIPS = {
    "The average score for organizations in your industry per KPI": "HOME.HEATMAP.SIDETOOLTIPS.INDUSTRY",
    "A measure of your organization’s performance per KPI based on survey data": "HOME.HEATMAP.SIDETOOLTIPS.YOUR_SCORE",
}

export const FOCUS_AREA_NAME = {
    "Career Development": "METRIC.CAREER_DEVELOPMENT",
    "Fair Management": "METRIC.FAIR_MANAGEMENT",
    "Workplace Safety": "METRIC.WORKPLACE_SAFETY",
    "Inclusive Culture": "METRIC.INCLUSIVE_CULTURE",
    "Workplace Flexibility": "METRIC.WORKPLACE_FLEXIBILITY",
    "Recruiting & Hiring": "METRIC.RECRUITING_HIRING"
}

export const FOCUS_AREA_SUBLABEL = {
    "Career Development": "HOME.HEATMAP.TOOLTIPS.CAREER_DEVELOPMENT",
    "Fair Management": "HOME.HEATMAP.TOOLTIPS.FAIR_MANAGEMENT",
    "Workplace Safety": "HOME.HEATMAP.TOOLTIPS.WORKPLACE_SAFETY",
    "Inclusive Culture": "HOME.HEATMAP.TOOLTIPS.INCLUSIVE_CULTURE",
    "Workplace Flexibility": "HOME.HEATMAP.TOOLTIPS.WORKPLACE_FLEXIBILITY",
    "Recruiting & Hiring": "HOME.HEATMAP.TOOLTIPS.RECRUITING_HIRING"
}

// Analyze
//// Diversity Composition by Role

export const ROLE = {
    "Individual Contributor": "ANALYZE.ROLES.ANALYST",
    "Associate": "ANALYZE.ROLES.ASSOCIATE",
    "People Manager or Management": "ANALYZE.ROLES.MANAGER",
    "Vice President": "ANALYZE.ROLES.VICE_PRESIDENT",
    "Executive": "ANALYZE.ROLES.EXECUTIVE"
}

export const FILTER_LABELS = {
    "Office / Region": "ANALYZE.FILTERS.OFFICE",
    "year": "ANALYZE.FILTERS.YEAR",
    "office": "ANALYZE.FILTERS.OFFICE",
    "level": "ANALYZE.FILTERS.LEVEL",
    "department": "ANALYZE.FILTERS.DEPARTMENT",
    "tenure": "ANALYZE.FILTERS.TENURE",
    "overview": "ANALYZE.FILTERS.OVERVIEW",
    "location": "ANALYZE.FILTERS.LOCATION",
    "company": "ANALYZE.FILTERS.COMPANY",
    "country": "ANALYZE.FILTERS.COUNTRY",
    "practice": "ANALYZE.FILTERS.PRACTICE",
    "status": "ANALYZE.FILTERS.STATUS",
    "industry": "ANALYZE.FILTERS.INDUSTRY",
    "organization": "ANALYZE.FILTERS.ORGANIZATION",
    "generation": "ANALYZE.FILTERS.GENERATION",
    "agebucket": "ANALYZE.FILTERS.AGE_BUCKET",
    "team": "ANALYZE.FILTERS.TEAM",
    "sub department": "ANALYZE.FILTERS.SUB_DEPARTMENT",
    "age": "ANALYZE.FILTERS.AGE",
    "businessArea": "ANALYZE.FILTERS.BUSINESS_AREA",
    "employeeStatus": "ANALYZE.FILTERS.EMPLOYEE_STATUS",
    "completedStatus": "ANALYZE.FILTERS.COMPLETED_STATUS",
    "lastRecruitingStage": "ANALYZE.FILTERS.LAST_RECRUITING_STAGE",
    "jobClass": "ANALYZE.FILTERS.JOB_CLASS"
}

// Improve
 export const KPI_CODES = {
    "DEALFLOW_SOURCES": "KPIS.DEALFLOW_SOURCES",
    "HUMAN_RESOURCES": "KPIS.HUMAN_RESOURCES",
    "INCLUSIVE_CULTURE": "KPIS.INCLUSIVE_CULTURE",
    "INTERNAL_POLICY": "KPIS.INTERNAL_POLICY",
    "PORTFOLIO_GUIDANCE": "KPIS.PORTFOLIO_GUIDANCE",
    "RECRUITING_HIRING": "KPIS.RECRUITING_HIRING"
 }

export const EMPLOYEE_FEEDBACK_CATEGORIES = {
    "All": "ANALYZE.FEEDBACK.CATEGORIES.ALL",
    "Transparency": "ANALYZE.FEEDBACK.CATEGORIES.TRANSPARENCY",
    "Employee Engagement": "ANALYZE.FEEDBACK.CATEGORIES.EMPLOYEE_ENGAGEMENT",
    "Organizational Diversity": "ANALYZE.FEEDBACK.CATEGORIES.ORGANIZATIONAL_DIVERSITY",
    "Team Building": "ANALYZE.FEEDBACK.CATEGORIES.TEAM_BUILDING",
    "Adequate Resourcing": "ANALYZE.FEEDBACK.CATEGORIES.ADEQUATE_RESOURCING",
    "Feedback Culture": "ANALYZE.FEEDBACK.CATEGORIES.FEEDBACK_CULTURE",
    "Training": "ANALYZE.FEEDBACK.CATEGORIES.TRAINING",
    "Career Development": "ANALYZE.FEEDBACK.CATEGORIES.CAREER_DEVELOPMENT",
    "Hiring / Recruiting Bias": "ANALYZE.FEEDBACK.CATEGORIES.HIRING_RECRUITING_BIAS",
    "No Pain Point": "ANALYZE.FEEDBACK.CATEGORIES.NO_PAIN_POINT",
    "Remote Work": "ANALYZE.FEEDBACK.CATEGORIES.REMOTE_WORK",
    "Empathy Building": "ANALYZE.FEEDBACK.CATEGORIES.EMPATHY_BUILDING",
    "Biased Management": "ANALYZE.FEEDBACK.CATEGORIES.BIASED_MANAGEMENT",
    "Time Off": "ANALYZE.FEEDBACK.CATEGORIES.TIME_OFF",
    "Networking": "ANALYZE.FEEDBACK.CATEGORIES.NETWORKING",
    "Recourse": "ANALYZE.FEEDBACK.CATEGORIES.RECOURSE",
    "Leadership Diversity": "ANALYZE.FEEDBACK.CATEGORIES.LEADERSHIP_DIVERSITY",
    "Toxic Leadership": "ANALYZE.FEEDBACK.CATEGORIES.TOXIC_LEADERSHIP",
    "Inclusive Leadership": "ANALYZE.FEEDBACK.CATEGORIES.INCLUSIVE_LEADERSHIP",
    "Nepotism/Favouritism": "ANALYZE.FEEDBACK.CATEGORIES.NEPOTISM_FAVOURITISM",
    "Mental Health": "ANALYZE.FEEDBACK.CATEGORIES.MENTAL_HEALTH",
    "Pay Equity": "ANALYZE.FEEDBACK.CATEGORIES.PAY_EQUITY",
    "Parental Leave": "ANALYZE.FEEDBACK.CATEGORIES.PARENTAL_LEAVE",
    "Traning": "ANALYZE.FEEDBACK.CATEGORIES.TRAINING",
    "Retaliation": "ANALYZE.FEEDBACK.CATEGORIES.RETALIATION",
    "Employee Recognition": "ANALYZE.FEEDBACK.CATEGORIES.EMPLOYEE_RECOGNITION",
    "Discrimination": "ANALYZE.FEEDBACK.CATEGORIES.DISCRIMINATION",
    "Mentorship": "ANALYZE.FEEDBACK.CATEGORIES.MENTORSHIP",
    "Harassment": "ANALYZE.FEEDBACK.CATEGORIES.HARASSMENT",
}

export const HOME_EMPLOYEE_SURVEYED = {
    "title": "HOME.CARD_HEADERS.EMPLOYEES_SURVEYED.title"
}
