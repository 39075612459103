import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import sent from '../../../assets/images/utils/sent.png';
import './index.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class ConfirmationModal extends Component {
    render() {
        return (
            <Dialog
                open={this.props.isOpen}
                TransitionComponent={Transition}
                keepMounted
                aria-labelledby="modal-title"
                aria-describedby="modal-content"
                fullWidth={true}
                id="confirmation-modal"
            >
                <DialogTitle className="header">Request Sent</DialogTitle>
                <DialogContent className="form-container">
                    <img src={sent} alt="paper airplane flying" />
                    <div className="detail">Your response has been sent successfully. A member of our team will email you as soon as possible!</div>
                    <Button className="back-button" onClick={() => this.props.handleCancel()}>Back to Report</Button>
                </DialogContent>
            </Dialog>
        );
    };
};

export default ConfirmationModal;
