import React, { Component } from 'react';
import PiChart from './PiChart';
import Filters, { Year, Office, Level, Department, Tenure, FiltersSelected, FilterContext } from '../../Analyze/Filters'
import { getYearAliasMap } from '../../Analyze/Filters/utils';
import './index.scss';
import MainCard from '../../utils/MainCard';
import { withTranslation } from 'react-i18next';
import { getOfficeRegionLabel, getDepartmentLabel, getTenureLabel, getLevelLabel, getYearLabel} from "../../../utils/labels";
import {getTranslation} from "../../../translations/utils";
import mixpanel from "mixpanel-browser";
import TAXONOMIES from '../../../utils/taxonomies';

class Breakdown extends Component {
    state = {
        selectedFilters: {},
    }

    track (demographicOverviewBreakdownName) {
        mixpanel.track(TAXONOMIES.DEMOGRAPHIC_OVERVIEW_GRANULAR_BREAKDOWN, {
            demographicOverviewBreakdownName
        })
    }

    render () {
        const { companyName, t, breakdown, composition, showMessage, warningMessage, bespokePage, latestSurveyYear } = this.props;

        const formattedData = composition.map(d => {
            const label = d.title;
            const data = Object.entries(d.data).map(item => {
                return {
                    name: item[1]["name"],
                    value: parseFloat(item[1]["value"])
                }
            });
            return {label, data}
        })

        return (
            <MainCard 
                title={t("BESPOKE.CARD_HEADERS.DEMOGRAPHIC_BREAKDOWN.title")}
                toolTipContent={getTranslation("BESPOKE.CARD_HEADERS.DEMOGRAPHIC_BREAKDOWN.tooltip", companyName)}
                subheader={warningMessage()}
            >
                <div className="bespoke-diversity-snapshot">
                    <Filters filters={this.props.filters} applyFilters={this.props.handleApplyFilters} yearAliasMap={getYearAliasMap(companyName)} bespokePage={bespokePage} latestSurveyYear={latestSurveyYear}>
                        <Year onOpen={() => this.track("Year")} label={getYearLabel(companyName)}/>
                        <Office onOpen={() => this.track("Office")} label={getOfficeRegionLabel(companyName)}/>
                        <Level onOpen={() => this.track("Level")} label={getLevelLabel(companyName)}/>
                        <Department onOpen={() => this.track("Department")} label={getDepartmentLabel(companyName)}/>
                        <Tenure onOpen={() => this.track("Tenure")} label={getTenureLabel(companyName)} />
                        {<FilterContext.Consumer>
                            {data => {
                                if (this.state.selectedFilters !== data.values) {
                                    this.setState({
                                        ...this.state,
                                        selectedFilters: data.values,
                                    })
                                }
                            }}
                        </FilterContext.Consumer>}
                    </Filters>
                    <div className="pie-chart-container">
                        {formattedData ?
                            formattedData.map((d, id) =>
                                <div className="bespoke-diversity-snapshot__single-pie">
                                    <PiChart 
                                        colors={this.props.colors}
                                        key={id}
                                        id={id}
                                        data={d.data}
                                        label={d.label}
                                        showMessage={showMessage}
                                        breakdown={breakdown}
                                        composition={composition}
                                        innerRadius={20}
                                        outerRadius={60}
                                        profile={this.props.profile}
                                    />
                                </div>
                            ) : null
                        }
                    </div>
                    <div className="bespoke-diversity-snapshot__filter-box">
                        <FiltersSelected
                            values={this.state.selectedFilters}
                            allFilters={this.props.filters}
                            companyName={this.props.companyName}
                        />
                    </div>
                </div>
            </MainCard>
        );
    };
};

export default withTranslation()(Breakdown);
