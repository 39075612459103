import React, { SyntheticEvent, forwardRef, useEffect, useRef, useState } from 'react'
import { ResizableBox, ResizeCallbackData } from 'react-resizable'
import {
    ChartLegendItem,
    DownloadIcon,
    IntersectionalityFilterRef,
    UserPrivacyShadedIcon,
    colors,
} from '@diversioteam/diversio-ds'
import classNames from 'classnames'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { HeatmapTable } from '../Table'
import { TABLE_VARIANTS } from '../enums'
import { CustomChartLegend } from '../Legend'
import { HeatmapTableSideCells } from '../SideCells'
import { Size } from '../SideCells/sideCells.types'

import { HeatmapTablesProps } from './tables.types'
import * as S from './tables.styles'

export enum METRICS {
    ALL = 'All Metrics',
    INCLUSION = 'Inclusion',
    ENGAGEMENT = 'Engagement',
    BESPOKE = 'Bespoke',
}

const HeatmapTablesWithoutEB = forwardRef<IntersectionalityFilterRef, HeatmapTablesProps>(
    (
        {
            handleClick,
            onDownloadCSV,
            isPanelVisible,
            inclusionMetrics,
            engagementMetrics,
            bespokeMetrics,
            selectedCellInfo,
            selectedTab,
        },
        ref,
    ) => {
        const tablesRef = useRef<HTMLDivElement>(null)
        const resizableBox = useRef<ResizableBox>(null)

        const [overflow, setOverflow] = useState(false)
        const [sidePanelSize, setSidePanelSize] = useState<Size>()
        const [isResizing, setIsResizing] = useState(false)
        const [isBoxExpanded, setIsBoxExpanded] = useState(false)
        const [originalResizableBoxWidth, setOriginalResizableBoxWidth] = useState(0)
        const [selectedMetric, setSelectedMetric] = useState(METRICS.ALL)
        const [availableMetrics, setAvailableMetrics] = useState([METRICS.ALL])
        const showAddProfile = selectedTab === 'target_groups'

        const handleResize = (_event: SyntheticEvent<Element, Event>, { size }: ResizeCallbackData) => {
            setIsBoxExpanded(originalResizableBoxWidth === size.width ? false : true)
            setSidePanelSize({ width: size.width, height: size.height })
        }

        useEffect(() => {
            setOriginalResizableBoxWidth(resizableBox.current?.props.width || 0)
        }, [])

        useEffect(() => {
            const element = tablesRef.current

            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    const elementTarget = entry.target

                    if (elementTarget !== element) {
                        return
                    }

                    const scrollWidth = tablesRef.current?.scrollWidth || 0
                    const offsetWidth = tablesRef.current?.offsetWidth || 0

                    setOverflow(scrollWidth > offsetWidth)
                }
            })

            if (element) {
                resizeObserver.observe(element)
            }

            return () => {
                if (element) {
                    resizeObserver.unobserve(element)
                }
            }
        }, [tablesRef])

        useEffect(() => {
            const addedMetrics = [METRICS.ALL]

            if (inclusionMetrics.columns.length) {
                addedMetrics.push(METRICS.INCLUSION)
            }

            if (engagementMetrics.columns.length) {
                addedMetrics.push(METRICS.ENGAGEMENT)
            }

            if (bespokeMetrics.columns.length) {
                addedMetrics.push(METRICS.BESPOKE)
            }

            setAvailableMetrics(addedMetrics)
        }, [inclusionMetrics, engagementMetrics, bespokeMetrics])

        return (
            <S.Card overflow={overflow}>
                <S.CardHeader>
                    <S.CardTitle>
                        Employee Experience Heatmap
                        <S.DownloadButton
                            onClick={onDownloadCSV}
                            icon={<DownloadIcon type="line" />}
                            aria-label="Download"
                        />
                    </S.CardTitle>

                    <S.Legends>
                        <ChartLegendItem
                            label="Attention"
                            marker={{
                                enable: true,
                                color: colors.lobster20,
                            }}
                        />
                        <ChartLegendItem
                            label="Doing Well"
                            marker={{
                                enable: true,
                                color: colors.green20,
                            }}
                        />
                        <CustomChartLegend
                            label="Data Missing"
                            marker={{
                                enable: true,
                                icon: <S.DashIcon>-</S.DashIcon>,
                            }}
                        />
                        <CustomChartLegend
                            label="Privacy Protected"
                            marker={{
                                enable: true,
                                icon: <UserPrivacyShadedIcon width={12} height={12} />,
                            }}
                        />
                    </S.Legends>
                </S.CardHeader>

                <S.ScrollBar>
                    <S.HeatmapTables isPanelVisible={isPanelVisible}>
                        <ResizableBox
                            ref={resizableBox}
                            className={classNames({ resizing: isResizing })}
                            minConstraints={[200, 200]}
                            maxConstraints={[500, 500]}
                            width={200}
                            axis="x"
                            onResize={handleResize}
                            onResizeStart={() => setIsResizing(true)}
                            onResizeStop={() => setIsResizing(false)}
                        >
                            <HeatmapTableSideCells
                                data={inclusionMetrics}
                                availableMetrics={availableMetrics}
                                selectedMetric={selectedMetric}
                                onSelectMetric={setSelectedMetric}
                                showAddProfile={showAddProfile}
                                onClick={handleClick}
                                selectedCellInfo={selectedCellInfo}
                                size={sidePanelSize}
                                showFullTitle={isBoxExpanded}
                                ref={ref}
                            />
                        </ResizableBox>

                        {inclusionMetrics.columns?.length &&
                        [METRICS.ALL, METRICS.INCLUSION].includes(selectedMetric) ? (
                            <HeatmapTable
                                className="inclusion"
                                label="Inclusion Scores"
                                onClick={handleClick}
                                data={inclusionMetrics}
                                table={TABLE_VARIANTS.INCLUSION}
                                selectedCellInfo={selectedCellInfo}
                                showAddProfile={showAddProfile}
                            />
                        ) : null}

                        {bespokeMetrics.columns?.length && [METRICS.ALL, METRICS.BESPOKE].includes(selectedMetric) ? (
                            <HeatmapTable
                                label="Bespoke"
                                className="bespoke"
                                onClick={handleClick}
                                data={bespokeMetrics}
                                table={TABLE_VARIANTS.BESPOKE}
                                selectedCellInfo={selectedCellInfo}
                                showAddProfile={showAddProfile}
                            />
                        ) : null}

                        {engagementMetrics.columns?.length &&
                        [METRICS.ALL, METRICS.ENGAGEMENT].includes(selectedMetric) ? (
                            <HeatmapTable
                                label="Engagement"
                                className="engagement"
                                onClick={handleClick}
                                data={engagementMetrics}
                                table={TABLE_VARIANTS.ENGAGEMENT}
                                selectedCellInfo={selectedCellInfo}
                                showAddProfile={showAddProfile}
                            />
                        ) : null}
                    </S.HeatmapTables>
                </S.ScrollBar>
            </S.Card>
        )
    },
)

export const HeatmapTables = withErrorBoundary<HeatmapTablesProps & { ref: React.Ref<IntersectionalityFilterRef> }>(
    HeatmapTablesWithoutEB,
    {},
)
