import React, {Component} from 'react';
import Header from "./Header";
import ScoreBreakdown from "./ScoreBreakdown";
import './index.scss'
import { withHooks } from 'config/withHooks/withHooks';
import { getActiveCompany } from 'utils';

const pages = {
    Overview: 'Overview',
    About: 'About',
    Resources: 'Resources'
};

class Overview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            page: pages.Overview
        }
    }

    componentDidMount() {
        this.props.getDiversitypeBenchmark();
        this.props.getDiversitypeScore();
        this.props.getDiversitypeCompanyPolicy();
        this.props.getDiversitypePolicy();
        this.props.getDiversitypeResource();
        this.props.getAllSolutions();
    }


    _deleteSolution(data) {
        this.props.doDeleteSolution(data);
    }

    _createNewSolution(data) {
        this.props.doCreateNewSolution(data);
    }

    _createNewCompanySolution(data) {
        this.props.doCreateNewCompanySolution(data);
    }

    render() {
        const hasLoaded = this.props.analyze !== undefined;
        const companyName = getActiveCompany(this.props.userMetadata?.companies)?.name
        if (!hasLoaded) {
            return null;
        }

        const mySolutions = this.props.improve.solutions.allSolutions.filter(x => x.is_my_solution);
        const { diversitype: { score, benchmark } = {} } = this.props;

        return (
            <div id={'overview'}>
                <Header 
                    score={score}
                    benchmark={benchmark}
                    companyName={companyName}
                />
                <ScoreBreakdown diversitype={this.props.diversitype}/>
            </div>
        );
    }
}

export default withHooks(Overview);
