import { loadInclusionData } from 'sagas/network'
import { KpiKey } from '@diversioteam/diversio-ds/dist/shared/types/kpi.types'

import { stringifyQueryParams } from '../utils'
import { Queries } from 'api/queries.enum'

import { useQuery } from './useQuery'

export type NO_DATA = 'N/A' | 'INSUFFICIENT_DATA'

export type RawInclusionData = {
    overall: RawOverallInclusionData[]
    inclusionMetrics: RawInclusionMetrics
    engagementMetrics: RawInclusionMetrics
    bespokeMetrics: RawInclusionMetrics
}

type RawOverallInclusionData = {
    title: string
    tooltip: string
    score: number
    previousDifference: number
    benchmarkDifference: number
}

export type RawInclusionMetrics = {
    columns: RawColumn[]
    data: RawData[]
}

export type RawColumn = {
    title: string
    key: KpiKey
    nonDominantGroupScore?: number | NO_DATA
    dominantGroupScore?: number | NO_DATA
    benchmarks: BenchmarkProps[]
    companyScore: number
    description: string
    questionTitle: string
}

export type BenchmarkProps = {
    isActive: boolean
    isPrimary: boolean
    label: string
    score: number | NO_DATA
    uuid: string
}

export type RawData = {
    scores: RawDataScores[]
    title: string
    subtitle: string
    secondarySubtitle: string | undefined
    profileColor?: number
    type?: 'benchmark' | 'dimension' | 'profile' | 'bespoke_demographic'
    tooltip: string
    code: string
}

export type RawDataScores = {
    current?: number | NO_DATA
    previous?: number | NO_DATA
    isStrength: boolean
    isWeakness: boolean
    isSelected?: boolean
}

export type InclusionDataProps = {
    year?: string
    key?: string
    type?: string
    filters_in_profiles?: boolean
    export_format?: string
}

export const useInclusionData = (params: InclusionDataProps) => {
    return useQuery<RawInclusionData, Error>({
        queryKey: [Queries.getInclusionData, params],
        queryFn: () => {
            return loadInclusionData(stringifyQueryParams(params))
        },
    })
}
