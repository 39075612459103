/** @format */

import { mapT } from "../translations/utils";
import * as m from "../translations/mapping";

export const translateTargetGroups = (targetGroups) => {
  const cleanTargetGroups = [];
  for (const i in targetGroups) {
    cleanTargetGroups.push({
      label: mapT(m.GROUP_BREAKDOWN, targetGroups[i].label),
      value: targetGroups[i].value,
    });
  }
  return cleanTargetGroups;
};

export const toTitleCase = (str) => {
  /**
   * Capitalizes each word in the string provided, returns empty string if str variable is empty
   */
  if (str === null || str === undefined) return "";
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
