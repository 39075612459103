import { call, put, select } from 'redux-saga/effects';
import { Actions, ActionTypes } from '../actions';
import {
    handleLogin,
    handleRegistration,
    handleSelfServeRegistration,
    refreshToken,
    changePassword,
    requestResetPassword,
    resetPassword,
    requestVerificationCode,
    validateVerificationCode,
    fetchExistingBackupCodes,
    fetchNewBackupCodes,
    initializeSSO,
    ssoCallback
} from './network';
import mixpanel from "mixpanel-browser"
import { jwtDecode } from "jwt-decode";
import _ from "underscore";
import history from "../history";
import {getGlobalTranslatedMessage} from "../utils";
import { beaconDestroy } from "../utils/beacon";
import TAXONOMIES from '../utils/taxonomies';

const getProfile = (state) => state.profile;

// All data sagas to add to middleware.
export default [
    [ActionTypes.DO_LOGIN, doLogin],
    [ActionTypes.DO_REGISTRATION, doRegistration],
    [ActionTypes.DO_SELF_SERVE_REGISTRATION, doSelfServeRegistration],
    [ActionTypes.DO_REQUEST_VERIFICATION_CODE, doRequestVerificationCode],
    [ActionTypes.DO_VALIDATE_VERIFICATION_CODE, doValidateVerificationCode],
    [ActionTypes.DO_FETCH_EXISTING_BACKUP_CODES, doFetchExistingBackupCodes],
    [ActionTypes.DO_FETCH_NEW_BACKUP_CODES, doFetchNewBackupCodes],
    [ActionTypes.DO_REFRESH_TOKEN, doRefreshToken],
    [ActionTypes.DO_SIGNOUT, doSignout],
    [ActionTypes.DO_CHANGE_PASSWORD, doChangePassword],
    [ActionTypes.DO_REQUEST_RESET_PASSWORD, doRequestResetPassword],
    [ActionTypes.DO_RESET_PASSWORD, doResetPassword],
    [ActionTypes.DO_INITIALIZE_SSO, doInitializeSSO],
    [ActionTypes.DO_SSO_CALLBACK, doSsoCallback]
];

const handleAuthCreds = (response, trustedDevice) => {
    const { access_token , refresh_token, backup_codes} = response.data;

    const user = jwtDecode(access_token)
    const responseObject = {
        "access_token": access_token,
        "refresh_token": refresh_token,
        "user": user,
        "backupCodes": backup_codes
    }
    if (access_token && refresh_token) {
        localStorage.setItem("auth_access_token", access_token);
        localStorage.setItem("auth_refresh_token", refresh_token);
    }
    if (user["2fa_device_id"] && trustedDevice === true) {
        localStorage.setItem("mfa_device_id", user["2fa_device_id"])
    }

    return responseObject;
}

function * doLogin ({ payload }) {
    yield put(Actions.doingLogin());

    const response = yield call(handleLogin, payload);

    // The explicit response.code check is because the backend will set response.successfull = True,
    // when throttling the request. This will result in the error message not being shown without it.
    if (!response.successful || response.code == 429) {
        yield put(Actions.showMessage({
            type: "error",
            code: response.code,
            delay: response.code === 404 ? 15000 : 5000,
            message: getGlobalTranslatedMessage(response, `AUTH.LOGIN.${_.maybe(response, "data", "detail")}`)
        }));
        if (response?.data?.detail === "INVALID_DEVICE_ID") {
            localStorage.removeItem("mfa_device_id");
            yield put(Actions.resetAuth());
        }
        yield put(Actions.doSignout());
    } else {
        yield put(Actions.doneLogin(handleAuthCreds(response)));
    }
}

function * doRegistration ({ payload }) {
    yield put(Actions.doingRegistration());
    const response = yield call(handleRegistration, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: response.data.error.message}));
        yield put(Actions.resetAuth());
    } else {
        mixpanel.track(TAXONOMIES.SIGN_UP, {
            $email: payload.email,
            $username: payload.username,
            user_id: response.data.data.user_id
        });
        mixpanel.alias(response.data.data.user_id);

        yield put(Actions.doneRegistration(response.data));
    }
}

function * doSelfServeRegistration ({ payload }) {
    yield put(Actions.doingSelfServeRegistration());
    const response = yield call(handleSelfServeRegistration, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message:  response.data.error.detail?.company?.[0] ?? response.data.error.detail?.username?.[0] ?? response.data.error?.detail?.email?.[0] ?? response.data.error.message}));
        yield put(Actions.resetAuth());
    } else {
        mixpanel.track(TAXONOMIES.SIGN_UP, {
            $email: payload.email,
            $username: payload.username,
            user_id: response.data.data.user_id
        });
        mixpanel.alias(response.data.data.user_id);

        yield put(Actions.doneSelfServeRegistration(response.data));
    }
}

function * doRefreshToken ({ payload }) {
    yield put(Actions.doingRefreshToken());
    const requestPayload = {refresh: payload.refreshToken, access: payload.accessToken};

    const response = yield call(refreshToken, requestPayload)
    const profile = yield select(getProfile);

    if (!response.successful) {
        mixpanel.track(TAXONOMIES.DASHBOARD_SESSION_ENDED, {
            dashboardName: profile.company,
            user: profile.username,
            action: "Refresh Token Expired"
        });
        yield put(Actions.resetAuth());
    } else {
        yield put(Actions.doneRefreshToken(handleAuthCreds(response)));
    }
}

export function* doSignout(props) {
    yield put(Actions.doingSignout());
    localStorage.removeItem("auth_access_token");
    localStorage.removeItem("auth_refresh_token");
    if (props?.payload?.clearSession) {
        sessionStorage.setItem("clear_before_signout", "true");
    } 
    yield put(Actions.resetAuth());
    yield put(Actions.doneSignout());

    beaconDestroy();
}

function * doChangePassword ({ payload }) {
    yield put(Actions.doingChangePassword());

    const response = yield call(changePassword, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: response.data.error.message}));

        yield put(
            Actions.showToast({
              type: "error",
              message: response.data.error.message,
            })
          );
    } else {
        yield put(Actions.doneChangePassword(response.data))
        
        yield put(
            Actions.showToast({
              type: "success",
              message: "Successfully changed password.",
            })
        );;
    }
}

function * doRequestResetPassword ({ payload }) {
    yield put(Actions.doingRequestResetPassword());

    const response = yield call(requestResetPassword, payload);

    if (!response.successful || response.code == 429) {
        yield put(Actions.showMessage({
            type: "error",
            code: response.code,
            delay: response.code === 404 ? 15000 : 5000,
            message: getGlobalTranslatedMessage(response, `AUTH.FORGOT_PASSWORD.${_.maybe(response, "data", "detail")}`)
        }));

        yield put(Actions.requestResetPasswordFail(response.data));
    } else {
        mixpanel.track(TAXONOMIES.RESET_PASSWORD, {
            email: payload.email,
            username: payload.username
        })
        
        yield put(Actions.doneRequestResetPassword(response.data));
    }
}

function * doResetPassword ({ payload }) {
    yield put(Actions.doingResetPassword());

    const response = yield call(resetPassword, payload);

    if (!response.successful) {
        yield put(Actions.resetPasswordFail(response.data));
    } else {
        yield put(Actions.doneResetPassword(response.data));
    }
}

function * doRequestVerificationCode ({ payload }) {
    yield put(Actions.doingRequestVerificationCode());

    const response = yield call(requestVerificationCode, payload);

    if (!response.successful) {
        if (response.code === 429) {
            yield put(Actions.failedRequestVerificationCode(response.data));
        }
        yield put(Actions.showMessage({type: "error", code: response.code, message: getGlobalTranslatedMessage(response, `AUTH.MFA.MESSAGES.${_.maybe(response, "data", "detail")}`)}));
    } else {
        yield put(Actions.showMessage({type: "success", code: response.code, message: getGlobalTranslatedMessage(response, `AUTH.MFA.MESSAGES.${_.maybe(response, "data", "detail")}`)}));
        yield put(Actions.doneRequestVerificationCode(response.data));
    }
}

function * doValidateVerificationCode ({ payload }) {
    yield put(Actions.doingValidateVerificationCode());

    const { trustedDevice, ...restPayload } = payload;

    const response = yield call(validateVerificationCode, restPayload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: getGlobalTranslatedMessage(response, `AUTH.MFA.MESSAGES.${_.maybe(response, "data", "detail")}`)}));
    } else {
        if (response.code == 401) {
            yield put(Actions.resetAuth());
        }
        yield put(Actions.doneValidateVerificationCode(handleAuthCreds(response, trustedDevice)));
    }
}

function * doFetchExistingBackupCodes ({ payload }) {
    yield put(Actions.doingFetchExistingBackupCodes());

    const response = yield call(fetchExistingBackupCodes, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: getGlobalTranslatedMessage(response, `AUTH.MFA.MESSAGES.${_.maybe(response, "data", "detail")}`)}));
    } else {
        yield put(Actions.doneFetchExistingBackupCodes(response.data));
    }
}

function * doFetchNewBackupCodes ({ payload }) {
    yield put(Actions.doingFetchNewBackupCodes());

    const response = yield call(fetchNewBackupCodes, payload);

    if (!response.successful) {
        yield put(Actions.showMessage({type: "error", code: response.code, message: getGlobalTranslatedMessage(response, `AUTH.MFA.MESSAGES.${_.maybe(response, "data", "detail")}`)}));
    } else {
        yield put(Actions.doneFetchNewBackupCodes(response.data));
    }
}

function * doInitializeSSO ({ payload }) {
    yield put(Actions.doingInitializeSso());

    const response = yield call(initializeSSO, payload);

    if (!response.successful) {
        yield put(
            Actions.showMessage({
                type: "error",
                code: response.code,
                message: response.data.errors?.__all__
                        || getGlobalTranslatedMessage(response, `AUTH.SSO.MESSAGES.${_.maybe(response, "data", "detail")}`)
            })
        )
        yield put(Actions.failedInitializeSso());
    } else {
        yield put(Actions.doneInitializeSso(response.data));
    }
}

function * doSsoCallback ({ payload }) {
    yield put(Actions.doingSsoCallback());

    const response = yield call(ssoCallback, payload);

    if (!response.successful) {
        yield put(
            Actions.showMessage({
                type: "error",
                code: response.code,
                message: response.data.errors?.__all__
                    || getGlobalTranslatedMessage(response, `AUTH.SSO.MESSAGES.${_.maybe(response, "data", "detail")}`)
            })
        )
        yield put(Actions.failedSsoCallback(response.data));
    } else {
        yield put(Actions.doneSsoCallback(handleAuthCreds(response)));
    }
}
