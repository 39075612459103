import React, { Component } from 'react';
import Link from '../../utils/Link';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import './index.scss';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}
class DeleteModal extends Component {
    render () {
        return (
            <div>
                <Dialog
                    open={this.props.isOpen}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.handleCloseModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-content"
                    fullWidth={true}
                    id="delete-modal"
                >
                    <DialogTitle className="header">{`Delete ${this.props.type}`}</DialogTitle>
                    <DialogContent>
                        <div className="text-area">
                            Are you sure you want to delete this post? You cannot restore after deletion.
                        </div>
                    </DialogContent>
                    <DialogActions className="action-container">
                        <Button className="delete-button" onClick={() => {this.props.handleDelete()}} >
                            {
                                this.props.type === 'Post'
                                ? <Link to="/connect/all-post">
                                    Delete
                                </Link>
                                : <span>Delete</span>
                            }
                        </Button>
                        <Button className="cancel-button" onClick={() => {this.props.handleCloseModal()}}>
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };
};

export default DeleteModal;