import { useMutation } from '@tanstack/react-query'
import { loadInclusionData } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import { InclusionDataProps } from 'hooks/useInclusionData'
import { Mutations } from 'api/mutations.enum'

export const useExportInclusionData = (params: InclusionDataProps) => {
    const mutation = useMutation<string, Error, void>({
        mutationKey: [Mutations.exportInclusionData, params],
        mutationFn: () => {
            return loadInclusionData(stringifyQueryParams(params), 'file')
        },
    })

    return mutation
}
