/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { stringify } from "query-string";
import mixpanel from "mixpanel-browser";
import {
  SingleSelect,
  Button,
  ReloadIcon,
  PeopleIcon,
  PainPointIcon,
  SearchBox,
  ToolCard,
  Toast,
  Chip,
  EventCard,
} from "@diversioteam/diversio-ds";

import { ToolCardListSkeleton } from "../../../../components/skeletons/toolCardListSkeleton";
import { Actions } from "../../../../actions";
import { getActiveCompanyID } from "../../../../utils";
import { kpiMap } from "../../../../utils/kpiUtils";
import { toTitleCase, translateTargetGroups } from "../../../../utils/programFeaturesFunctions";
import { getPageName, handleClickTool } from "../../../../utils/tracking";
import { mapT } from "../../../../translations/utils";
import * as m from "../../../../translations/mapping";

import "./index.scss";
import TAXONOMIES from "../../../../utils/taxonomies";
import { withHooks } from "config/withHooks/withHooks";
import { getCleanedKpiCodes } from "../../utils/getCleanedKpiCodes";
import { getCleanedGroups } from "../../utils/getCleanedGroups";

const PAGE_NAME = "tools";

const PAGE_SIZE = 9;

class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: PAGE_SIZE,
      sort: "id",
      orderBy: "-",
      search: "",
      selectedKpiID: null,
      selectedPainPointID: null,
      selectedTargetGroupID: null,
      selectedKPIValue: null,
      selectedTargetGroupValue: null,
      timer: null,
    };
    this.props.resetTools();
    this.props.resetGlobalToast();
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    const query = stringify({
      page: 1,
      page_size: PAGE_SIZE,
      title: "",
    });
    this.props.getSolutionTools({ id: activeID, query: query });
    this.props.getSolutionToolsFilters({ id: activeID });

    // call this to get the active survey ID
    const initialData = {
      data: { year: "", level: "", office: "", department: "" },
      id: activeID,
    };
    this.props.getSolutionGoalsInsightData(initialData);
  }

  componentDidMount() {
    mixpanel.track(TAXONOMIES.VIEW_PAGE, {
      name: getPageName(PAGE_NAME),
    });
  }

  handleLoadTools = () => {
    /**
     * Function to call the backend api to load the first set or next page of tools to show to the
     * user
     */
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.LOAD_MORE_TOOLS}`);

    const { page, pageSize, search, selectedKpiID, selectedPainPointID, selectedTargetGroupID } = this.state;
    const newPage = page + 1;
    this.setState({
      page: newPage,
    });

    const query = stringify({
      page: newPage,
      page_size: pageSize,
      title: search,
      kpis: [selectedKpiID],
      pain_points: [selectedPainPointID],
      target_groups: [selectedTargetGroupID],
    });

    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getSolutionTools({ id: activeID, query: query });
  };

  handleKpiChange = (id) => {
    /**
     * Function that handles a user changing a selected kpi to filter by
     */
    const { pageSize, search, selectedPainPointID, selectedTargetGroupID } = this.state;
    const { kpis } = this.props?.solutionTools.toolsFilters;
    const kpiName = kpis.filter(({ value }) => value === id)[0].label;

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHOOSES_KPI_FILTER}`, {
      kpiName,
    });

    let kpiObj = null;

    for (const i in kpis) {
      if (kpis[i].value === id) {
        kpiObj = kpis[i];
      }
    }
    this.setState({
      selectedKpiID: id,
      selectedKPIValue: kpiObj,
      page: 1,
    });

    let obj = {
      page: 1,
      page_size: pageSize,
      title: search,
      kpis: [id],
    };

    if (selectedPainPointID) obj.pain_points = [selectedPainPointID];
    if (selectedTargetGroupID) obj.target_groups = [selectedTargetGroupID];

    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.resetToolChoices();
    this.props.getSolutionTools({ id: activeID, query: query });
    this.scrollToTop();
  };

  handleTargetGroupChange = (id) => {
    /**
     * Function that handles a user changing a selected target group to filter by
     */
    const { pageSize, search, selectedPainPointID, selectedKpiID } = this.state;
    const { target_groups } = this.props?.solutionTools.toolsFilters;
    const targetGroupName = target_groups.filter(({ value }) => value === id)[0].label;

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHOOSES_TARGET_GROUP_FILTER}`, {
      targetGroupName,
    });

    let targetGroupObj = null;

    for (const i in target_groups) {
      if (target_groups[i].value === id) {
        targetGroupObj = target_groups[i];
        break;
      }
    }

    this.setState({
      selectedTargetGroupID: id,
      selectedTargetGroupValue: targetGroupObj,
      page: 1,
    });

    let obj = {
      page: 1,
      page_size: pageSize,
      title: search,
      target_groups: [id],
    };

    if (selectedPainPointID) obj.pain_points = [selectedPainPointID];
    if (selectedKpiID) obj.kpis = [selectedKpiID];

    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);

    this.props.resetToolChoices();
    this.props.getSolutionTools({ id: activeID, query: query });
    this.scrollToTop();
  };

  handleSearchChange = (value) => {
    /**
     * Function to handle a user typing into the search box. We use lodash's debounce method
     * to provide a short delay from when the user types a character to when the backend api call is made.
     * This is done to prevent api call's while a user is still typing
     */
    const { pageSize, selectedKpiID, selectedPainPointID, selectedTargetGroupID, timer } = this.state;
    const newSearch = value;

    this.setState({
      search: newSearch,
      page: 1,
    });

    const query = stringify({
      page: 1,
      page_size: pageSize,
      title: newSearch,
      kpis: [selectedKpiID],
      pain_points: [selectedPainPointID],
      target_groups: [selectedTargetGroupID],
    });

    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      if (newSearch) {
        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.SEARCH_TERM}`, {
          searchTerm: newSearch,
        });
      }

      this.props.resetToolChoices();
      this.props.getSolutionTools({ id: activeID, query: query });
      this.scrollToTop();
    }, 800);

    this.setState({
      timer: newTimer,
    });
  };

  scrollToTop = () => {
    /**
     * Helper function to set the page scroll back to 0 on each goal subpage render
     */
    let container = document.getElementsByClassName("content-container")[0];
    
    container.scrollTop = 0;
  };

  saveTool = (tool) => {
    const companyID = getActiveCompanyID(this.props.userMetadata?.companies);

    const obj = {
      tool: tool.id,
      company: companyID,
      tool_obj: tool,
      page: PAGE_NAME,
    };

    this.props.doSolutionSaveTool(obj);
  };

  removeSavedTool = (tool) => {
    if (tool?.company_tool_id) {
      const obj = {
        toolId: tool?.id,
        companyToolId: tool?.company_tool_id,
        tool_obj: tool,
        page: PAGE_NAME,
      };

      this.props.doSolutionRemoveSavedTool(obj);
    }
  };

  removeKpiFilter = () => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.REMOVE_KPI_FILTER}`);

    const { selectedTargetGroupID, search, pageSize } = this.state;

    this.setState({
      selectedKPIValue: null,
      selectedKpiID: null,
    });

    const obj = {
      page: 1,
      page_size: pageSize,
      title: search,
    };

    if (selectedTargetGroupID) obj.target_groups = [selectedTargetGroupID];

    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);

    this.props.resetToolChoices();
    this.props.getSolutionTools({ id: activeID, query: query });
  };

  removeTargetGroupFilter = () => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.REMOVE_TARGET_GROUP_FILTER}`);

    const { selectedKpiID, search, pageSize } = this.state;

    this.setState({
      selectedTargetGroupValue: null,
      selectedTargetGroupID: null,
    });

    const obj = {
      page: 1,
      page_size: pageSize,
      title: search,
    };

    if (selectedKpiID) obj.kpis = [selectedKpiID];

    const query = stringify(obj);
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);

    this.props.resetToolChoices();
    this.props.getSolutionTools({ id: activeID, query: query });
  };

  handleExpandSearchBox = () => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.SEARCH_CLICKED}`);
  };

  render() {
    const { tools, toolsFilters, endOfTools, isLoading, isLoadingMore } = this.props?.solutionTools;
    const { kpis = [], target_groups } = toolsFilters;
    const { search, selectedKPIValue, selectedTargetGroupValue } = this.state;
    const toastError = this.props.message.toastError;
    const targetGroups = target_groups ? translateTargetGroups(target_groups) : null;
    const filterApplied = selectedKPIValue || selectedTargetGroupValue ? true : false;

    return (
      <div className="tools-container">
        {toastError?.type && (
          <div className="ds-toast-component">
            <Toast
              open={true}
              type={toastError?.type}
              message={toastError?.message}
              onClick={() => {
                this.props.resetGlobalToast();
              }}
            />
          </div>
        )}

        <div className="tools-wrapper">
          <div className="tools-content">
            <div className="tools-header">
              <div className="tools-text tool-page-margin">
                <h1>Diversio Tools</h1>

                <h3>Tools and resources to complement company efforts</h3>
              </div>

              <div className="tools-filter tool-page-margin">
                <span className="search-box-wrapper">
                  <SearchBox
                    maxWidth={198}
                    value={search}
                    onChange={(value) => this.handleSearchChange(value)}
                    onExpand={() => this.handleExpandSearchBox()}
                  />
                </span>

                <SingleSelect
                  color="light"
                  variant="block"
                  listboxId="kpi"
                  placeholder="KPI"
                  isPlaceholderPermanent
                  icon={<PainPointIcon type="line" />}
                  options={kpis}
                  value={selectedKPIValue ? selectedKPIValue.value : null}
                  onChange={(id) => {
                    this.handleKpiChange(id);
                  }}
                />

                <SingleSelect
                  color="light"
                  variant="block"
                  listboxId="targetGroup"
                  placeholder="Target Group"
                  isPlaceholderPermanent
                  icon={<PeopleIcon type="line" />}
                  options={targetGroups ? targetGroups : []}
                  value={selectedTargetGroupValue ? selectedTargetGroupValue.value : null}
                  onChange={(id) => {
                    this.handleTargetGroupChange(id);
                  }}
                />
              </div>
            </div>

            {filterApplied ? (
              <div className="tools-chip-container">
                <div className="chip-container-wrapper tool-page-margin">
                  {selectedKPIValue ? (
                    <div className="chip-wrapper">
                      <Chip
                        variant="primary"
                        label={selectedKPIValue.label}
                        onDelete={() => this.removeKpiFilter()}
                      />
                    </div>
                  ) : null}

                  {selectedTargetGroupValue ? (
                    <div className="chip-wrapper">
                      <Chip
                        variant="primary"
                        label={mapT(m.GROUP_BREAKDOWN, selectedTargetGroupValue.label)}
                        onDelete={() => this.removeTargetGroupFilter()}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            <div className="tools-body">
              {isLoading.tools ? (
                <ToolCardListSkeleton
                  size="medium"
                  numberOfElements={this.state.pageSize}
                  listElementWrapperClassName="tool-card-wrapper tool-page-margin"
                />
              ) : (
                tools.map((row, index) => {
                  return (
                    <div className="tool-card-wrapper tool-page-margin">
                      <ToolCard
                        key={index}
                        category={toTitleCase(row?.media_type)}
                        kpis={getCleanedKpiCodes(row.pain_points)}
                        targetGroups={getCleanedGroups(row.target_groups)}
                        publicationYear={row.created}
                        description={row.description}
                        title={row.title ? row.title.replace(".pdf", "").replace(".docx", "") : null}
                        onSave={() => this.saveTool(row)}
                        isSaved={row.is_saved}
                        href={row.url}
                        onRemove={() => this.removeSavedTool(row)}
                        onClick={(e) => handleClickTool(e, row, PAGE_NAME)}
                        size="medium"
                      />
                    </div>
                  );
                })
              )}
            </div>

            <div className="load-more-container">
              <div className="load-more tools-footer tool-page-margin">
                <div className="load-more-button-wrapper">
                  <Button
                    rightIcon={<ReloadIcon type="line" />}
                    type="button"
                    color="secondary"
                    onClick={() =>
                      endOfTools
                        ? this.props.showToast({
                            type: "warning",
                            message: "No more tools available",
                          })
                        : this.handleLoadTools()
                    }
                    loading={isLoadingMore.tools}
                  >
                    Load More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, Actions)(withHooks(Tools));
