import React from 'react';
import { Row } from "../utils/FlexBox";
import mixpanel from "mixpanel-browser";
import HeatMap from './HeatMap';
import { getSkinnedCompanyName } from "../../containers/Home";
import Loader from '../utils/Loader';
import TAXONOMIES from '../../utils/taxonomies';
import { useUserMetadata } from 'hooks/metadata/useUserMetadata';
import { getActiveCompany } from 'utils';


const Engagement = (props) => {
    const { data: userMetadata } = useUserMetadata();
    const companyInformation = getActiveCompany(userMetadata?.companies)

    React.useEffect(() => {
        mixpanel.track(TAXONOMIES.SELECT_DEMOGRAPHICS_OR_ENGAGEMENT_PAGE, {
            bespokePageName: "Engagement"
        })
    }, []);

    const { quarters, bespoke_engagement_quarters, bespoke_questions } = props.analyze.filters 

    const isExportToExcelEnabled = companyInformation?.permissions?.export_to_excel;

    const companyName = getSkinnedCompanyName(companyInformation?.name, props.profile);
    
    const demographicQuestions = bespoke_questions.filter(question => question.question_type == "demographic")
    const experienceQuestions = bespoke_questions.filter(question => question.question_type == "experience")

    const latestBespokeEngagementSurvey = bespoke_engagement_quarters?.[0]
    const latestBespokeEngagementSurveyString = latestBespokeEngagementSurvey ? `${latestBespokeEngagementSurvey[0]}Q${latestBespokeEngagementSurvey[1]}` : ''

    /**
     * If the current survey does not have bespoke experience questions, display a message
     * communicating that the data being displayed is from an older survey.
     */
    const displayMessageForOldBespokeData = () => {
        const latestSurvey = quarters?.[0]
        
        // Check if latest survey has any bespoke engagement questions
        const latestSurveyHasBespokeEngagementQuestions = (latestSurvey.length == latestBespokeEngagementSurvey.length) && latestSurvey.every((element, index) =>
            element === latestBespokeEngagementSurvey[index]
        );

        if (!latestSurveyHasBespokeEngagementQuestions) {
            return <p><i>Note that the data displayed below is from a previous survey but is the most recent data available.</i></p>
        }
    }

    return (
        props.analyze.isLoading ? <Loader/> :
        <div>
            <Row>
                <HeatMap 
                    data={props.bespoke.inclusionMetricsBespoke}
                    loadingNonOverviewMetrics={props.bespoke.loadingNonOverviewMetrics}
                    prevData={props.bespoke.previousInclusionMetricsBespoke}
                    overall={props.bespoke.overall}
                    previousOverall={props.bespoke.previousOverall}
                    companyName={companyName}
                    isExportToExcelEnabled={isExportToExcelEnabled}
                    getInclusionMetricsDataBespoke={props.getInclusionMetricsDataBespoke}
                    getInclusionMetricsExcel={props.getInclusionMetricsExcel}
                    getNonOverviewMetricsData={props.getNonOverviewMetricsDataBespoke}
                    getNonOverviewMetricsExcel={props.getNonOverviewMetricsExcel}
                    allFilters={props.analyze.filters}
                    selected={props.selected}
                    selectTab={props.select}
                    metadata={props.bespoke.metadata}
                    bespokeQuestions={bespoke_questions}
                    expQuestions={experienceQuestions}
                    demQuestions={demographicQuestions}
                    currentHeatmap={"heatmap3"}
                    doUpdateCompanyFilters={props.doUpdateCompanyFilters}
                    latestSurveyYear={latestBespokeEngagementSurveyString}
                    warningMessage={displayMessageForOldBespokeData}
                    bespokePage={"engagement"}
                />
            </Row>
        </div>
    );
};

export default Engagement;
