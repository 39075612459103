import styled, { css } from 'styled-components'

import { media } from 'styles/theme/mediaQueries'
import { AnalyzeBreadcrumbsWrapper } from 'components/AnalyzeV2/AnalyzeBreadcrumbs/analyzeBreadcrumbs.styles'

export const Container = styled.div<{ v2: boolean; hasCustomSpacing: boolean }>`
    grid-area: topbar;
    box-sizing: border-box;
    width: calc(100% - 256px);
    min-height: 86px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 256px;
    padding-left: 24px;
    padding-right: 24px;
    position: absolute;
    z-index: 110;
    background-color: white;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primaryGray2};

    ${media.lg} {
        padding-left: ${({ hasCustomSpacing }) => {
            if (!hasCustomSpacing) {
                return '48px'
            }
        }};
        padding-right: 48px;
    }

    ${({ v2 }) => {
        if (v2) {
            return css`
                position: relative;
                width: auto;
                margin-left: 70px;
            `
        } else {
            return css`
                .dds-topbar-menu__left-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 7px;
                }
            `
        }
    }}

    .dds-single-select {
        ${media.md} {
            margin-right: auto;

            &:has(+ ${AnalyzeBreadcrumbsWrapper}) {
                margin-right: 60px;
            }
        }
    }

    .dds-topbar-menu {
        margin-left: auto;

        ${media.md} {
            margin-left: 60px;
        }

        &:only-child {
            margin-left: auto;
        }
    }
`

export const Switch = styled.div`
    display: flex;
    gap: 60px;

    ${media.md} {
        &:first-child {
            margin-left: auto;
        }
    }
`
