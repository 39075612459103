import {Actions} from '../../actions';
import {connect} from 'react-redux';
import DiversityPE from "../../components/DiversityPE";

const mapStateToProps = (state, ownProps) => {
    return {
        analyze: state.analyze,
        diversitype: state.diversitype,
        home: state.home,
        improve: state.improve,
        ...ownProps
    };
};

export default connect(mapStateToProps, Actions)(DiversityPE);
