import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import AllPost from '../components/Connect/AllPost';
import './App.scss';
import mixpanel from "mixpanel-browser";
import TAXONOMIES from '../utils/taxonomies';

class AllPostContainer extends Component {
    componentDidMount() {
        mixpanel.track(TAXONOMIES.VIEW_PAGE, {
            name: "All Post"
        })
    }

    render() {
        return (
            <div>
                <AllPost allPosts={this.props.allPosts} 
                         profileInfo={this.props.profileInfo}
                         handleCreatePost={this.props.handleCreatePost}
                         handleLikePost={this.props.handleLikePost}
                         handleEditPost={this.props.handleEditPost} 
                         handleDeletePost={this.props.handleDeletePost} 
                         bookmark={this.props.bookmark}
                         handleUpdateBookmark={this.props.handleUpdateBookmark}
                         isFiltered={this.props.isFiltered}
                         filteredPosts={this.props.filteredPosts}
                         profileName={this.props.profileName}
                         kpis={this.props.kpis} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state;
};

export default connect(mapStateToProps, Actions)(AllPostContainer);
