import { Toast as DdsToast } from '@diversioteam/diversio-ds'
import { Button as DdsButton, TextField as DdsTextField, getFontStyle } from '@diversioteam/diversio-ds'
import styled from 'styled-components'

export const Container = styled.div`
    height: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Form = styled.form`
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 460px;
    height: 600px;
    max-width: 100%;
    padding: 40px;
    border-radius: 30px;
    background-color: ${({ theme }) => theme.colors.primaryWhite};
    border: 1px solid ${({ theme }) => theme.colors.primaryGray2};
`

export const Logo = styled.img`
    width: 115px;
`

export const Title = styled.h1`
    margin: 20px 0 24px;
    color: ${({ theme }) => theme.colors.primary70};
    text-align: center;

    ${getFontStyle('h1Bold')}
`

export const Description = styled.p`
    margin: 0 0 24px;
    color: ${({ theme }) => theme.colors.secondary500};
    text-align: center;

    ${getFontStyle('p02Regular')}

    strong {
        color: ${({ theme }) => theme.colors.primary90};

        ${getFontStyle('p02Medium')}
    }
`

export const Fields = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 40px;
`

export const TextField = styled(DdsTextField)`
    .MuiOutlinedInput-root {
        background-color: rgba(247, 247, 255, 0.5);
        overflow: hidden;
    }
`

export const TextLink = styled.a<{ disabled?: boolean }>`
    margin: 24px 0;
    color: ${({ theme, disabled }) => (disabled ? theme.colors.primaryGray2 : theme.colors.primary60)};
    cursor: pointer;
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

    ${getFontStyle('p02Underline')}
`

export const Button = styled(DdsButton)`
    box-sizing: border-box;
`

export const SecondaryButton = styled(Button)<{ disabled?: boolean }>`
    &.dds-button {
        border-color: ${({ theme, disabled }) => (disabled ? theme.colors.primaryWhite : theme.colors.primary20)};
        pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};

        color: ${({ disabled, theme }) => (disabled ? theme.colors.primaryGray2 : 'inherit')};
    }
`

export const Toast = styled(DdsToast)`
    && {
        width: calc(100% - 60px);
        gap: 24px;
        border-radius: 12px;
    }
`
