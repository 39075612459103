/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import { stringify } from "query-string";
import mixpanel from "mixpanel-browser";
import classNames from "classnames";
import _ from 'underscore'
import {
  Toast,
  SearchBox,
  PeopleIcon,
  ProgramCell,
  TablePagination
} from "@diversioteam/diversio-ds";

import { AverageImpactProgramTag } from "components/AvgImpactProgramTag";

import { ProgramCellListSkeleton } from "../../../../components/skeletons/programCellListSkeleton";
import { Actions } from "../../../../actions";
import { mapIcons } from "../../../../utils/kpiUtils";
import { getActiveCompanyID } from "../../../../utils";
import { getPageName, mapProgramToTracking } from "../../../../utils/tracking";

import TAXONOMIES from "../../../../utils/taxonomies";
import { ProgramFilters } from "./ProgramFilters";
import { withHooks } from "config/withHooks/withHooks";
import { getCleanedKpiCodes } from "../../utils/getCleanedKpiCodes";

import "./index.scss";

const PAGE_NAME = "all-programs";

/**
 * TODO
 * - Add dynamic sorting functionality
 */
class AllProgramsV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
      sort: "id",
      orderBy: "-",
      search: "",
      filters: {}
    };
    this.tableRef = React.createRef()
    this.props.resetGlobalToast();
    const query = stringify({
      page: 1,
      page_size: 10,
      search: "",
    });
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getAllProgramsV2({ query: query });
    this.props.getSolutionAllProgramsFilters({ id: activeID });

    const initialData = {
      data: { year: "", level: "", office: "", department: "" },
      id: activeID,
    };
    this.props.getSolutionGoalsInsightData(initialData);
  }

  componentDidMount() {
    mixpanel.track(TAXONOMIES.VIEW_PAGE, {
      name: getPageName(PAGE_NAME),
    });
  }


  fetchData = ({ page }) => {
    const { search, pageSize, filters } = this.state;
    
    const obj = {
      page: page || 1,
      page_size: pageSize,
      search: search,
      ...filters
    }

    const query = stringify(obj)
    this.props.getAllProgramsV2({ query: query });
    this.scrollToTop();
  }

  handleFilterChange = (name, selectedFilters) => {
    /**
     * Handle a user changing filters from kpi or target groups dropdown
     */
    const { filters } = this.state;
     
    const newFilters = {
      ...filters,
      [name]: _.pluck(selectedFilters, 'value').join(',')
    }

    this.setState({
      filters: newFilters,
      page: 1
    }, () => {
      this.fetchData({ page: 1 });
    })
  }


  handlePageChanged = (event, newPage) => {
    /**
     * Handle a user changing to a new page to view
     */
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHANGE_PAGE}`, {
      newPage: newPage + 1,
    });

    this.setState({
      page: newPage + 1,
    }, () => {
      this.fetchData({ page: newPage + 1 })
    });
  };

  handleChangeRowsPerPage = (event) => {
    /**
     * Handle a user changing the rows per page to show in the table
     */
    const newRowsPerPage = parseInt(event.target.value, 10);

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHANGE_ROWS_PER_PAGE}`, {
      newRowsPerPage,
    });

    this.setState(() => ({
      pageSize: newRowsPerPage,
      page: 1,
    }), () => {
      this.fetchData({ page: 1 })
    });
  };

  debouncedFetchData = _.debounce(this.fetchData, 100)

  handleSearchChange = (value) => {
    /**
     * Handle a user typing into the search box. We use settimeout
     * to have a small delay before making the api request to allow
     * the user to finish typing
     */
    this.setState({
      search: value,
      page: 1,
    }, () => {
      this.debouncedFetchData({ page: 1 })
    });
    
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.SEARCH_TERM}`, {
      searchTerm: value,
    });
  };

  scrollToTop = () => {
    /**
     * Helper function to scroll to the top of the div provided
     */
    let div = this.tableRef.current;
    if (div) {
      div.scrollTop = 0;
    }
  };

  saveProgram = (row) => {
    this.props.doSaveProgramV2({
      solution: row.id
    });
  };

  removeCompanySolution = (row) => {
    this.props.doSolutionRemoveV2({
      id: row.companySolution.id
    });
  };

  changeSolutionStatus = (row, isActive) => {
    this.props.doChangeSolutionStatus({
      id: row?.companySolution?.id,
      is_active: isActive
    });
  };

  handleExpandSearchBox = () => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.SEARCH_CLICKED}`);
  };

  handleOpenProgramDropDown = (program) => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.PROGRAM_DETAILS_VIEWED}`, mapProgramToTracking(program));
  };

  handleClickProgramTool = (toolTitle) => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.TOOL_CLICKED}`, {
      toolName: toolTitle,
    });
  };

  render() {
    const { programs, count, isLoading } = this.props?.solutionAllPrograms;

    const { page, pageSize, search, filters } = this.state;
    const toastError = this.props.message.toastError;

    const hasPrograms = programs.length > 0;

    return (
      <div className="all-programs-container-v2">
        {toastError?.type && (
          <div className="ds-toast-component">
            <Toast
              open={true}
              type={toastError.type}
              message={toastError.message}
              onClick={this.props.resetGlobalToast}
            />
          </div>
        )}
        <div className="filter-header-container">
          <span className="search-box-wrapper">
            <SearchBox
              maxWidth={198}
              value={search}
              onChange={(value) => this.handleSearchChange(value)}
              onExpand={this.handleExpandSearchBox.bind(this)}
            />
          </span>

          <ProgramFilters values={filters} handleFilterChange={this.handleFilterChange.bind(this)} />
        </div>

        <div className="table-body-container">
          <div className={classNames("table-body-wrapper", { "no-data": !hasPrograms })} ref={this.tableRef}>
            {isLoading.programs ? (
              <ProgramCellListSkeleton
                numberOfElements={this.state.pageSize}
                listElementWrapperClassName="solution-cell-wrapper"
              />
            ) : (
              programs.map((row) => {
                const tools = row.tools?.map(tool => {
                  return {
                    caption: tool.title,
                    href: tool.url,
                    icon: mapIcons(tool),
                    title: tool.mediaType,
                    withBadge: tool.type !== "resource",
                  };
                });
                
                const labels = row.targetGroupsV2?.map(group => ({
                  icon: <PeopleIcon type="line" />,
                  label: group.displayName,
                })) || [];

                const averageImpactDataEntries = Object.entries(row.averageImpactData);

                const averageImpactTags = row.hasAverageImpact ? 
                  averageImpactDataEntries.flatMap(([label, value]) => value > 0 ? ({
                    label: <AverageImpactProgramTag label={label} value={value} />,
                    color: 'green'
                  }): [])
                  : []
                
                  const averageImpactData = Object.fromEntries(
                    averageImpactDataEntries.map(
                      ([k, v]) => [row.painPoints.find(({ kpi }) => kpi.name === k).kpi.code, v]
                    )
                  )
                
                return (
                  <div className="solution-cell-wrapper">
                    <ProgramCell
                      key={row.id}
                      kpis={getCleanedKpiCodes(row.painPoints)}
                      programName={row.title}
                      labels={row.recommendationLabel.map(({ text }) => text)}
                      description={row.description}
                      isSaved={!!row.companySolution}
                      isAdded={row?.companySolution?.isActive}
                      onSave={() => this.saveProgram(row)}
                      onRemoveFromSaved={() => this.removeCompanySolution(row)}
                      onAddToActive={() => this.changeSolutionStatus(row, true)}
                      onRemoveFromActive={() => this.changeSolutionStatus(row, false)}
                      tools={tools}
                      programLabels={[...averageImpactTags, ...labels]}
                      onOpenDropdown={() => this.handleOpenProgramDropDown(row)}
                      onClickTool={(toolTitle) => this.handleClickProgramTool(toolTitle)}
                      averageImpactData={averageImpactData}
                    />
                  </div>
                );
              })
            )}
          </div>
        </div>

        {hasPrograms && (
          <div className="pagination-footer-container">
            <TablePagination
              count={count}
              page={page - 1}
              rowsPerPage={pageSize}
              onPageChange={(event, newPage) => {
                this.handlePageChanged(event, newPage);
              }}
              onRowsPerPageChange={this.handleChangeRowsPerPage.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, Actions)(withHooks(AllProgramsV2));
