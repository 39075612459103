import React, { useEffect, useRef, useState } from 'react'
import {
    GoalCardProps,
    HorizontalStepper,
    KpiKey,
    ScoreCardProps,
    Toast,
    VerticalStepper,
    kpiMap,
} from '@diversioteam/diversio-ds'
import { useHistory } from 'react-router-dom'
import { getPageName } from 'utils/tracking'
import { useDispatch, useSelector } from 'react-redux'
import { getActiveCompany } from 'utils'
import mixpanel from 'mixpanel-browser'

import { useCreateGoal } from 'hooks/goals/useCreateGoal'
import { useGetSolutionGoalCardOptions } from 'hooks/goals/useGetSolutionGoalCardOptions'
import { useGetInsightData } from 'hooks/goals/useGetInsightData'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { AppRoute } from 'routing/AppRoute.enum'
import { RootState } from 'reducers/rootState.types'
import { CreateGoalPayload, TimelineRange } from 'api/actions/goals/goalsActions.types'
import {
    InitRegularGoalArgs,
    InsightListSpacingParamsArgs,
    ViewRecommendedArgs,
} from 'components/Solutions/GoalsPagesV2/SelectInsight/selectInsight.types'
import { CreateGoal } from 'components/Solutions/GoalsPagesV2/CreateGoal'
import { ChangeInsightModal } from 'components/Solutions/GoalsPagesV2/ChangeInsightModal'
import { SelectInsight } from 'components/Solutions/GoalsPagesV2/SelectInsight'
import { ExplorePrograms } from 'components/Solutions/GoalsPagesV2/ExplorePrograms'
import TAXONOMIES from 'utils/taxonomies'

import { getScore, validateGoalFields } from './goals.helpers'
import { Actions } from './../../../../actions'
import * as S from './goals.styles'

const PAGE_NAME = 'goals'

const defaultSteps = {
    SELECT_INSIGHT: {
        number: 0,
        label: 'Select Insight',
    },
    CREATE_GOAL: {
        number: 1,
        label: 'Create Goal',
    },
    EXPLORE_PROGRAMS: {
        number: 2,
        label: 'Explore Programs',
    },
}

export const GoalsV2 = () => {
    const history = useHistory()

    const [steps, setSteps] = useState(defaultSteps)
    const [activeStep, setActiveStep] = useState(defaultSteps.SELECT_INSIGHT.number)
    const [isCustomGoal, setIsCustomGoal] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [pointImprovementScore, setPointImprovementScore] = useState<number | null>(null)
    const [kpi, setKpi] = useState<KpiKey | null>(null)
    const [targetGroup, setTargetGroup] = useState<string | null>(null)
    const [timeline, setTimeline] = useState<TimelineRange | null>(null)
    const [goalTitle, setGoalTitle] = useState<string | null>(null)
    const [insightListStyle, setInsightListStyle] = useState({ clippedHeight: 0, position: 0 })

    const goalsContainerRef = useRef<HTMLDivElement>(null)
    const insightsListRef = useRef<HTMLDivElement>(null)

    const { toastError } = useSelector((state: RootState) => {
        return state.message
    })

    const dispatch = useDispatch()

    const querySolutionGoalCardOptions = useGetSolutionGoalCardOptions()
    const queryInsightData = useGetInsightData()
    const queryUserMetaData = useUserMetadata()
    const mutationCreateGoal = useCreateGoal()

    const companyInformation = getActiveCompany(queryUserMetaData.data?.companies)

    const hasInsightsLoaded = queryInsightData.data?.insights !== undefined
    const hasInsights = hasInsightsLoaded && queryInsightData.data?.insights

    useEffect(() => {
        let label = ''

        if (!hasInsightsLoaded) {
            label = ''
        } else if (hasInsights) {
            label = 'Select Insight'
        } else {
            label = industryBenchmarkAvailable ? 'Insight' : 'Start'
        }

        setSteps((prevState) => ({
            ...prevState,
            SELECT_INSIGHT: {
                ...prevState['SELECT_INSIGHT'],
                label,
            },
        }))
    }, [hasInsightsLoaded])

    useEffect(() => {
        goalsContainerRef.current?.scrollTo({ top: insightListStyle.clippedHeight, behavior: 'smooth' })
    }, [insightListStyle])

    const stepLabels = Object.fromEntries(Object.entries(steps).map(([k, v]) => [k, v.label]))

    const hasData = querySolutionGoalCardOptions.data !== undefined && queryInsightData.data !== undefined

    const domGroupScore = queryInsightData.data?.orgData?.['Dominant group']
    const industryScore = queryInsightData.data?.orgData?.['Industry Average']
    const yourScore = queryInsightData.data?.orgData?.['Your KPI score']

    const numberOfCoreKpis = querySolutionGoalCardOptions.data?.kpis?.length
    const numberOfNaKpiScores = Object.values(industryScore || []).filter((score) => score === 'N/A').length
    const hasAllKpisNa = numberOfNaKpiScores === numberOfCoreKpis
    const industryBenchmarkAvailable = industryScore !== undefined && !hasAllKpisNa

    const handleClickReturnButton = () => {
        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.RETURN_BUTTON_CLICKED}`)

        setActiveStep(steps.SELECT_INSIGHT.number)
        setPointImprovementScore(null)
        setKpi(null)
        setTargetGroup(null)
        setTimeline(null)
        setGoalTitle(null)
    }

    const handleScrollToTop = () => {
        if (goalsContainerRef.current) {
            goalsContainerRef.current.scrollTop = 0
        }
    }

    const handleViewRecommended = (values: ViewRecommendedArgs) => {
        history.push(AppRoute.Recommended, values)
    }

    const handleScrollGoalsContainer = () => {
        insightsListRef.current?.scrollTo({ top: goalsContainerRef.current?.scrollTop })
    }

    const handleChangeInsightListSpacingParams = (params: InsightListSpacingParamsArgs) => {
        setInsightListStyle(params)
    }

    const handleInitRegularGoal = ({ kpi, targetGroup, title }: InitRegularGoalArgs) => {
        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CLICKED_CREATE_GOAL_FROM_INSIGHT}`, {
            insightName: title,
        })

        setActiveStep(steps.CREATE_GOAL.number)
        setInsightListStyle({ clippedHeight: 0, position: 0 })
        setIsCustomGoal(false)
        setIsModalOpen(false)
        setPointImprovementScore(null)
        setKpi(kpi)
        setTargetGroup(targetGroup)
        setTimeline(null)
        setGoalTitle(title)
    }

    const handleInitCustomGoal = () => {
        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CLICKED_CREATE_CUSTOM_GOAL}`)

        setActiveStep(steps.CREATE_GOAL.number)
        setInsightListStyle({ clippedHeight: 0, position: 0 })
        setIsCustomGoal(true)
        setIsModalOpen(false)
        setPointImprovementScore(null)
        setKpi(null)
        setTargetGroup(null)
        setTimeline(null)
        setGoalTitle(null)
    }

    const handleCreateGoal = () => {
        const kpiId = querySolutionGoalCardOptions.data?.kpiValues.find(({ code }) => code === kpi)?.id
        const surveyId = queryInsightData.data?.surveyId
        const targetGroupScore = getTargetGroupScore()

        const errorMessage = validateGoalFields({
            pointImprovementScore,
            kpiId,
            targetGroup,
            timeline,
        })

        if (errorMessage) {
            return dispatch(Actions.showToast({ type: 'error', message: errorMessage }))
        }

        const hasGoalCardData =
            !!targetGroup && !!targetGroupScore && !!timeline && !!pointImprovementScore && !!kpiId && !!surveyId

        if (!hasGoalCardData) {
            return
        }

        const targetGroupId = querySolutionGoalCardOptions.data?.targetGroup[targetGroup].id
        const targetGroups = targetGroupId ? [targetGroupId] : []
        const timelineValue = querySolutionGoalCardOptions?.data?.timelineMapping?.[timeline]

        const goal: CreateGoalPayload = {
            point_improvement: pointImprovementScore,
            kpis: [kpiId],
            target_groups_v2: targetGroups,
            duration: timelineValue ? parseFloat(timelineValue) : 0,
            survey: surveyId,
            original_score: targetGroupScore,
            is_diversio_insight: !isCustomGoal,
        }

        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CLICKED_EXPLORE_PROGRAMS_TO_CREATE_GOAL}`, {
            pointImprovementChoice: pointImprovementScore,
            kpiChoice: kpi,
            targetGroupChoice: targetGroup,
            timeFrameChoice: timeline,
        })

        mutationCreateGoal.mutate(goal, {
            onSuccess: () => {
                setActiveStep(steps.EXPLORE_PROGRAMS.number)
            },
        })
    }

    const handleChangeGoalCard = (key: string, value?: string | null) => {
        if (!value) {
            return
        }

        switch (key) {
            case 'pointImprovement':
                return setPointImprovementScore(parseFloat(value))

            case 'kpi':
                if (kpi) {
                    setPointImprovementScore(null)
                }

                return setKpi(value as KpiKey)

            case 'targetGroup':
                if (targetGroup) {
                    setPointImprovementScore(null)
                }

                return setTargetGroup(value)

            case 'timeline':
                return setTimeline(value as TimelineRange)
        }
    }

    const handleOpenModal = () => {
        mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHANGE_INSIGHT}`)

        setIsModalOpen(true)
    }

    const handleCloseModal = () => {
        setIsModalOpen(false)
    }

    if (!hasData) {
        return null
    }

    const hasCardData = !!queryInsightData.data.orgData && !!queryInsightData.data.groupData && !!targetGroup && !!kpi

    const maxScore =
        querySolutionGoalCardOptions.data.pointsImprovementRange[
            querySolutionGoalCardOptions.data.pointsImprovementRange.length - 1
        ]

    const getTargetGroupScore = () => {
        if (!targetGroup || !kpi) {
            return null
        }

        const score = queryInsightData.data.groupData[targetGroup][kpi]

        return getScore(score)
    }

    const getPointImprovementOptions = () => {
        const defaultRange = querySolutionGoalCardOptions.data.pointsImprovementRange

        const range = hasCardData && defaultRange.filter((value) => value + (getTargetGroupScore() || 0) <= maxScore)

        if (range) {
            return range
        }

        return defaultRange
    }

    const getKpiOptions = () => {
        if (isCustomGoal) {
            return querySolutionGoalCardOptions.data.kpis
        }

        if (!kpi) {
            return []
        }

        return [kpi]
    }

    const getTargetGroupOptions = () => {
        if (isCustomGoal) {
            return querySolutionGoalCardOptions.data.groups
        }

        if (!targetGroup) {
            return []
        }

        return [targetGroup]
    }

    const calculateScoreUpTo = () => {
        if (!pointImprovementScore) {
            return null
        }

        const isSelectedPointImprovementInRange = getPointImprovementOptions()?.includes(pointImprovementScore)

        const score = hasCardData && getTargetGroupScore()

        if (typeof score !== 'number') return null

        if (isSelectedPointImprovementInRange) {
            return score + pointImprovementScore
        }

        return score
    }

    const goalCardData: GoalCardProps = {
        company: companyInformation.name,
        pointImprovement: pointImprovementScore,
        pointImprovementOptions: getPointImprovementOptions(),
        kpi: kpi,
        kpiOptions: getKpiOptions(),
        targetGroup: targetGroup,
        targetGroupOptions: getTargetGroupOptions(),
        timeline,
        timelineOptions: querySolutionGoalCardOptions.data.timelineRange,
        scoreUpTo: calculateScoreUpTo(),
        maxScore,
        onChange: handleChangeGoalCard,
        onClick: handleCreateGoal,
    }

    const scoreCardData: ScoreCardProps = {
        kpi: kpi
            ? {
                  title: kpiMap[kpi],
                  icon: kpi,
                  score: getScore(yourScore?.[kpi]),
              }
            : undefined,
        industry: kpi
            ? {
                  score: getScore(industryScore?.[kpi]),
              }
            : undefined,
        targetGroup: targetGroup
            ? {
                  category: targetGroup,
                  score: getTargetGroupScore(),
              }
            : undefined,
        groupComparison: kpi
            ? {
                  score: getScore(domGroupScore?.[kpi]),
              }
            : undefined,
        maxScore,
    }

    const hasExploreProgramsData = !!pointImprovementScore && !!kpi && !!targetGroup && !!timeline

    return (
        <S.Goals isModalOpen={isModalOpen}>
            <S.ToastWrapper>
                <Toast
                    open={!!toastError?.type}
                    type={toastError?.type}
                    message={toastError?.message}
                    onClick={() => dispatch(Actions.resetGlobalToast())}
                />
            </S.ToastWrapper>

            <S.Header activeStep={activeStep}>
                {activeStep !== 0 && <S.ReturnButton onClick={handleClickReturnButton} />}

                <HorizontalStepper activeStep={activeStep} stepLabels={stepLabels} />
            </S.Header>

            <S.Container onScroll={handleScrollGoalsContainer} ref={goalsContainerRef}>
                <S.Wrapper clippedHeight={insightListStyle.clippedHeight}>
                    <S.StickyWrapper>
                        <S.Stepper>
                            <VerticalStepper variant="primary" activeStep={activeStep} />
                        </S.Stepper>

                        {activeStep === steps.SELECT_INSIGHT.number && (
                            <SelectInsight
                                insights={queryInsightData.data.insights}
                                isIndustryBenchmarkAvailable={industryBenchmarkAvailable}
                                onScrollToTop={handleScrollToTop}
                                onInitRegularGoal={handleInitRegularGoal}
                                onInitCustomGoal={handleInitCustomGoal}
                                onViewRecommended={handleViewRecommended}
                                onChangeInsightListSpacingParams={handleChangeInsightListSpacingParams}
                                ref={insightsListRef}
                            />
                        )}

                        {activeStep === steps.CREATE_GOAL.number && (
                            <CreateGoal
                                isCustomGoal={isCustomGoal}
                                scoreCardData={scoreCardData}
                                goalCardData={goalCardData}
                                goalTitle={goalTitle || ''}
                                onScrollToTop={handleScrollToTop}
                                onOpenModal={handleOpenModal}
                            />
                        )}

                        {activeStep === steps.EXPLORE_PROGRAMS.number && hasExploreProgramsData && (
                            <ExplorePrograms
                                isCustomGoal={isCustomGoal}
                                goalTitle={goalTitle || ''}
                                pointImprovementScore={pointImprovementScore}
                                kpi={kpi}
                                targetGroup={targetGroup}
                                timeline={timeline}
                                onScrollToTop={handleScrollToTop}
                            />
                        )}
                    </S.StickyWrapper>
                </S.Wrapper>
            </S.Container>

            <ChangeInsightModal
                isModalOpen={isModalOpen}
                insights={queryInsightData.data.insights}
                onCloseModal={handleCloseModal}
                onInitRegularGoal={handleInitRegularGoal}
                onInitCustomGoal={handleInitCustomGoal}
            />
        </S.Goals>
    )
}
