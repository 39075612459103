import React from 'react';
import ContactCard from "../../utils/ContactCard";
import deploySurvey from '../../../assets/images/DiversityVC/DeploySurvey.png';
import Goals from "../../../containers/DiversityVC/Goals";
import NextStepsCard from "./NextStepsCard"
import TopSolution from "../../Improve/TopSolution";
import update from '../../../assets/images/LSO/Update/update.png';
import { useUserMetadata } from 'hooks/metadata/useUserMetadata';
import { getActiveCompany } from 'utils';

const NextSteps = (props) => {
    const { data: userMetadata } = useUserMetadata()
    const companyInformation = getActiveCompany(userMetadata?.companies) 

    React.useEffect(() => {
        props.getAllSolutions();
    }, []);

    const recommendations = props.improve.solutions.allSolutions.filter(x => x.is_recommendation);
    const domain = '@diversio.com';

    return (
        <div id={'overview'}>
            {
                Object.keys(companyInformation).length && recommendations.length
                    ? <TopSolution topSolutions={recommendations}
                        filters={companyInformation.recommendationFilters}
                        doUpdateCompanySolutions={props.doUpdateCompanySolutions}
                        showMessage={props.showMessage}
                        isDiversityVC
                    />
                    : null
            }
            <NextStepsCard
                company_solutions={props.improve.solutions.allSolutions.filter(x => x.is_next_steps)}
                doUpdateCompanySolutions={props.doUpdateCompanySolutions}
            />
            <Goals />
            <div className="contact-container">
                <ContactCard
                    title="Deploy a Survey"
                    imgSrc={deploySurvey}
                    detail="Analyze the diversity & inclusion in your workforce, identify and unlock barriers to advancements for women and minorities."
                    link={`info${domain}`}
                    linkText="Get Started"
                />
                <ContactCard
                    title="Start Your Portfolio Assessment"
                    imgSrc={update}
                    detail="Assess the diversity & inclusion of your portfolio. Understand which of your companies are vulnerable to risks such as toxic culture and internal discrimination."
                    link={`info${domain}`}
                    linkText="Assess Your Portfolio"
                />
            </div>
        </div>
    )
}

export default NextSteps;
