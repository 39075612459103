import styled from 'styled-components'
import { getFontStyle } from '@diversioteam/diversio-ds'

import { media } from 'styles/theme/mediaQueries'

import { TextLink } from './../auth.styles'

export const LoginWrapper = styled.div`
    height: 100%;
    display: flex;
    padding: 12px;
    gap: 40px;

    ${media.sm} {
        padding: 24px;
    }
`

export const Billboard = styled.div`
    display: none;
    width: 50%;
    border-radius: 20px;
    background-color: ${({ theme }) => theme.colors.primaryGray2};

    ${media.sm} {
        display: block;
    }

    iframe {
        overflow: hidden;
        height: 100%;
        width: 100%;
        border: none;
    }
`

export const FormWrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${media.sm} {
        width: 50%;
    }
`

export const SsoButton = styled.a`
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary60};

    ${getFontStyle('p02Medium')}
`

export const ForgotPassword = styled(TextLink)`
    margin: 0;
`

export const LinksGroup = styled.div`
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export { Button, Form, Logo, Title, Fields, TextField, Toast } from './../auth.styles'
