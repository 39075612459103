import React, {Component} from 'react';
import Slack from './Slack'
import './index.scss'
import mixpanel from "mixpanel-browser";
import TAXONOMIES from '../../utils/taxonomies';

let requestId=0;

class Communications extends Component {
    componentDidMount() {
        mixpanel.track(TAXONOMIES.VIEW_PAGE, {
            name: "Communications"
        })
        this.props.getCommunicationsTokens({requestId:requestId++});
    }

    updateSlackToken(payload){
        const github_token = this.props.communications.authentication.github_token;
        payload.github_token = github_token;
        this.props.doUpdateCommunicationsTokens(payload)
    }

    getSlackAnalytics(payload){
        this.props.getCommunicationsSlackAnalytics(payload);
    }

    render() {
        const {authentication, slack_analytics} = this.props.communications;
        const slackProps = {authentication, slack_analytics, showMessage:this.props.showMessage};
        return (
            <div className={'diversity-dashboard'}>
                <Slack {...slackProps} updateToken={this.updateSlackToken.bind(this)} getAnalytics={this.getSlackAnalytics.bind(this)}/>
            </div>
        );
    }
}

export default Communications;
