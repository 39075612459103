import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ReloadIcon, SearchBox, Toast } from '@diversioteam/diversio-ds'
import { ToolCardListSkeleton } from 'components/skeletons/toolCardListSkeleton'
import { Actions } from 'actions'

import { useDebounce } from 'hooks/useDebounce'
import { useGetReports } from 'hooks/reporting/useGetReports'
import { RootState } from 'reducers/rootState.types'
import reportingCardImg from 'images/reportingCard.png'

import { Report } from './../../components/Report'
import * as S from './reporting.styles'

const PAGE_SIZE = 9

export const Reporting = () => {
    const [searchValue, setSearchValue] = useState('')

    const debouncedSearchValue = useDebounce<string>(searchValue, 500)

    const queryGetReports = useGetReports({
        page_size: PAGE_SIZE,
        title: debouncedSearchValue,
    })

    const { toastError } = useSelector((state: RootState) => {
        return state.message
    })

    const dispatch = useDispatch()

    const reports = queryGetReports.data?.pages
    const hasNextPage = reports && reports[reports.length - 1].next !== null

    const handleChangeSearch = (value: string) => {
        setSearchValue(value)
    }

    const handleClickLoadMore = () => {
        queryGetReports.fetchNextPage()
    }

    return (
        <S.Reporting>
            {toastError?.type && (
                <div className="ds-toast-component">
                    <Toast
                        open={true}
                        type={toastError?.type}
                        message={toastError?.message}
                        onClick={() => dispatch(Actions.resetGlobalToast())}
                    />
                </div>
            )}

            <S.Card variant="dark">
                <div>
                    <S.CardTitle>Reporting</S.CardTitle>

                    <S.CardDescription>
                        Simplifying DEI reporting to help you stay compliant and drive accountability
                    </S.CardDescription>
                </div>

                <img src={reportingCardImg} />
            </S.Card>

            <S.Filters>
                <SearchBox maxWidth={198} value={searchValue} onChange={handleChangeSearch} />
            </S.Filters>

            <S.Reports>
                {queryGetReports.isInitialLoading ? (
                    <ToolCardListSkeleton
                        size="medium"
                        numberOfElements={PAGE_SIZE}
                        listElementWrapperClassName="tool-card-wrapper tool-page-margin"
                    />
                ) : (
                    reports?.flatMap(({ results }) =>
                        results.map((props) => <Report {...props} size="medium" key={props.id} />),
                    )
                )}
            </S.Reports>

            {hasNextPage && (
                <S.LoadMore>
                    <Button
                        rightIcon={<ReloadIcon type="line" />}
                        type="button"
                        color="secondary"
                        onClick={handleClickLoadMore}
                        loading={queryGetReports.isFetchingNextPage}
                    >
                        Load More
                    </Button>
                </S.LoadMore>
            )}
        </S.Reporting>
    )
}
