import { useMutation } from '@tanstack/react-query'
import { loadDiversityDataOverTime } from 'sagas/network'
import { stringifyQueryParams } from 'utils'

import { DiversityDataOverTimeParams } from 'api/actions/analyze/diversityData/diversityDataOverTimeActions.types'
import { Mutations } from 'api/mutations.enum'

export const useExportDiversityDataOverTime = (params: DiversityDataOverTimeParams) => {
    const mutation = useMutation<string, Error, void>({
        mutationKey: [Mutations.exportDiversityDataOverTime, params],
        mutationFn: () => {
            return loadDiversityDataOverTime(stringifyQueryParams(params), 'file')
        },
    })

    return mutation
}
