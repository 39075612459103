import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../../../actions';
import EditComment from '../EditComment';
import DeletePost from '../DeleteModal';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import like from '../../../assets/images/svg/Connect/Post/like.svg';
import liked from '../../../assets/images/svg/Connect/Post/liked.svg';
import { parseDate } from "../../../utils";
import './index.scss';

class Comment extends Component {
    state = {
        anchorEl: null,
        isEditOpen: false,
        deleteConfirmationModal: false
    }

    handleOpenAction = (e) => {
        this.setState({
            anchorEl: e.currentTarget
        })
    }

    handleCloseAction = () => {
        this.setState({
            anchorEl: null
        })
    }

    handleOpenEdit = () => {
        this.handleCloseAction();
        this.setState({
            isEditOpen: true
        })
    }

    handleCloseEdit = () => {
        this.setState({
            isEditOpen: false
        });
    }

    handleOpenDeleteModal = () => {
        this.setState({
            deleteConfirmationModal: true
        });
    }

    handleCloseDeleteModal = () => {
        this.setState({
            deleteConfirmationModal: false
        });
        this.handleCloseAction();
    }

    handleDelete = () => {
        this.props.handleDeleteComment({ id: this.props.comment.id.toString() });
        this.handleCloseDeleteModal();
    }

    handleLike = () => {
        if (this.props.comment.liked_by_user) {
            this.props.handleLikeComment({
                comment_id: this.props.comment.id.toString(),
                like_count: {
                    "is_liked": this.props.comment.likes_count - 1
                }
            })
        } else {
            this.props.handleLikeComment({
                comment_id: this.props.comment.id.toString(),
                like_count: {
                    "is_liked": this.props.comment.likes_count + 1
                }
            })
        }
    }

    handleBookmark = () => {
        console.log('bookmark comment');
    }

    handleReply = () => {
        console.log('reply');
    }

    render() {
        return (
            <div id="thread-post">
                <div className="post-container">
                    <div className="post">
                        <div className="user-info-container">
                            <div className="profile-name">
                                {this.props.comment.user.full_name.toUpperCase().match(/\b(\w)/g)}
                            </div>
                            <div className="user-container">
                                <div className="user">{this.props.comment.user.full_name} - {this.props.comment.user.role}</div>
                                <div className="date">{parseDate(this.props.comment.created_at)}</div>
                            </div>
                        </div>

                        {
                            this.state.isEditOpen
                            ? <EditComment handleEditComment={this.props.handleEditComment}
                                           postInfo={this.props.postInfo}
                                           commentInfo={this.props.comment}
                                           handleCloseEdit={this.handleCloseEdit} />
                            : <div className="comment-container">
                                {this.props.comment.comment}
                                <span className={ this.props.comment.created_at.slice(0, 19) !== this.props.comment.updated_at.slice(0, 19) ? "edited" : "not-edited" }> (Edited)</span>
                            </div>
                        }

                        <div className="action-container">
                            {/* <button onClick={() => this.handleReply()} className="reply">Reply</button> */}
                            {/* <button onClick={() => this.handleBookmark()} className="bookmark"><img src="/images/svg/Connect/Post/bookmark.svg" alt="bookmark icon" /></button> */}
                            <button onClick={() => this.handleLike()} className="like"><img src={this.props.comment.liked_by_user ? liked : like} alt="like icon" />{this.props.comment.likes_count ? this.props.comment.likes_count : null}</button>
                        </div>
                    </div>
                    <button className={this.props.profileInfo.company === this.props.comment.user.company ? "action" : "hidden"}>
                        <IconButton
                            id="action-menu"
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => this.handleOpenAction(e)}
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="post-action-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={() => this.handleCloseAction()}
                        >
                            <MenuItem onClick={() => this.handleOpenEdit()}>Edit post</MenuItem>
                            <MenuItem onClick={() => this.handleOpenDeleteModal()}>Delete post</MenuItem>
                        </Menu>
                        <DeletePost isOpen={this.state.deleteConfirmationModal} handleCloseModal={this.handleCloseDeleteModal} handleDelete={this.handleDelete} type={'Comment'} />
                    </button>
                </div>
            </div>
        );
    };
};

const mapStateToProps = state => {
    return {
        ...state.connect
    };
};

export default connect(mapStateToProps, Actions)(Comment);
