/** @format */

import React, { useEffect, useState } from "react";
import mixpanel from "mixpanel-browser";
import {
  Button,
  ToyFaces,
  ReloadIcon,
  ProgramCell,
  ProgramCellSkeleton,
  ProgramsLogo,
  PeopleIcon
} from "@diversioteam/diversio-ds";

import { mapIcons } from "../../../../utils/kpiUtils";
import { getPageName, mapProgramToTracking } from "../../../../utils/tracking";

import "./index.scss";
import TAXONOMIES from "../../../../utils/taxonomies";
import { getCleanedKpiCodes } from "containers/ImprovePages/utils/getCleanedKpiCodes";

export const ExplorePrograms = ({
  isCustomGoal,
  recommendedPrograms,
  companyName,
  selectedKPI,
  selectedTimeline,
  selectedGoalString,
  selectedTargetGroup,
  selectedImprovementScore,
  onNext,
  onSeeAll,
  onScrollToTop,
  onSaveProgram,
  onActivateProgram,
  onDeactivateProgram,
  onRemoveCompanySolution,
  onLoadRecommendedPrograms,
  mapT,
  m,
  pageName,
  isLoadedRecommendedPrograms
}) => {
  const [page, setPage] = useState(1);

  const hasPrograms = recommendedPrograms.length > 0

  useEffect(() => {
    onLoadRecommendedPrograms(page);
    onScrollToTop();
  }, []);

  const handleNext = () => {
    // Used to add custom logic

    onNext();
  };

  const handleLoadMore = () => {
    const newPage = page + 1;

    setPage(newPage);
    onLoadRecommendedPrograms(newPage);

    mixpanel.track(`${getPageName(pageName)}: ${TAXONOMIES.LOAD_MORE_PROGRAMS}`)
  };


  const handleOpenProgramDropDown = (program) => {
    mixpanel.track(`${getPageName(pageName)}: ${TAXONOMIES.PROGRAM_DETAILS_VIEWED}`, mapProgramToTracking(program))
  }

  const handleClickProgramTool = (toolTitle) => {
    mixpanel.track(`${getPageName(pageName)}: ${TAXONOMIES.TOOL_CLICKED}`, {
      toolName: toolTitle
    })
  }

  const programsSkeletons = (
    new Array(10).fill('').map((_element, index) => {
      return (
        <ProgramCellSkeleton key={index} />
      )
    })
  )

  return (
    <div className="explore-programs">
      <div className="explore-programs__header">
        <div className="explore-programs__steps">
          <div className="explore-programs__steps-item explore-programs__steps-item--secondary">
            <h3 className="explore-programs__steps-title">Insight</h3>

            <p className="explore-programs__steps-description">
              {isCustomGoal
                ? "You chose to create a custom goal unrelated."
                : `${selectedGoalString}.`}
            </p>
          </div>

          <div className="explore-programs__steps-item explore-programs__steps-item--secondary">
            <h3 className="explore-programs__steps-title">Goal</h3>

            <p className="explore-programs__steps-description">
            {`${companyName} will achieve a ${selectedImprovementScore} point improvement in ${selectedKPI} for
              ${mapT(
                m.TARGET_GROUP,
                selectedTargetGroup
              )} in ${selectedTimeline}.`}
            </p>
          </div>

          <div className="explore-programs__steps-item explore-programs__steps-item--primary">
            <h1 className="explore-programs__steps-title">Explore Programs</h1>

            <ProgramsLogo
              className="explore-programs__steps-logo"
              height={30}
              width={240}
              variant="watermarkLight"
            />

            <p className="explore-programs__steps-description">
              Here is what we recommend to help you reach this goal.
            </p>
          </div>
        </div>

        <div className="explore-programs__avatars">
          <ToyFaces numAvatars={2} />
        </div>
      </div>

      <div className="explore-programs__subheader">
        {/* <Select
          // options={filters}
          options={[]}
          placeholder="Company Filters"
          type="block"
          variant="light"
          icon={<FilterIcon type="line" />}
        /> */}

        <Button color="transparent" size="small" onClick={onSeeAll}>
          See All
        </Button>
      </div>

      <div className="explore-programs__body">
        {
          isLoadedRecommendedPrograms
            ? recommendedPrograms.map((row, index) => {
                const tools = [];
                const labels = [];

                for (const i in row.tools) {
                  let withBadge = true;
                  if (row.tools[i].type === "resource") {
                    withBadge = false;
                  }
                  const cleanTool = {
                    caption: row.tools[i].title,
                    href: row.tools[i].url,
                    icon: mapIcons(row.tools[i]),
                    title: row.tools[i].media_type,
                    withBadge: withBadge,
                  };

                  tools.push(cleanTool);
                }

                for (const i in row.target_groups) {
                  const obj = {
                    icon: <PeopleIcon type="line" />,
                    label: mapT(m.GROUP_BREAKDOWN, row.target_groups[i].name),
                  };

                  labels.push(obj);
                }

                return (
                  <ProgramCell
                    key={row.id}
                    kpis={getCleanedKpiCodes(row.pain_points)}
                    programName={row.title}
                    labels={row.recommendation_label.map(({ text }) => text)}
                    description={row.description}
                    tools={tools}
                    programLabels={labels}
                    isSaved={row.is_saved}
                    isAdded={row?.company_solution?.is_active}
                    onSave={() => onSaveProgram(row)}
                    onRemoveFromSaved={() => onRemoveCompanySolution(row)}
                    onAddToActive={() => onActivateProgram(row)}
                    onRemoveFromActive={() => onDeactivateProgram(row)}
                    onOpenDropdown={() => handleOpenProgramDropDown(row)}
                    onClickTool={(toolTitle) => handleClickProgramTool(toolTitle)}
                  />
                );
              })
            : programsSkeletons
        }

        {  
          hasPrograms &&      
          <div className="load-more">
            <div className="load-more-button-wrapper">
              <Button
                type="button"
                size="medium"
                color="secondary"
                rightIcon={<ReloadIcon type="line" />}
                onClick={handleLoadMore}
              >
                Load More
              </Button>
            </div>
          </div>
        }
      </div>
    </div>
  );
};
