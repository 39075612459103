import React from 'react'
import { getActiveCompany } from 'utils'
import { InclusionScoreAnalysisCardSkeleton } from '@diversioteam/diversio-ds'
import { Link } from 'react-router-dom'

import { withErrorBoundary } from 'config/withErrorBoundary/withErrorBoundary'
import { useUserMetadata } from 'hooks/metadata/useUserMetadata'
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI'
import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useGetPastSurveys } from 'hooks/useGetPastSurveys'

import { parseIndustry, parseSurvey } from './inclusionScore.helpers'
import * as S from './inclusionScore.styles'

const InclusionScoreWithoutEB = () => {
    const { isLoading, data } = useGetPastSurveys()
    const { data: userMetadata } = useUserMetadata()
    const { survey } = useSurveys()

    if (isLoading) {
        return <InclusionScoreAnalysisCardSkeleton data-testid="inclusion-score-skeleton" />
    }

    if (!data) {
        return null
    }

    const inclusionScores = [...data.pastSurveys].reverse().map(parseSurvey)

    const industryKpis = data.pastSurveys[0].inclusionScore.industries.map(parseIndustry)

    return (
        <S.InclusionScoreAnalysisCard
            expandable
            animation={{
                isRadialBarChartAnimated: true,
                chart: {
                    isAreaAnimated: true,
                    isLineAnimated: true,
                },
            }}
            title="Inclusion score"
            data={inclusionScores}
            benchmarkOptions={industryKpis}
            onContactCsm={() => {
                window.location.href = `mailto:cs@diversio.com?subject=${
                    getActiveCompany(userMetadata?.companies)?.name
                } - request for additional benchmarks`
            }}
            inclusionScoreGoalButtonProps={{
                component: Link,
                to: generateCompanyURI('analyze2/inclusion'),
                children: 'Inclusion',
            }}
            customMainYearQuarter={survey?.name}
            data-joyride="inclusion-score"
            data-testid="inclusion-score"
        />
    )
}

export const InclusionScore = withErrorBoundary(InclusionScoreWithoutEB, {})
