import React, {useEffect} from 'react';
import classNames from "classnames";
import './index.scss';
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/HelpOutline";
import mixpanel from "mixpanel-browser";
import TAXONOMIES from "../../../utils/taxonomies";

const OpenText = ({ question, company, handleChange }) => {
    const { title, required, editable } = question;
    let parsedTitle = title.replace(/{{company}}/gm, company);

    useEffect(() => {
        handleChange({
            ...question,
            title: parsedTitle
        });
    }, []);

    const _handleTitleChange = (val) => {
        handleChange({
            ...question,
            title: val
        })
    }

    return (
        <div className={classNames("question", "ot-question", { "required": required })}>
            <div className={"question-title"}>
                <div className={'question-title-container'}>
                    <span onBlur={e => _handleTitleChange(e.target.textContent)}>{question.qnum}. {parsedTitle}</span>
                    <span className={"asterisk"}>*</span>
                </div>
                <div className={"question-title-description-container"}>
                    {
                        question.description
                            ? <Tooltip className="description-tooltip" title={question.description}  onOpen={() => {
                                mixpanel.track(TAXONOMIES.VIEWED_QUESTION_TOOLTIP, {
                                    question: parsedTitle,
                                    tooltip: question.description
                                })
                            }}>
                                <HelpIcon />
                            </Tooltip>: null
                    }
                </div>
            </div>
            <div className={"ot-question__text-container"}>
                <textarea placeholder="Free-text response" rows="10" disabled></textarea>
            </div>
        </div>
    );
};

export default OpenText;
