import React, {useEffect} from 'react';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/Tooltip';
import _ from 'underscore';
import './index.scss'
import GlobalSurveyErrors from "../GlobalSurveyErrors";
import HelpIcon from '@material-ui/icons/HelpOutline';
import { Button } from 'react-bootstrap';
import TAXONOMIES from "../../../utils/taxonomies";
import mixpanel from "mixpanel-browser";

const SingleChoice = ({ question, openText, handleChange, company, error, multiSelectType, preview }) => {
    const { title, required, editable, choices } = question;
    let parsedTitle = title.replace(/{{company}}/gm, company);

    useEffect(() => {
        handleChange({
            ...question,
            title: parsedTitle
        });
    }, []);

    const _handleChoiceChange = (val, idx) => {
        handleChange({
            ...question,
            choices: choices.map((choice, id) => {
                if (idx != id) {
                    return choice;
                } else {
                    return {
                        ...choice,
                        label: val,
                        value: val,
                    }
                }
            })
        })
    };

    const _handleTitleChange = (val) => {
        handleChange({
            ...question,
            title: val
        })
    }

    const _deleteChoice = (choiceToDeleteId) => {
        // Remove a choice and update the existing
        // placeholder `Choice n` values.
        const choicePattern = /^Choice \d+$/
        const newChoices = [];
        let nextCount = 1
        for (let i = 0; i < choices.length; i++) {
            if(choiceToDeleteId !== undefined && i === choiceToDeleteId) {
                mixpanel.track(TAXONOMIES.REMOVE_OPTION, {
                    question: parsedTitle,
                    choice: choices[i].label
                })
                continue;
            }
            if(choices[i].value.match(choicePattern)) {
                newChoices.push(
                    {
                        label: `Choice ${nextCount}`,
                        value: `Choice ${nextCount}`,
                        question: question.id,
                        editable: true
                    }
                )
            } else {
                newChoices.push(choices[i]);
            }
            nextCount++;
        }
        handleChange({
            ...question,
            choices: newChoices
        })
    };

    const _addChoice = () => {
        const newChoice = `Choice ${choices.length + 1}`;

        handleChange({
            ...question,
            choices: [
                ...choices,
                {
                    label: newChoice,
                    value: newChoice,
                    editable: true,
                    question: question.id
                }
            ]
        });

        mixpanel.track(TAXONOMIES.ADD_ANOTHER_OPTION, {
            question: parsedTitle,
            choice: newChoice
        })
    };

    const _checkValidity = () => {
        let dataAfterValidation = {
            ...question,
            // choices: [
            //     ..._.uniq(choices, choice => choice.value.toLowerCase()).filter(choice => !!choice.value.trim().length),
            // ]
        };

        if (!dataAfterValidation.choices.length) {
            dataAfterValidation.choices.push({
                label: `Choice 1`,
                value: `Choice 1`,
                question: question.id
            })
        }
        handleChange(dataAfterValidation)
    };

    return (
        <div className={classNames("question", "sc-question", { "required": required }, { "has-error": !_.isEmpty(error) })}>
            <GlobalSurveyErrors errors={error} />
            <div className={"question-title"}>
                {/*set this if the question title needs to be editable*/}
                {/*<span contentEditable={editable} onBlur={e => _handleTitleChange(e.target.textContent)}>{parsedTitle}</span>*/}
                <div className={'question-title-container'}>
                    <span onBlur={e => _handleTitleChange(e.target.textContent)}>{question.qnum}. {parsedTitle}</span>
                    <span className={"asterisk"}>*</span>
                </div>
                <div className={"question-title-description-container"}>
                    {
                        question.description
                            ? <Tooltip className="description-tooltip" title={question.description} onOpen={() => {
                                mixpanel.track(TAXONOMIES.VIEWED_QUESTION_TOOLTIP, {
                                    question: parsedTitle,
                                    tooltip: question.description
                                })
                            }}>
                                <HelpIcon />
                            </Tooltip>: null
                    }
                </div>
            </div>
            <div className={"sc-question__choice-container"}>
                <ul>
                    {
                        choices.map((choice, i) => {
                            const choiceErrors = _.maybe(error, "choices", i, "value") || [];
                            return <li>
                                {
                                    choices.length > 1 && !preview && (
                                        <Button className="delete-btn" variant={'danger'} style={{visibility: (!choice.editable) ? "hidden" : "visible"}} onClick={() => _deleteChoice(i)}>
                                            <img className={'icon icon-cross'}/>
                                        </Button>
                                    )
                                }
                                {
                                    multiSelectType && <input type={multiSelectType} disabled/>
                                }
                                <input onBlur={e => _checkValidity()}
                                       className={classNames("input-box", { "error": choiceErrors.length })}
                                       disabled={preview || !choice.editable}
                                       onChange={e => _handleChoiceChange(e.target.value, i)} value={choice.label} />
                                {
                                    choice.description
                                        ? <Tooltip className="description-tooltip" title={choice.description} onOpen={() =>
                                            mixpanel.track(TAXONOMIES.VIEWED_CHOICE_TOOLTIP, {
                                                question: parsedTitle,
                                                choice: choice.label,
                                                tooltip: question.description
                                            })
                                        }>
                                            <HelpIcon />
                                        </Tooltip>: null
                                }
                                {
                                    choiceErrors.map(err => <span className={"error-message"}>{err}</span>)
                                }
                            </li>
                        })
                    }
                    {
                        editable && !preview && <Button onClick={() => _addChoice()} size={"sm"}>Add another option</Button>
                    }
                    {
                        openText && <li className={"others"}>
                            <Button className={"delete-btn"} style={{ visibility: "hidden" }}>
                                <img className="icon icon-cross"/>
                            </Button>

                            <input type="checkbox" disabled style={{ visibility: "hidden" }}/>
                            <label className={"no-border"}>{ "Self describe" }</label>
                        </li>
                    }
                </ul>
            </div>
        </div>
    );
};

export default SingleChoice;
