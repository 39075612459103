import _ from "underscore";
import { BCI_APPLICANTS_YEAR_MAPPING, BCI_YEAR_MAPPING, CIFAR_STAGE_MAPPING } from '../../../utils/labels';
import { isBCI, isBCIApplicants, isBCIStudents, isCifarWithStages } from '../../../utils/companyChecks';
import { decode_utf8 } from "../../../utils/utf8";
import { getSelectedFilters } from "../../../utils/getSelectedFilters";

export const _btoa_json_encode = (obj) => {
    return btoa(unescape(encodeURIComponent(JSON.stringify(obj))));
}

function deepArraySort(data) {
    // Recursively loop over an nested object of arrays as
    // values at leaf nodes and sort those array.
    // These sorted arrays allow `_.isEqual` to work
    // correctly.
    Object.entries(data)
        .forEach(([key, value]) => {
            if (_.isObject(value) && !_.isArray(value)){
              deepArraySort(value)
            }
            else if (_.isArray(value)){
                data[key] = value.sort();
            }
            else {
              data[key] = value;
            }
        });
    return data;
}

function dropSameAndEmptyValues(choices, options) {
    // Recursively loop on options shown to user and the choices
    // selected by them.
    // Mark all the keys where values match between choices and options
    // as True, these matching values means user has not unselected
    // any values and it's okay to drop the inner values and just
    // send the outer keys to reduce the payload.

    // Empty values i.e. user unselected everything, are marked as False
    // Example: https://codepen.io/suicide_chewbacca/pen/mdBPgZp
    // options = {
    //     'a': {
    //         'a1': {
    //             'a11': ['a11a', 'a11b', 'a11c'],
    //             'a12': ['a12a'],
    //             'a13': ['a13a', 'a13b'],
    //             'a14': ['a141a', 'a142b']
    //         },
    //         'a2': ['a21', 'a22']
    //     },
    //     'b': {
    //         'b1': ['b11', 'b12'],
    //         'b2': ['b21', 'b22', 'b23', 'b24'],
    //         'b3': {
    //             'b31': ['b31a'],
    //             'b32': ['b32a', 'b32b', 'b32c', 'b32d']
    //             }
    //     },
    //     'c': {
    //         'c1': ['c11'],
    //         'c2': ['c21', 'c22', 'c23'],
    //         'c3': ['c31', 'c32']
    //         }
    //     }

    // values = {
    //     'a': {
    //         'a1': {
    //             'a11': ['a11a', 'a11c', 'a11b'],
    //             'a12': ['a12a'],
    //             'a13': [],
    //             'a14': ['a141a']
    //         },
    //     'a2': []
    //     },
    //     'b': {
    //         'b1': ['b12'],
    //         'b2': ['b22', 'b21'],
    //         'b3': {
    //             'b31': ['b31a'],
    //             'b32': ['b32b', 'b32a']}
    //     },
    //     'c': {
    //         'c1': ['c11'],
    //         'c2': ['c22', 'c21', 'c23'],
    //         'c3': ['c31', 'c32']
    //         }
    // }
    // nestedArraySort(values);
    // nestedArraySort(options);
    // console.log(JSON.stringify(dropSameAndEmptyValues(values, options)));
    // Returns
    // {
    //     'a': {
    //         'a1': {
    //             'a11': True,
    //             'a12': True,
    //             'a13': False,
    //             'a14': ['a141a']
    //         },
    //     'a2': False
    //     },
    //     'b': {
    //         'b1': ['b12'],
    //         'b2': ['b21', 'b22'],
    //         'b3': {
    //             'b31': True,
    //             'b32': ['b32a', 'b32b']
    //         }
    //     },
    //     'c': True
    // }

    Object.entries(choices)
        .forEach(([key, value]) => {
            if (_.isObject(value) && !_.isArray(value)){
               if(_.isEqual(value, options[key])) {
                   choices[key] = true;
               }
               else {
                dropSameAndEmptyValues(value, options[key]);
               }
            }
            if (_.isArray(value) && _.isEmpty(value)) {
                choices[key] = false;
            }
            if (_.isArray(value) && _.isArray(options[key]) && _.isEqual(value, options[key])) {
                choices[key] = true;
            }
        });
    return choices;
}

const _createNestedPayloadQueryString = (k, values, filters) => {
    const options = filters[`${k}s`] || filters[k];
    // Will remove post review
    deepArraySort(values);
    deepArraySort(options);
    const cleanedValues = dropSameAndEmptyValues(values, options);
    return _btoa_json_encode(cleanedValues);
};


export const createFiltersPayload = (ogValues = {}, ogFilters = {}) => {
    let values = _.deepClone(ogValues);
    let filters = _.deepClone(ogFilters);
    const final =  _.keys(values).reduce((reducer, key) => {
        const value = values[key];
        if (_.isArray(value) || key === "group_a" || key === "group_b") {
            reducer[key] = _btoa_json_encode(value)
            reducer["type"] = "base64";
        }
        else if (_.isObject(value) && !(_.isArray(value) || _.isString(value))) {
            reducer[key] = _createNestedPayloadQueryString(key, value, filters);
            reducer[`${key}_type`] = "nested_base64";
        }
        else {
            reducer[key] = value
        }
        return reducer;
    }, {});
    return _.omit(final, value => _.isUndefined(value) || _.isNull(value));
};

export const createFiltersPayloadV2 = (values, filters) => {
    const ogFilters = filters?.reduce((reducer, filter) => {
        reducer[filter.key] = filter.options
        return reducer
    }, {})
    const ogValues = values?.filter(value => value)?.reduce((reducer, value) => {
        if (!filters) {
            return reducer
        }
        const ogFilter = _.findWhere(filters, { name: value?.name }) || _.findWhere(filters, { key: value?.name })
        if (!ogFilter) {
            return reducer
        }
        reducer[ogFilter.key] = value.options
        return reducer
    }, {})

    return createFiltersPayload(ogValues, ogFilters)
}

export const createFiltersFromPayload = (values, filters) => {
    const { type, ...rest } = values
    const filtersWithoutTypes = Object.keys(rest).reduce((reducer, key) => {
        if (key.indexOf('_type') > -1) {
            return reducer
        }

        reducer[key] = rest[key]
        return reducer
    }, {})

    return Object.keys(filtersWithoutTypes).map((name) => {
        if (values[name]) {
            let parsedValue = "[]"
            try {
                parsedValue = atob(values[name])
            } catch(e) {
                parsedValue = "[]"
            }

            const decodedValue = JSON.parse(decode_utf8(parsedValue))
            const filtersInfo = _.findWhere(filters, { key: name }) || {}

            const selectedFilters = getSelectedFilters(decodedValue, filtersInfo.options)
            
            return {
                name: filtersInfo?.name,
                options: selectedFilters
            }
        }
    })
}

export const createStringifiedFiltersList = (filters) => {
    return filters.length ? filters.reduce((reducer, filter) => {
        return [
            ...reducer,
            ...filter.options
        ]
    }, []) : null
}

export const getYearAliasMap = (companyName) => {
    if (isCifarWithStages(companyName)) {
        return CIFAR_STAGE_MAPPING[companyName]
    }

    if (isBCIApplicants(companyName)) {
        return BCI_APPLICANTS_YEAR_MAPPING
    }

    if (isBCI(companyName) || isBCIStudents(companyName)) {
        return BCI_YEAR_MAPPING
    }

    return null
}
