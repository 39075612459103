import React from 'react'
import { SurveyParticipationCard, SurveyParticipationCardSkeleton } from '@diversioteam/diversio-ds'
import isEmpty from 'lodash/isEmpty'

import { useSurveys } from 'hooks/useSurveys/useSurveys'
import { useGetPastSurveys } from 'hooks/useGetPastSurveys'

export const SurveyParticipation = () => {
    const { isLoading, data } = useGetPastSurveys()
    const { checkIfSurveyIsSelected } = useSurveys()

    if (isLoading) {
        return <SurveyParticipationCardSkeleton data-testid="survey-participation-skeleton" />
    }

    if (!data || isEmpty(data)) {
        return null
    }

    const selectedSurveyData = data.pastSurveys.filter((survey) => checkIfSurveyIsSelected(survey))[0]

    if (!selectedSurveyData) {
        return null
    }

    const { participationRate, numberOfResponses, numberOfEmployees, yearQuarterLabel } = selectedSurveyData

    return (
        <SurveyParticipationCard
            participationRate={participationRate}
            numberOfResponses={numberOfResponses}
            numberOfEmployees={numberOfEmployees}
            surveyTitle={yearQuarterLabel}
            data-testid="survey-participation"
        />
    )
}
