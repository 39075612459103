/** @format */

import React, { useEffect } from "react";
import { ToyFaces, GoalCard, ScoreCard } from "@diversioteam/diversio-ds";
import "./index.scss";

export const CreateGoal = ({
  isCustomGoal,
  goalCardData,
  scoreCardData,
  selectedGoalString,
  onModalOpen,
  onScrollToTop,
}) => {
  const goalCard = goalCardData && goalCardData;

  useEffect(() => {
    onScrollToTop();
  }, []);

  return (
    <div className="create-goal">
      <div className="create-goal__steps">
        <div className="create-goal__steps-item create-goal__steps-item--secondary">
          <h3 className="create-goal__steps-item-title">Insight</h3>

          <p className="create-goal__steps-description">
            {isCustomGoal
              ? "You chose to create a custom goal unrelated. "
              : `${selectedGoalString}. `}

            <button
              className="create-goal__steps-item-action"
              onClick={onModalOpen}
            >
              Change
            </button>
          </p>
        </div>

        <div className="create-goal__steps-item create-goal__steps-item--primary">
          <h1 className="create-goal__steps-item-title">Create a goal</h1>

          <p className="create-goal__steps-description">
            Tailor this goal to your organization
          </p>
        </div>
      </div>

      <div className="create-goal__avatars">
        <ToyFaces numAvatars={2} />
      </div>

      {goalCard && (
        <div className="create-goal__goal-card-wrapper">
          {<GoalCard {...goalCard} />}
        </div>
      )}

      <div className="create-goal__score-card-wrapper">
        <ScoreCard {...scoreCardData} />
      </div>
    </div>
  );
};
