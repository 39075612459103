/**
 * Reducers output the Redux state (models) for the app. They do not alter the
 * existing state, but generate modified state objects from the old state in
 * response to actions.
 *
 * http://redux.js.org/docs/basics/Reducers.html
 *
 **/
import { combineReducers } from 'redux';
import pageState from './pageState';
import targets from './targets';
import actionPlans from './actionPlans';
import solutions from './solutions';
import filterMap from './filterMap';

/*
 * Reducer aggregator
 */
export default combineReducers({
    /*
     * Populate reducers
     */
    pageState,
    targets,
    actionPlans,
    solutions,
    filterMap,
});
