/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { stringify } from "query-string";
import mixpanel from "mixpanel-browser";
import {
  ProgramCell,
  TablePagination,
  Toast,
  PeopleIcon,
  ProgramCellSkeleton,
} from "@diversioteam/diversio-ds";

import { Actions } from "../../../../actions";
import { getActiveCompanyID } from "../../../../utils";
import { getPageName, mapProgramToTracking } from "../../../../utils/tracking";
import { mapT } from "../../../../translations/utils";
import { mapIcons } from "../../../../utils/kpiUtils";
import * as m from "../../../../translations/mapping";

import "./index.scss";
import classNames from "classnames";
import { ProgramCellListSkeleton } from "../../../../components/skeletons/programCellListSkeleton";
import TAXONOMIES from "../../../../utils/taxonomies";
import { withHooks } from "config/withHooks/withHooks";
import { EmptyArchivedSolutions } from "./EmptyArchivedSolutions";
import { getCleanedKpiCodes } from "../../utils/getCleanedKpiCodes";

const PAGE_NAME = "my-solutions-archive";

class MySolutionsArchiveV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 10,
    };
    this.props.resetGlobalToast();
    const query = stringify({
      page: 1,
      page_size: 10,
    });

    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);

    this.props.getSolutionMySolutionsArchiveV2({ id: activeID, query });
    const initialData = {
      data: { year: "", level: "", office: "", department: "" },
      id: activeID,
    };
    this.props.getSolutionGoalsInsightDataV2(initialData);
  }

  componentDidMount() {
    mixpanel.track(TAXONOMIES.VIEW_PAGE, {
      name: getPageName(PAGE_NAME)
    })
  }

  handlePageChanged = (event, newPage) => {
    /**
     * Handle a user changing to a new page to view
     */
    const { pageSize } = this.state;

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHANGE_PAGE}`, {
      newPage: newPage + 1
    })

    this.setState({
      page: newPage + 1,
    });

    const query = stringify({
      page: newPage + 1,
      page_size: pageSize,
    });

    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);

    this.props.getSolutionMySolutionsArchiveV2({ id: activeID, query });

    this.scrollToTop();
  };

  handleChangeRowsPerPage = (event) => {
    /**
     * Handle a user changing the rows per page to show in the table
     */
    const { page } = this.state;
    const newRowsPerPage = parseInt(event.target.value, 10);

    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.CHANGE_ROWS_PER_PAGE}`, {
      newRowsPerPage
    })

    this.setState(() => ({
      pageSize: newRowsPerPage,
    }));

    const query = stringify({
      page: page + 1,
      page_size: newRowsPerPage,
    });

    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);

    this.props.getSolutionMySolutionsArchiveV2({ id: activeID, query });

    this.scrollToTop();
  };

  scrollToTop = () => {
    /**
     * Helper function to scroll to the top of the div provided
     */
    const div = document.getElementsByClassName(
      "archived-solutions-container"
    )[0];
    div.scrollTop = 0;
  };

  removeFromArchived = (row) => {
    const { insightData } = this.props.solutionGoals;
    this.props.doSolutionUnarchiveV2({
      solution: row?.id,
      id: insightData?.survey_id,
      fields: { is_archived: false },
      program_obj: row.solution,
      page: PAGE_NAME
    });
  };

  handleOpenProgramDropDown = (program) => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.PROGRAM_DETAILS_VIEWED}`, mapProgramToTracking(program))
  }

  handleClickProgramTool = (toolTitle) => {
    mixpanel.track(`${getPageName(PAGE_NAME)}: ${TAXONOMIES.TOOL_CLICKED}`, {
      toolName: toolTitle
    })
  }

  render() {
    const { solutions, count, isLoading, solutionsPagination } = this.props?.solutionMySolutionsArchive;
    const { page, pageSize } = this.state;
    const toastError = this.props.message.toastError;

    const hasSolutions = solutions.length > 0;

    return (
      <div className={classNames('archived-solutions-container', { 'no-data': !hasSolutions })}>
        {toastError?.type && (
          <div className="ds-toast-component">
            <Toast
              open={true}
              type={toastError.type}
              message={toastError.message}
              onClick={this.props.resetGlobalToast}
            />
          </div>
        )}

        <div className="table-body-container">
          {
            isLoading.solutions
              ? <ProgramCellListSkeleton numberOfElements={this.state.pageSize} listElementWrapperClassName="solution-cell" />
              : solutions.length ? solutions.map((row, index) => {
                  const tools = row.solution?.tools?.map(tool => {
                    return {
                      caption: tool.title,
                      href: tool.url,
                      icon: mapIcons(tool),
                      title: tool.mediaType,
                      withBadge: tool.type !== "resource",
                    };
                  });
                  
                  const labels = row.solution?.targetGroupsV2?.map(group => ({
                    icon: <PeopleIcon type="line" />,
                    label: group.displayName,
                  }));

                  return (
                    <div key={row.id} className="solution-cell">
                      <ProgramCell
                        key={row.id}
                        kpis={getCleanedKpiCodes(row.solution.painPoints)}
                        programName={row.solution.title}
                        labels={row.solution.recommendationLabel}
                        description={row.solution.description}
                        onRemoveFromArchived={() => this.removeFromArchived(row)}
                        tools={tools}
                        programLabels={labels}
                        onOpenDropdown={() => this.handleOpenProgramDropDown(row.solution)}
                        onClickTool={(toolTitle) => this.handleClickProgramTool(toolTitle)}
                      />
                    </div>
                  );
              }) : <EmptyArchivedSolutions />
          }
        </div>

        {
          hasSolutions && !!solutionsPagination &&
            <div className="pagination-footer-container">
              <TablePagination
                count={count}
                page={page - 1}
                rowsPerPage={pageSize}
                onPageChange={(event, newPage) => {
                  this.handlePageChanged(event, newPage);
                }}
                onRowsPerPageChange={(event) => this.handleChangeRowsPerPage(event)}
                component="div"
              />
            </div>   
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, Actions)(withHooks(MySolutionsArchiveV2));
