import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Actions } from '../actions';
import {Redirect} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import mixpanel from "mixpanel-browser";
import { jwtDecode } from "jwt-decode";
import history from '../history';
import _ from 'underscore';
import TAXONOMIES from 'utils/taxonomies';
import { generateCompanyURI } from 'sagas/helpers/generateCompanyURI';
import { withHooks } from 'config/withHooks/withHooks';
import { getActiveCompany } from 'utils';

class ChooseDashboard extends Component {
    state = {
        dashboardPicked: false
    }

    handleCompanyClick = async ({uuid}) => {
        if (!uuid) {
            return 
        }
        const foundCompany = _.find(this.props.userMetadata?.companies, { uuid })
        const activeCompanyName = foundCompany.company
        const userObj = jwtDecode(this.props.accessToken);
        const username = userObj.username;

        mixpanel.track(TAXONOMIES.DASHBOARD_SESSION_ENDED, {
            dashboardName: activeCompanyName,
            user: username,
            action: 'dashboard switch'
        });

        history.replace(`/company/${foundCompany.uuid}/home`)            
    }

    render() {
        const companies = this.props.userMetadata?.companies;
        const permissions = getActiveCompany(companies)?.permissions;

        if(this.props.isAuthenticated && permissions && this.props.choseCompany) {
            if(permissions.ilnDashboardHome) return <Redirect to={generateCompanyURI("iln-dashboard/home")} />
            if(permissions.diversity_pe) return <Redirect to={generateCompanyURI("diversity_pe/about")} />
            if(permissions.diversity_vc) return <Redirect to={generateCompanyURI("diversity_vc/about")} />
            if(permissions.home) return <Redirect to={generateCompanyURI("home")} />
            if(permissions.survey) return <Redirect to={generateCompanyURI("survey")} />
            if(permissions.lso) return <Redirect to={generateCompanyURI("overview")} />
            return <Redirect to={generateCompanyURI("home")} />
        }
        return (
            <div className="choose-dashboard-content">
                <h1>Choose Your Dashboard</h1>
                <div className="dashboard-list-container">
                    {companies && companies.map((company, index) => {
                        const name = company.name
                        return (
                            <Button
                                className="dashboard-button-container"
                                onClick={() => this.handleCompanyClick(company)}
                            >
                                {name}
                            </Button>
                        )
                    })}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.auth,
        ...state.home,
    };
};

export default connect(mapStateToProps, Actions)(withHooks(ChooseDashboard));
