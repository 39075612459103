/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { stringify } from "query-string";
import mixpanel from "mixpanel-browser";
import {
  Button,
  ReloadIcon,
  ToolCard,
  Toast,
} from "@diversioteam/diversio-ds";

import { ToolCardListSkeleton } from "../../../../components/skeletons/toolCardListSkeleton";
import { Actions } from "../../../../actions";
import { getActiveCompanyID } from "../../../../utils";
import { toTitleCase } from "../../../../utils/programFeaturesFunctions";
import { getPageName, handleClickTool } from "../../../../utils/tracking";
import { mapT } from "../../../../translations/utils";
import * as m from "../../../../translations/mapping";

import "./index.scss";
import { withHooks } from "config/withHooks/withHooks";
import { getCleanedGroups } from "../../utils/getCleanedGroups";
import { getCleanedKpiCodes } from "../../utils/getCleanedKpiCodes";

const PAGE_NAME = "trainings";

const PAGE_SIZE = 9;

class Trainings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: PAGE_SIZE,
      sort: "id",
    };
    this.props.resetTrainings();
    this.props.resetGlobalToast();
    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    const query = stringify({
      page: 1,
      page_size: PAGE_SIZE,
    });
    this.props.getSolutionTrainings({ id: activeID, query: query });
  }

  componentDidMount() {
    mixpanel.track("View Page", {
      name: getPageName(PAGE_NAME),
    });
  }

  handleLoadTrainings = () => {
    /**
     * Function to call the backend api to load the first set or next page of trainings to show to the
     * user
     */
    mixpanel.track(`${getPageName(PAGE_NAME)}: Load More Trainings`);

    const { page, pageSize} = this.state;
    const newPage = page + 1;
    this.setState({
      page: newPage,
    });

    const query = stringify({
      page: newPage,
      page_size: pageSize,
    });

    const activeID = getActiveCompanyID(this.props.userMetadata?.companies);
    this.props.getSolutionTrainings({ id: activeID, query: query });
  };

  scrollToTop = () => {
    /**
     * Helper function to set the page scroll back to 0 on each goal subpage render
     */
    let container = document.getElementsByClassName("content-container")[0];
    container.scrollTop = 0;
  };

  saveTool = (training) => {
    const companyID = getActiveCompanyID(this.props.userMetadata?.companies);

    const obj = {
      tool: training.id,
      company: companyID,
      tool_obj: training,
      page: PAGE_NAME,
    };

    this.props.doSolutionSaveTraining(obj);
  };

  removeSavedTool = (training) => {
    if (training?.company_tool_id) {
      const obj = {
        toolId: training?.id,
        companyToolId: training?.company_tool_id,
        tool_obj: training,
        page: PAGE_NAME,
      };

      this.props.doSolutionRemoveSavedTraining(obj);
    }
  };

  render() {
    const { trainings, endOfTrainings, isLoading, isLoadingMore } = this.props?.solutionTrainings;
    const toastError = this.props.message.toastError;

    return (
      <div className="tools-container">
        {toastError?.type && (
          <div className="ds-toast-component">
            <Toast
              open={true}
              type={toastError?.type}
              message={toastError?.message}
              onClick={() => {
                this.props.resetGlobalToast();
              }}
            />
          </div>
        )}

        <div className="tools-wrapper">
          <div className="tools-content">
            <div className="tools-header">
              <div className="tools-text tool-page-margin">
                <h1>Diversio Trainings</h1>
                <h3>Online Courses for HR & DEI Professionals</h3>
              </div>
            </div>

            <div className="tools-body">
              {isLoading.trainings ? (
                <ToolCardListSkeleton
                  size="medium"
                  numberOfElements={this.state.pageSize}
                  listElementWrapperClassName="tool-card-wrapper tool-page-margin"
                />
              ) : (
                trainings.map((row, index) => {
                  return (
                    <div className="tool-card-wrapper tool-page-margin">
                      <ToolCard
                        key={index}
                        category={toTitleCase(row?.media_type)}
                        kpis={getCleanedKpiCodes(row.pain_points)}
                        targetGroups={getCleanedGroups(row.target_groups)}
                        publicationYear={row.created}
                        description={row.description}
                        title={row.title ? row.title.replace(".pdf", "").replace(".docx", "") : null}
                        onSave={() => this.saveTool(row)}
                        isSaved={row.is_saved}
                        href={row.url}
                        onRemove={() => this.removeSavedTool(row)}
                        onClick={(e) => handleClickTool(e, row, PAGE_NAME)}
                        size="medium"
                      />
                    </div>
                  );
                })
              )}
            </div>

            <div className="load-more-container">
              <div className="load-more tools-footer tool-page-margin">
                <div className="load-more-button-wrapper">
                  <Button
                    rightIcon={<ReloadIcon type="line" />}
                    type="button"
                    color="secondary"
                    onClick={() =>
                      endOfTrainings
                        ? this.props.showToast({
                            type: "warning",
                            message: "No more trainings available",
                          })
                        : this.handleLoadTrainings()
                    }
                    loading={isLoadingMore.trainings}
                  >
                    Load More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, Actions)(withHooks(Trainings));
